import React, {  useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { getalllocalstore, AddNewCustomerType, UpdateCustomerTypeCharges } from '../../apollo';
import { Button, Box, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';

const GET_LOCAL_STORE = gql`
  ${getalllocalstore}
`;
const CREATE_CUSTOMER_TYPE = gql`
  ${AddNewCustomerType}
`;
const EDIT__CUSTOMER_TYPE_CHARGES = gql`
  ${UpdateCustomerTypeCharges}
`;




function UpdateCustomerType(props) {
  const { onClose } = props;
  const formRef = useRef();
  const mutation = props.vendor ? EDIT__CUSTOMER_TYPE_CHARGES : CREATE_CUSTOMER_TYPE;
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { t } = props;
  const [formErrors, setFormErrors] = useState({});
  const [openSnackk, setOpenSnackk] = useState(false);

  const [formData, setFormData] = useState({
    id: props.vendor ? props.vendor._id : "",
    name: props.vendor ? props.vendor.name : '',
    description: props.vendor ? props.vendor.description : '',
    customerType: props.vendor ? props.vendor.customerType : null,
    charges: {
      handling: {
        minimumAmount: props.vendor ? props.vendor.charges.handling.minimumAmount : '',
        rate: props.vendor ? props.vendor.charges.handling.rate : ''
      },
      delivery: {
        minimumAmount: props.vendor ? props.vendor.charges.delivery.minimumAmount : '',
        rate: props.vendor ? props.vendor.charges.delivery.rate : ''
      }
    }
  });
  const onError = ({ graphQLErrors, networkError }) => {
    setSuccess('');
    if (graphQLErrors) {
      setError("Something Went Wrong");
      setOpenSnackk(true);
    } else if (networkError) {
      setError(networkError.result.errors[0].message);
    } else {
      setError('Something went wrong!');
    }
    setTimeout(hideAlert, 3000);
    setOpenSnackk(true);
  };

  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Chargers Updated Successfully') : t('Customer Type Added Successfully');
    setError('');
    props.refetch();
    setSuccess(message);
    setTimeout(hideAlert, 3000);
    props.refetch();
    setOpenSnackk(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');

    setFormData((prevData) => {
      const updatedData = { ...prevData };
      let currentLevel = updatedData;

      for (let i = 0; i < keys.length - 1; i++) {
        currentLevel = currentLevel[keys[i]] = { ...currentLevel[keys[i]] };
      }

      currentLevel[keys[keys.length - 1]] =parseFloat(value);
      return updatedData;
    });

    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };

  const validateFormData = () => {
    let errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.description) errors.description = 'Description is required';
    if (!formData.customerType) errors.customerType = 'Customer Type is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_LOCAL_STORE }],
    onError,
    onCompleted,
  });

  const clearFields = () => {
    formRef.current.reset();
    setFormData({
      name: '',
      description: '',
      customerType: '',
      charges: {
        handling: {
          minimumAmount: '',
          rate: ''
        },
        delivery: {
          minimumAmount: '',
          rate: ''
        }
      }
    });
  };

  const hideAlert = () => {
    setError('');
    setSuccess('');
    setOpenSnackk(false);
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) return;
    const payload = {
      type: formData.customerType,
      charges: formData.charges
    };

    mutate({ variables: payload });
  
    setTimeout(() => {
      if (typeof props.onClose === 'function') {
        props.onClose();
      }
    }, 4000);
  };

  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') onClose();
    }} maxWidth="sm" fullWidth>
      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor ? t('Edit Customer Type') : t('Add Customer Type')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField name="name" value={formData.name} label="Name" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                error={!!formErrors.name} helperText={formErrors.name} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField name="description" value={formData.description} label="Description" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                error={!!formErrors.description} helperText={formErrors.description} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField type="number" name="customerType" label="Customer Type" value={formData.customerType} variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                error={!!formErrors.customerType} helperText={formErrors.customerType} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField type="number" name="charges.handling.minimumAmount" label="Handling Minimum Amount" value={formData.charges.handling.minimumAmount} variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                error={!!formErrors.handlingMinimumAmount} helperText={formErrors.handlingMinimumAmount} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField type="number" name="charges.handling.rate" label="Handling Rate" value={formData.charges.handling.rate} variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                error={!!formErrors.handlingRate} helperText={formErrors.handlingRate} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField type="number" name="charges.delivery.minimumAmount" label="Delivery Minimum Amount" value={formData.charges.delivery.minimumAmount} variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                error={!!formErrors.deliveryMinimumAmount} helperText={formErrors.deliveryMinimumAmount} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField type="number" name="charges.delivery.rate" label="Delivery Rate" value={formData.charges.delivery.rate} variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                error={!!formErrors.deliveryRate} helperText={formErrors.deliveryRate} />
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <Autocomplete options={options} getOptionLabel={(option) => option.storeName} value={selectedCategory} onChange={handleCategoryChange} renderInput={(params) => <TextField {...params} name="storeId" label="Store Name" variant="outlined" fullWidth inputProps={{ style: inputTextStyle }} />}
                PaperComponent={CustomPaperComponent} />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
        <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}
          >
            {props.vendor ? t('Update') : t('Add')}
          </Button>
          <Button onClick={props.onClose} className={`${globalClasses.modalCancleBtn}`}>{t('Cancel')}</Button>
        </DialogActions>
        <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
      </form>
    </Dialog>
  );
}

export default withTranslation()(UpdateCustomerType);
