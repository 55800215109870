import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { createworker ,updateworkerdata } from '../../apollo';
import { Button, Box,Paper, Typography, Grid, TextField, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions,Alert,Snackbar } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';



const CREATE_LOCAL_STORE_ADMIN = gql`
  ${createworker}
`;
const EDIT__LOCAL_STORE_ADMIN = gql`
  ${updateworkerdata}
`;
const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);
function AddLocalLocal(props) {
  const { onClose  } = props;
  const formRef = useRef();
  const mutation = props.vendor ? EDIT__LOCAL_STORE_ADMIN : CREATE_LOCAL_STORE_ADMIN;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const userType  = JSON.parse(localStorage.getItem("user-enatega"))
  const [formData, setFormData] = useState({
    id :userType._id,
    name: props.vendor ? props.vendor.name : '',
    email: props.vendor ? props.vendor.email : '',
    contactAddress: props.vendor ? props.vendor.contactAddress : '',
    password: props.vendor ? props.vendor.password : '',
    phone: props.vendor ? props.vendor.phone : '',
    storeId: props.vendor ? props.vendor.storeId : userType.storeId,
    workerType: props.vendor ? props.vendor.workerType : '', // Added workerType field
  });
  
  const [formErrors, setFormErrors] = useState({});
  const [openSnackk, setopenSnackk] =useState(false);
    // Define a state to hold your query data

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateFormData = () => {
    let errors = {};
  
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.contactAddress) errors.contactAddress = 'Contact Address is required';
    if (!formData.email) errors.email = 'Email is required';
    if (!formData.password && !props.vendor) errors.password = 'Password is required';
    if (!formData.phone) errors.phone = 'Phone is required';
    if (!formData.storeId && !props.vendor) errors.storeId = 'Store is required';
    if (formData.workerType === null || formData.workerType === undefined) errors.workerType = 'Worker Type is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Worker Updated Successfully') : t('Worker Added Successfully');
    errorSetter('');
    successSetter(message);
    props.refetch()
    setopenSnackk(true)
    setTimeout(hideAlert, 3000);
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      // errorSetter(error);
      errorSetter(error);
      setopenSnackk(true)
    } else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
  };

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    onError,
    onCompleted,
  });

 

  const clearFields = () => {
    formRef.current.reset();
    setFormData({
      name: props.vendor ? props.vendor.name : '',
      email: props.vendor ? props.vendor.email : '',
      contactAddress: props.vendor ? props.vendor.contactAddress : '',
      password: props.vendor ? props.vendor.password : '',
      phone: props.vendor ? props.vendor.phone : '',
      storeId: props.vendor ? props.vendor.storeId : userType.storeId,
      workerType: null,
    });
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const workerTypes = [
    { id: 0, label: 'Pick and Pack' },
    { id: 1, label: 'Billing' },
    { id: 2, label: 'Rider' },
  ];

  const handleWorkerTypeChange = (event, value) => {
    if (value) {
      setFormData({ ...formData, workerType: value.id,workerTypeName: value.label  });
    } else {
      setFormData({ ...formData, workerType: null,workerTypeName: null });
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      // setOpenSnack(true);
      return;
    }
    if (true) {
      !props.vendor ?
        mutate({
          variables: {
            workerInput: {
              contactAddress: formData.contactAddress,
              email: formData.email,
              name: formData.name,
              password: formData.password,
              phone: formData.phone,
              storeId: formData.storeId,
              workerType: formData.workerType,
            }
          }
        }) :
        mutate({
          variables: {
              email: formData.email,
              password: formData.password,
          }
        });
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose();
        }
      }, 4000);
    }
  };
  return (
    <Dialog open={true}   onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
        <form ref={formRef} onSubmit={handleSubmit}>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
            {props.vendor ? t('Edit Local Users ') : t('Add Local Users')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
     
          <Grid container spacing={2}>
            {!props.editModal ? (
              <>
               <Grid item xs={12} sm={12}>
                <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={workerTypes}
  getOptionLabel={(option) => option.label}
  onChange={handleWorkerTypeChange} // Pass the modified function here
  value={formData.workerTypeName}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Worker Type"
      variant="outlined"
      inputProps={{ ...params.inputProps, style: inputTextStyle }}
    />
  )}

  PaperComponent={CustomPaperComponent}
/>

                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="name" label=" Name worker" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="contactAddress" label="Address" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.contactAddress}
                    helperText={formErrors.contactAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="email"label="Email" variant="outlined" fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.email}
                    helperText={formErrors.email}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="password" label="Password" variant="outlined" fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.password}
                    helperText={formErrors.password}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField name="phone" label="Phone" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.phone}
                    helperText={formErrors.phone}/>
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <TextField name="storeId" label="StoreId" variant="outlined" fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}/>
                </Grid> */}
               
              </>
            ) : (
              <>
                 <Grid item xs={12} sm={12}>
           
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={workerTypes}
  getOptionLabel={(option) => option.label}
  getOptionSelected={(option, value) => option.id === value.id} // Specify how to determine if an option is selected
  onChange={handleWorkerTypeChange}
  value={workerTypes.find((type) => type.id === formData.workerType) || null} // Adjust value mapping here
  // Map the selected worker type ID to its label
  renderInput={(params) => (
    <TextField
      {...params}
      label="Worker Type"
      variant="outlined"
      inputProps={{ ...params.inputProps, style: inputTextStyle }}
    />
  )}
  PaperComponent={CustomPaperComponent}
/>

                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="name" value={formData.name} label=" Name" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} disabled/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="contactAddress" value={formData.contactAddress}   label="Address" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.contactAddress}
                    helperText={formErrors.contactAddress}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="email"label="Email"  value={formData.email}  variant="outlined" fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.email}
                    helperText={formErrors.email}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="password" label="Password" value={formData.password}  variant="outlined" fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.password}
                    helperText={formErrors.password}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField name="phone" label="Phone" variant="outlined" value={formData.phone} disabled fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.phone}
                    helperText={formErrors.phone}/>
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <TextField name="storeId" label="StoreId" variant="outlined"  value={formData.storeId} fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}/>
                </Grid> */}
              
              </>
            )}
          </Grid>
       
      </DialogContent>
      <DialogActions>
      <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}
          >
        {props.vendor ? t('Update') : t('Add')}
          </Button>
        
        <Button
          className={globalClasses.modalCancleBtn}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(AddLocalLocal);
