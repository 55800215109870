import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { getproductsforsa, SearchProducts } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableForProduct'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper,
  Box,
  Badge,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../components/TableHeader'
import AddProduct from '../components/AddProduct/AddProduct'
import { Icon } from '@iconify/react';
import SearchBar from '../components/TableHeader/SearchBar'
import TuneIcon from '@mui/icons-material/Tune';
import AllPRoductFIlter from '../utils/fIlters/AllPRoductFIlter'
const GET_PRODUCTS = gql`
  ${getproductsforsa}
`

const GET_SEARCH_PRODUCTS = gql`
  ${SearchProducts}
`
// const DELETE_PRODUCTS = gql`
// ${deleteproducts} `

const Products = props => {
  const { t } = props;
  const [editModal, setEditModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const golbalClasses = useGlobalStyles()
  
  const [badgeContent, setbadgeContent] = useState(false);
  const [searchQuery, setSearchQuery] = useState('')
  
  const [OpenFilter, setOpenFilter] = useState(false)
  var [FilterPaload, setFilterPaload] = useState({})
  var [DefaultValue, setDefaultValue] = useState({})
  
  var [tabledata, setTabledata] = useState(null);
  const [relaod, setReload] = useState(false)
  // const [openSnackk, setopenSnackk] = useState(false);
  // Define a state to hold your query data
  const client = useApolloClient();
  // let [error, errorSetter] = useState('');
  // const [error, errorSetter] = useState('');
  // const [success, successSetter] = useState('');
  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };
  const handleOpenFilter = () => {
    setOpenFilter(true)
  }
  const onChangeSearch = e => {
    setSearchQuery(e.target.value)
    setTimeout(() => {
      refetchSearch()
    }, 1000)

  }
  const onClickRefetch = () => {
    refetch()
    setbadgeContent(false)
    setReload(!relaod)
    setFilterPaload({});
    setDefaultValue({});
  }
  const openaAddVendorModal = () => {
    setAddVendorModal(true);
  };
  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };
  const [page, setPage] = useState(
    1
  );

  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const variables = {
    // storeId: userDetails.storeId,
    limit: perPage,
    skip: (page - 1) * perPage,
    // skip: page,
  };
  const variablesSearch = {
    text: searchQuery, // Assuming `text` is the variable used in your search query
    limit: perPage,
    skip: (page - 1) * perPage,
  };

  const { data: dataSearch, refetch: refetchSearch } = useQuery(
    GET_SEARCH_PRODUCTS, {
    variables: variablesSearch,

    onCompleted: (data) => {
      setTabledata(data && dataSearch.searchProducts.products ? dataSearch.searchProducts.products : [])
      setTotalRows(data.searchProducts.totalCount);
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  )
  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_PRODUCTS, {
    variables,
    onCompleted: (data) => {
      setTabledata(data && dataSearch.searchProducts.products ? dataSearch.searchProducts.products : [])
      setTotalRows(data.searchProducts.totalCount);
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  },

  )
  useEffect(() => {
    if (data) {
      setTotalRows(data.getProducts.totalCount);
    }
  }, [data,relaod]);

  // Update the query variables when the page value changes
  useEffect(() => {
    refetch({
      variables: {
        limit: perPage,
        skip: (page - 1) * perPage,
      },
    });
  }, [page, perPage, refetch]);


  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)

  };
  // const [mutate, { loadingData }] = useMutation(DELETE_PRODUCTS, {
  //   refetchQueries: [{ query: GET_PRODUCTS }],
  //   onError: ({ graphQLErrors, networkError }) => {
  //     if (graphQLErrors) {
  //       errorSetter('An error occurred while deleting the user.');
  //       setTimeout(hideAlert, 3000);
  //       setopenSnackk(true)
  //     }
  //     if (networkError) {
  //       errorSetter(networkError.message);
  //       setTimeout(hideAlert, 3000);
  //     }
  //   },
  //   onCompleted: () => {
  //     const message = 'Admin Deleted Successfully';
  //     errorSetter('');
  //     props.refetch()
  //     successSetter(message);
  //     setTimeout(hideAlert, 3000);
  //     setopenSnackk(true)
  //   }
  // })

  var filtered = data && data.getProducts.products
  useEffect(() => {
    if (data) {
      var filtered = data.getProducts.products;
      setTabledata(filtered);
    }
  }, [data ,relaod]);
  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])
  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }

  // const hideAlert = () => {
  //   errorSetter('');
  //   successSetter('');
  //   setopenSnackk(false)
  // };

  const columns = [
    {
      name: "Product ID",
      selector: '_id',
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Product Name',
      cell: row => row.catalog.name,
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: 'Description',
      cell: row => row.catalog.description,
      style: { cursor: 'pointer', minWidth: "300px" },
    },
    {
      name: 'Aliases',
      cell: row => row.aliases.join(', ')
    },

    {
      name: 'Category Name',
      cell: row => row.category.name,
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Catalog Name',
      cell: row => row.catalog.name
    },

    {
      name: 'Images ',
      cell: row => (
        <div>
          {row.images.length > 0 &&
            row.images.map((imag, index) => (
              <img key={index} src={imag} alt={`Imag ${index + 1}`} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
            ))}
        </div>
      )
    },
    {
      name: t('Action'),
      cell: row => <>{actionButtons(row)}</>
    }
  ];

  const CloseFilter = () => {
    setOpenFilter(false)
  }
  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {

                  }, 5000)
                  // uncomment this for paud version
                  toggleModal(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
              <MenuItem
                disabled
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                  }, 5000)
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      <Container className={golbalClasses.flex}>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button

            className={golbalClasses.dashbordBtn}
            onClick={openaAddVendorModal}
          >
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} /> {t('AddNewProduct')}
          </Button>
        </Box>
        {addVendorModal ? <Grid container>
          <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
            <Grid
              sx={{ display: { xs: 'none', lg: 'block' } }}
              item
              mt={5}
              ml={-2}
              order={{ xs: 1, lg: 2 }}
            >
              <Modal
                open={addVendorModal}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClose={closeAddVendorModal}
              >
                <AddProduct onClose={closeAddVendorModal} reload={addVendorModal} refetch={refetch} />
              </Modal>
            </Grid>
          </Grid>
        </Grid>
          :
          null
        }
        {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
            subHeaderComponent={
              <>
                <SearchBar
                  value={searchQuery}
                  onChange={onChangeSearch}
                  onClick={() => onClickRefetch(refetch)}
                />
                {/* <Box style={{ marginTop: -40 }}>
                  {badgeContent === true ? (
                    <Badge
                      badgeContent={''}
                      color="success"
                      variant="dot"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Button
                        onClick={handleOpenFilter}
                      >

                        <TuneIcon />
                      </Button>
                    </Badge>
                  ) : <Button
                    onClick={handleOpenFilter}
                  >

                    <TuneIcon />
                  </Button>}
                </Box> */}
              </>
            }
            title={<TableHeader title={t('Manage Products')} />}
            columns={columns}
            data={tabledata ||[]}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            sortFunction={customSort}
            customStyles={customStyles}
          />
        )}
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <AddProduct vendor={vendors} onClose={closeEditModal} reload={addVendorModal} refetch={refetch} />
        </Modal>
        {/* {OpenFilter &&
          <AllPRoductFIlter open={OpenFilter} close={CloseFilter} filterData={filterData} DefaultValue={DefaultValue} handleResetFilters={handleResetFilters} />
        } */}
      </Container>
    </>
  )
}

export default withTranslation()(Products)
