/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useQuery, gql, useApolloClient, useLazyQuery } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import CustomLoader from '../components/Loader/CustomLoader'
// core components
import Header from '../components/Headers/Header'
import {  getorderFilter, GetRiderAllotmentReport } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { Container, Button, useTheme, Box, Badge, } from '@mui/material'
import { customStyles } from '../utils/tableStyleFOrAllOrders'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'
import TuneIcon from '@mui/icons-material/Tune';
import AllOrderFilter from '../utils/fIlters/AllOrderFilter'

const GET_RIDERDETAILS = gql`
  ${GetRiderAllotmentReport}
`
const GET_RIDERDETAILS_filter = gql`
  ${getorderFilter}
`


const RiderReportList = props => {
  const { t } = props;
  const theme = useTheme();
  const client = useApolloClient();
  const globalClasses = useGlobalStyles()
  const userType = JSON.parse(localStorage.getItem('user-enatega'))
  const [page, setPage] = useState(
    1
  );
  var [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  var [FilterPaload, setFilterPaload] = useState({})
  var [DefaultValue, setDefaultValue] = useState({})
  const [badgeContent, setbadgeContent] = useState(false);
  const [reload, setReload] = useState(false)

  const [refetchfilterData] = useLazyQuery(GET_RIDERDETAILS_filter, {
    fetchPolicy: 'no-cache',

    onCompleted: (resultData) => {
      if (resultData && resultData.getAllOrdersByFilter) {
        tableData = resultData.getAllOrdersByFilter.orders || []
        setTableData(tableData);
        setTotalRows(resultData.getAllOrdersByFilter.totalCount || 0);
        CloseFilter()
      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },

  });

  const { data, loading: loadingQuery, refetch } = useQuery(GET_RIDERDETAILS, {
    fetchPolicy: 'network-only',
    variables: {
        "lsId":userType.storeId,
      limit: perPage,
      skip: (page - 1) * perPage,
    },
    onCompleted: (data) => {
      if (data && data.getRiderAllotmentReport) {
        tableData = data.getRiderAllotmentReport.riderAllotments || []
        setTableData(tableData);
        setTotalRows(data.getRiderAllotmentReport.totalCount || 0);
      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });
  useEffect(() => {
    if (data) {
      setTotalRows(data.getRiderAllotmentReport.totalCount);
      tableData = data.getRiderAllotmentReport.riderAllotments
      setTableData(tableData)
    }
  }, [data, reload]);
  // Update the query variables when the page value changes
  useEffect(() => {

    if(badgeContent === true){

      refetchfilterData({
        variables:  {
          filterInput: {
            endDate: DefaultValue.endDate,
            lsName: DefaultValue.lsName,
            orderStatus: DefaultValue.orderStatus,
            startDate: DefaultValue.startDate,
            workerEmail: DefaultValue.workerEmail,
            workerStatus: DefaultValue.workerStatus,
          },
          limit: perPage,
          skip: (page - 1) * perPage,
        },
      })
    }
    else  {
      refetch({
        variables: {
          limit: perPage,
          skip: (page - 1) * perPage,
        },
      });
    }
 
  }, [page, perPage
    // , refetch
  ]);

  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)
  };
  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }

  const columns = [
    {
      name: 'Order ID',
      selector: 'createdAt',
      style: { cursor: 'pointer' },
    },
    {
      name: 'Assigned Orders',
      cell: row => {
        const assignedOrdersCount = row.assigned ? row.assigned.reduce((acc, rider) => acc + rider.totalOrders, 0) : 0;
        return <span>{assignedOrdersCount}</span>;
      }
    },
    {
        name: 'Unassigned Orders',
        cell: row => {
          const unassignedOrdersCount = row.unassigned ? row.unassigned.length : 0;
          return <span>{unassignedOrdersCount}</span>;
        }
      },
   
   
    {
      name: 'Total Orders',
      cell: row => {
        const totalOrdersCount = row.orders ? row.orders.length : 0;
        return <span>{totalOrdersCount}</span>;
      }
    },
    {
        name: 'Riders',
        cell: row => {
          const ridersCount = row.riders ? row.riders.length : 0;
          return <span>{ridersCount}</span>;
        }
      },
    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>,
    },
  ];
  
 

  const filterData = (filterInput) => {
    setDefaultValue(filterInput)
    FilterPaload = {
      filterInput: {
        endDate: filterInput.endDate,
        lsName: filterInput.lsName,
        orderStatus: filterInput.orderStatus,
        startDate: filterInput.startDate,
        workerEmail: filterInput.workerEmail,
        workerStatus: filterInput.workerStatus,
      },
      limit: perPage,
      skip: (page - 1) * perPage,
    }
    //  setFilterPaload(filterInput)
    refetchfilterData({
      variables: FilterPaload,
    })
    setbadgeContent(true)
  }

  const actionButtons = row => {

    return (
      <>
        <Button
          size="20px"
          variant="contained"
          sx={{
            fontWeight: 'bold',
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.common.white,
            padding: 0,
            height: '15px',
            fontSize: '7px',
            '&:hover': {
              border: '2px solid #637BFE',
              color: theme.palette.warning.dark,
              backgroundColor: "white"
            }
          }}
          onClick={e => {
            e.preventDefault()
            localStorage.setItem('riderReportDetails', JSON.stringify(row))
            const userType = JSON.parse(localStorage.getItem('user-enatega')).userType;
            let routePath = '';
            switch (userType) {
              case 0:
                routePath = '/super_admin/OrderDetails';
                break;
              case 1:
                routePath = '/cs_admin/OrderDetails';
                break;
              case 2:
                routePath = '/local_admin/riderreportDetails';
                break;
              default:
                routePath = '/super_admin/OrderDetails'; // Default to super admin if userType is not defined
                break;
            }
            props.history.push({
              pathname: routePath,
              state: { id: row._id }
            });
          }}
        >
          {t('ViewDetails')}
        </Button>
      </>
    )
  }
  const [OpenFilter, setOpenFilter] = useState(false)
  const handleOpenFilter = () => {
    setOpenFilter(true)
  }
  const CloseFilter = () => {
    setOpenFilter(false)
  }
  const handleResetFilters = () => {
    setFilterPaload({});
    setDefaultValue({});
    setPage(1);
    // refetch({
    //   variables: {
    //     limit: perPage,
    //     skip: (page - 1) * perPage,
    //   },
    // });
    setReload(!reload)
    setbadgeContent(false)
  };
  return (
    <>
      {OpenFilter &&
        <AllOrderFilter open={OpenFilter} close={CloseFilter} filterData={filterData} DefaultValue={DefaultValue} handleResetFilters={handleResetFilters} />
      }
      <Header />
      <Container className={globalClasses.flex} fluid>
        {loadingQuery ? (
          <CustomLoader />
        ) :
          <DataTable
            customStyles={customStyles}
            subHeaderComponent={
              <Box style={{ marginTop: -40 }}>
                {badgeContent === true ? (
                  <Badge
                    badgeContent={''}
                    color="success"
                    variant="dot"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Button
                      onClick={handleOpenFilter}
                    >

                      <TuneIcon />
                    </Button>
                  </Badge>
                ) : <Button
                  onClick={handleOpenFilter}
                >

                  <TuneIcon />
                </Button>}
              </Box>
            }
            subHeader={true}
            title={<TableHeader title={t('Rider Report')} />}
            columns={columns}
            data={tableData || []}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            sortFunction={customSort}
          />
        }
      </Container>
    </>
  )
}
export default withTranslation()(RiderReportList)
