import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import {  getsinglecategory } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import SearchBar from '../components/TableHeader/SearchBar'
import { customStyles } from '../utils/tableForManageSubCategory'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper, Box, Switch
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../components/TableHeader'
import Alert from '../components/Alert'
import AddSubCategory from '../components/AddSubCategory/AddSubCategory'
import { Icon } from '@iconify/react';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const GET_SINGLE_CATEGORY_DETAILS = gql`
  ${getsinglecategory}
`

const ManageSubCategory = props => {
  const catagery_id = localStorage.getItem('catagery_id')
  const catagery_Name = localStorage.getItem('CategoryName')
  const { t } = props;
  const [editModal, setEditModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const onChangeSearch = e => setSearchQuery(e.target.value)
  const golbalClasses = useGlobalStyles()

  const history = useHistory();

  const client = useApolloClient();
  const handleBackClick = () => {
    history.push('/super_admin/mangecategory'); // Navigate back to ManageCategory component
  };
  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };

  const openaAddVendorModal = () => {
    setAddVendorModal(true);
  };
  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };


  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_SINGLE_CATEGORY_DETAILS,
    {
      variables: {

        categoryId: catagery_id
      },
      onError: error => {
        const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

        if (isForbiddenError) {
          localStorage.removeItem('user-enatega');
          localStorage.removeItem('restaurant_id');
          client.clearStore();
          props.history.push('/auth/login');
        }
      },
    }
  )




  const regex =
    searchQuery.length > 2 ? new RegExp(searchQuery.toLowerCase(), 'g') : null

  const filtered =
    searchQuery.length < 3
      ? data && data.getCategory.subcategories
      : data &&
      data.getCategory.subcategories.filter(vendor => {
        return vendor.email.toLowerCase().search(regex) > -1
      })

  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    // localStorage.removeItem('restaurant_id')
  }, [catagery_id])

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }
  

  const availableStatus = row => {
    return (
      <>
        {row.isActive}
        <Switch
          size="small"
          defaultChecked={row.isActive}
          onChange={_event => {
            // mutateToggle({ variables: { id: row._id } })
          }}
          style={{ color: 'black' }}
        />
      </>
    )
  }
  const columns = [
    {
      name: "Sl No.",
      cell: (row, index) => <>{index + 1}</>,
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: "Category ID",
      sortable: true,
      selector: '_id',
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: "Description",
      sortable: true,
      selector: 'description',
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Images',
      style: { cursor: 'pointer', minWidth: "150px" },
      cell: row => (
        <>
          {row.images.map((images, index) => (
            <img alt={`Imag ${index + 1}`}
              key={images}
              src={images}
              style={{ width: 30, height: 30, cursor: 'pointer', marginRight: 5 }}
            />
          ))}
        </>
      ),
    },

    {
      name: "Status",
      cell: row => <>{availableStatus(row)}</>,
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: t('Action'),
      cell: row => <>{actionButtons(row)}</>,
    },
  ];

  // useEffect(() => {
  //   // Cleanup function to remove localStorage items when component unmounts
  //   return () => {
  //     localStorage.removeItem('catagery_id');

  //   };
  // }, []);
  useEffect(() => {
    const handleRouteChange = () => {
      localStorage.removeItem('catagery_id');
      // You can also perform other cleanup tasks if needed
    };

    const unlisten = history.listen(handleRouteChange);

    return () => {
      unlisten(); // Stop listening to route changes
    };
  }, [history]); // Add history as a dependency to ensure the effect runs on route changes


  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(false)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  toggleModal(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
              <MenuItem
                disabled
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(true)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  // mutate({ variables: { id: row._id } });
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      {isOpen && (
        <Alert
          message={t('AvailableAfterPurchasing')}
          severity="warning"
        />
      )}

      <Container className={golbalClasses.flex}>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button
            
            className={golbalClasses.dashbordBtn}
            onClick={openaAddVendorModal}
          >
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} /> {t('AddNewSubCategory')}
          </Button>
          <Button className={golbalClasses.dashbordBtn} onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
        {addVendorModal ? <Grid container>
          <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
            <Grid
              sx={{ display: { xs: 'none', lg: 'block' } }}
              item
              mt={5}
              ml={-2}
              order={{ xs: 1, lg: 2 }}
            >
              <Modal
                open={addVendorModal}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClose={closeAddVendorModal}
              >
                <AddSubCategory onClose={closeAddVendorModal} refetch={refetch} />
                {/* <VendorComponent onClose={closeAddVendorModal} /> */}
              </Modal>
            </Grid>
          </Grid>
        </Grid>
          : null}

        {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
            subHeaderComponent={
              <SearchBar
                value={searchQuery}
                onChange={onChangeSearch}
                onClick={() => refetch()}
              />
            }
            title={<TableHeader title={`${catagery_Name} - ${t('SubCategory')}`} />}
            columns={columns}
            data={filtered}
            // data={updatedData}
            pagination
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            sortFunction={customSort}
            customStyles={customStyles}
            onRowClicked={row => {
              localStorage.setItem('restaurantId', row._id)
              localStorage.setItem('restaurantImage', row.image)
              localStorage.setItem('restaurantName', row.name)
            }}
          />
        )}
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <AddSubCategory vendor={vendors} onClose={closeEditModal} refetch={refetch} />
        </Modal>
      </Container>
    </>
  )
}

export default withTranslation()(ManageSubCategory)
