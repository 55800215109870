

import React, { useEffect, useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { updateskudetails, createsku, UpdateSKUPriceByCustomerType, CreateStock } from '../../apollo';
import { Button, Box, Snackbar, Alert, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';


const CREATE_STOCK = gql`
${CreateStock}`
const EDIT_SKU = gql`
  ${updateskudetails}
`;

const CREATE_SKU = gql`
  ${createsku}
`;

const UPDATEPRICE = gql`
${UpdateSKUPriceByCustomerType}`;

function AddProduct(props) {
  const { onClose } = props;
  const formRef = useRef();
  const mutation = props.vendor ? EDIT_SKU : CREATE_SKU;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const localData = JSON.parse(localStorage.getItem('skuRow')) || {};
  const [openSnackk, setopenSnackk] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isNormalCutomerPrice , setIsNormalCustomerPrice]= useState(false)
  const [isPremiumCutomerPrice , setIsPremiumCustomerPrice]= useState(false)
  const [formData, setFormData] = useState({
    localStockID: "",
    localStockName: "",
    localStockQuantity: "",
    _id: props.vendor ? props.vendor._id : "",
    barcode: props.vendor ? props.vendor.barcode : '',
    discount: props.vendor ? props.vendor.discount : 0,
    isActive: props.vendor ? props.vendor.isActive : true,
    localStocks: props.vendor ? props.vendor.localStocks : [],
    measure: props.vendor ? props.vendor.measure : '',
    minimumQty: props.vendor ? props.vendor.minimumQty : 10,
    mrp: props.vendor ? props.vendor.mrp : 0,
    productId: props.vendor ? props.vendor.productId : localData.productId,
    sellingPrice: props.vendor ? props.vendor.sellingPrice : 0,
    stockId: props.vendor ? props.vendor.stockId : localData.ckId,
    stockUnits: props.vendor ? props.vendor.stockUnits : 1,
    storeId: props.vendor ? props.vendor.storeId : localData.storeId,
    skuId: localData.skus ? localData.skus[0]._id : "",
    UserskuId: "",
    unit: props.vendor ? props.vendor.unit : 'KG',
    normalCutomerSellingPrice: props.vendor ? props.vendor.prices[0] ? props.vendor.prices[0].sellingPrice : "" : '',
    premiumCutomerSellingPrice: props.vendor ? props.vendor.prices[1] ? props.vendor.prices[1].sellingPrice : "" : "",
  });

  const calculateDiscount = () => {
    const { sellingPrice, mrp } = formData;
    if (sellingPrice && mrp) {
      const calculatedDiscountValue = ((mrp - sellingPrice) / mrp) * 100;
      if (calculatedDiscountValue > 0) {
        // setCalculatedDiscount(calculatedDiscountValue.toFixed(2)); // Set only if positive
      } else {
        // setCalculatedDiscount(0); // Set to 0 if calculated discount is not positive
      }
    } else {
      // setCalculatedDiscount(0); // Set to 0 if either selling price or MRP is not provided
    }
  };

  useEffect(() => {
    if (formData.sellingPrice !== '' && formData.mrp !== '') {
      calculateDiscount();
    } else {
      // setCalculatedDiscount(0); // Set to 0 if either selling price or MRP is not provided
    }
  }, [formData.sellingPrice, formData.mrp]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update form data state
    if(name === 'premiumCutomerSellingPrice' ){
      setIsPremiumCustomerPrice(true)
    }
    if(name=== 'normalCutomerSellingPrice'){
      setIsNormalCustomerPrice(true)
    }
    setFormData({ ...formData, [name]: value });
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };

  const validateFormData = () => {
    let errors = {};
    if (!formData.UserskuId && !props.vendor) errors.UserskuId = 'User SKU ID is required';
    if (!formData.localStockID && !props.vendor) errors.localStockID = 'Stock ID is required';
    if (!formData.localStockName && !props.vendor) errors.localStockName = 'Stock Name is required';
    if (!formData.localStockQuantity && !props.vendor) errors.localStockQuantity = 'Stock Quantity is required';
    if (!formData.barcode) errors.barcode = 'Barcode is required';
    if (!formData.mrp) errors.mrp = 'MRP is required';
    if (!formData.unit) errors.unit = 'Unit is required';
    if (!formData.measure) errors.measure = 'Measure is required';
    if (!formData.minimumQty) errors.minimumQty = 'Minimum Quantity is required';
    if (props.vendor && !formData.normalCutomerSellingPrice) errors.normalCutomerSellingPrice = 'Normal Customer Selling Price is required';
    if (props.vendor && !formData.premiumCutomerSellingPrice) errors.premiumCutomerSellingPrice = 'Premium Customer Selling Price is required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onCompleted = async (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('SKU Updated Successfully') : t('SKU Added Successfully');
    errorSetter('');
    successSetter(message);
    setopenSnackk(true)
    if (props.vendor) {
      // Call updatePrice API for normal and premium customer selling prices
      if (formData.normalCutomerSellingPrice !== 'NA' && isNormalCutomerPrice) {
        await updatePrice({
          variables: {
            customerType: 0,
            skuId: formData._id ? formData._id : formData.skuId,
            price: parseFloat(formData.normalCutomerSellingPrice),
          },
        });
      }
      if (formData.premiumCutomerSellingPrice !== 'NA' && isPremiumCutomerPrice) {
        await updatePrice({
          variables: {
            customerType: 1,
            skuId: formData._id ? formData._id : formData.skuId,
            price: parseFloat(formData.premiumCutomerSellingPrice),
          },
        });
      }
    }
    
    setTimeout(hideAlert, 3000);
    props.refetch()
  };

  const onError = ({ graphQLErrors, networkError }) => {

    successSetter('');
    if (graphQLErrors) {
      error = "Something Went Wrong!"
      errorSetter(error);
      setopenSnackk(true)
    }

    else if (networkError) {
      errorSetter("Something Went Wrong!");
      setopenSnackk(true);
    }
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
  };
  const [createStock] = useMutation(CREATE_STOCK, { onError, onCompleted });
  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    onError,
    onCompleted,
  });

  const handleFormSubmit = async (e) => {
    const stockInput = {
      _id: formData.localStockID || null,
      name: formData.localStockName || null,
      quantity: parseFloat(formData.localStockQuantity) || null,
      storeId: formData.storeId || null,
    };
    try {
      const { data: stockData } = await createStock({
        variables: { stockInput },
      });
      const stockId = stockData.createStock._id;
      if (stockId) {
        const skuInput = {
          _id: formData.UserskuId,
          barcode: formData.barcode,
          discount: 0,
          measure: formData.measure,
          minimumQty: formData.minimumQty,
          mrp: parseFloat(formData.mrp),
          productId: formData.productId,
          // sellingPrice: parseFloat(formData.sellingPrice),
          stockId: stockId,
          stockUnits: formData.stockUnits,
          storeId: formData.storeId,
          unit: formData.unit,
        };
        await mutate({
          variables: { skuInput },
        });
        onCompleted();
      }
    } catch (error) {
      onError(error);
    }
  };

  const clearFields = () => {
    formRef.current.reset();
    setFormErrors({});
    setFormData({
      name: '',
      description: '',
      catalogId: '',
      category: '',
      defaultImage: '',
      displayName: '',
      hsn: '',
      images: '',
      isNewProduct: '',
      measure: '',
      subcategory: '',
      aliases: '',
      catalogDefault: '',
    });
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const [updatePrice] = useMutation(UPDATEPRICE, {
    onError,
  });

  // useEffect(() => {

  //   if (props.vendor) {
  //     if (formData.normalCutomerSellingPrice !== 'NA') {
  //       updatePrice({
  //         variables: {
  //           customerType: 0,
  //           skuId: formData._id ? formData._id : formData.skuId,
  //           price: parseFloat(formData.normalCutomerSellingPrice),
  //         },
  //       });
  //     }

  //   }

  // }, [formData.normalCutomerSellingPrice]);

  // useEffect(() => {

  //   if (props.vendor) {
  //     if (formData.premiumCutomerSellingPrice !== 'NA') {
  //       updatePrice({
  //         variables: {
  //           customerType: 1,
  //           skuId: formData._id ? formData._id : formData.skuId,
  //           price: parseFloat(formData.premiumCutomerSellingPrice),
  //         },
  //       });
  //     }
  //   }

  // }, [formData.premiumCutomerSellingPrice]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      // setOpenSnack(true);
      return;
    }
    if (true) {
      props.vendor
        ? mutate({
          variables:
          {
            skuUpdate: {
              _id: formData._id,
              barcode: formData.barcode,
              // discount: formData.discount,
              discount: 0,
              measure: formData.measure,
              minimumQty: formData.minimumQty,
              mrp: parseFloat(formData.mrp),
              productId: formData.productId,
              // sellingPrice:parseFloat(formData.sellingPrice) ,
              stockId: formData.stockId,
              stockUnits: formData.stockUnits,
              storeId: formData.storeId,
              unit: formData.unit,
            },
          }


        }) : handleFormSubmit()

      // Close the modal after 4 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose(); // Close the modal
        }
      }, 4000);
    }
  };
  return (
    <Dialog open={true} maxWidth="sm" fullWidth onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }}>
      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor ? t('Edit SKU ') : t('Add SKU ')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <form ref={formRef}>
            <Grid container spacing={2}>
              {
                !props.vendor ?
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField name="UserskuId" label="SKU ID" variant="outlined" fullWidth value={formData.UserskuId} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}

                        error={!!formErrors.UserskuId}
                        helperText={formErrors.UserskuId}
                        disabled={!!props.vendor} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name="localStockID" label="Stock ID" variant="outlined" fullWidth value={formData.localStockID} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                        error={!!formErrors.localStockID}
                        helperText={formErrors.localStockID}
                        disabled={!!props.vendor} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField name="localStockName" label="Stock Name" variant="outlined" fullWidth value={formData.localStockName} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                        error={!!formErrors.localStockName}
                        helperText={formErrors.localStockName}
                        disabled={!!props.vendor} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name="localStockQuantity" label="Stock Qunaity" variant="outlined" fullWidth value={formData.localStockQuantity} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                        error={!!formErrors.localStockQuantity}
                        helperText={formErrors.localStockQuantity}
                        disabled={!!props.vendor} />
                    </Grid>

                  </> : null
              }
              <Grid item xs={12} sm={6}>
                <TextField name="barcode" label="Barcode" variant="outlined" fullWidth value={formData.barcode} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                  error={!!formErrors.barcode}
                  helperText={formErrors.barcode} />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <TextField name="sellingPrice" label="Selling Price" variant="outlined" fullWidth value={formData.sellingPrice} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}/>
            </Grid> */}
              {props.vendor ?
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField name="normalCutomerSellingPrice" label="Normal Customer Selling Price" variant="outlined" fullWidth value={formData.normalCutomerSellingPrice} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                      error={!!formErrors.normalCutomerSellingPrice}
                      helperText={formErrors.normalCutomerSellingPrice} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="premiumCutomerSellingPrice" label="Premium Customer Selling Price" variant="outlined" fullWidth value={formData.premiumCutomerSellingPrice} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                      error={!!formErrors.premiumCutomerSellingPrice}
                      helperText={formErrors.premiumCutomerSellingPrice} />
                  </Grid>
                </>
                : null
              }
              <Grid item xs={12} sm={6}>
                <TextField name="mrp" label="MRP" variant="outlined" fullWidth value={formData.mrp} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                  error={!!formErrors.mrp}
                  helperText={formErrors.mrp} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField name="unit" label="Unit" variant="outlined" fullWidth value={formData.unit} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                  error={!!formErrors.unit}
                  helperText={formErrors.unit} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField name="measure" label="Measure" variant="outlined" fullWidth value={formData.measure} onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField name="minimumQty" label="Minimum Qty" variant="outlined" fullWidth value={formData.minimumQty} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                  error={!!formErrors.minimumQty}
                  helperText={formErrors.minimumQty} />
              </Grid>

            </Grid>
          </form>
        </DialogContent>
        <DialogActions>

          <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}
          >
            {props.vendor ? t('Update') : t('Add ')}
          </Button>
          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (

          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(AddProduct);
