import React from 'react';

const Message = ({ notification }) => {
  return (
    <React.Fragment>
      <div id="notificationHeader">
        {/* image is optional */}
        {notification.image && (
          <div id="imageContainer" alt=''>
            <img src={notification.image} width={100} />
          </div>
        )}
        <span>{notification.title}</span>
      </div>
      <div id="notificationBody">{notification.body}</div>
    </React.Fragment>
  );
};

export default Message;
