import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { useQuery, useMutation, gql, useApolloClient } from '@apollo/client'
import { getallcentralstoreadmin, deletecsadmin } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableStyleForLocalUsers'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'
import AddUsers from '../components/AddUser/AddUsers'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper,
  Box,
  Alert,
  Snackbar
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Icon } from '@iconify/react';


const GET_ALL_CS_ADMIN = gql`
  ${getallcentralstoreadmin}
`
const DELETE_CSADMIN = gql`
  ${deletecsadmin}`
const Users = props => {
  const { t } = props
  const [editModal, setEditModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const client = useApolloClient();
  const [openSnackk, setopenSnackk] = useState(false);
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { data, error: errorQuery, loading: loadingQuery, refetch } = useQuery(GET_ALL_CS_ADMIN, {
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  })
  const [mutate] = useMutation(DELETE_CSADMIN, {
    refetchQueries: [{ query: GET_ALL_CS_ADMIN }],
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        errorSetter('An error occurred while deleting the user.');
        setTimeout(hideAlert, 3000);
        setopenSnackk(true)
      }
      if (networkError) {
        errorSetter(networkError.message);
        setTimeout(hideAlert, 3000);
      }
    },
    onCompleted: () => {
      const message = 'Admin Deleted Successfully';
      errorSetter('');
      props.refetch()
      successSetter(message);
      setTimeout(hideAlert, 3000);
      setopenSnackk(true)
    }
  })


  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };
  const golbalClasses = useGlobalStyles()
  const closeEditModal = () => {
    setEditModal(false);
  };

  const openaAddVendorModal = () => {
    setAddVendorModal(true);
  };
  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };
  const columns = [
    {
      name: "Sl No.",
      cell: (row,index) => <>{index + 1}</>,
    },
    {
      name: 'Name',
      sortable: true,
      selector: 'name',
    },
    {
      name: 'Email',
      sortable: true,
      selector: 'email',
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: 'Phone',
      sortable: true,
      selector: 'phone',
    },
    {
      name: 'Store Name',
      sortable: true,
      selector: 'storeName',
    },

    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>,
    }
  ];
  useEffect(() => {
    // Manually trigger refetch when the component mounts
    refetch();
  }, []);
  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }

  const filtered =
    data && data.getCSAdminsForAllStores


  const globalClasses = useGlobalStyles()
  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                  }, 5000)
                  toggleModal(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
              <MenuItem
                disabled
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                  }, 5000)
                  mutate({ variables: { adminId: row._id } });
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }
  return (
    <>
      <Header />
      <Container classNname={globalClasses.flex} fluid>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button
            className={golbalClasses.dashbordBtn}
            onClick={openaAddVendorModal}>
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} /> {t('Add New central User')}
          </Button>
        </Box>
        {addVendorModal ? <Grid container>
          <Grid
            sx={{ display: { xs: 'none', lg: 'block' } }}
            item
            mt={5}
            ml={-2}
            order={{ xs: 1, lg: 2 }}>
            <Modal
              open={addVendorModal}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClose={closeAddVendorModal}>
              <AddUsers onClose={closeAddVendorModal} addVendorModal={addVendorModal} refetch={refetch} />
            </Modal>
          </Grid> </Grid> : null}
        {errorQuery ? (
          <span>
            `${t('Error')}! ${errorQuery.message}`
          </span>
        ) : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}

            title={<TableHeader title={t('Manage Central Admin Users')} />}
            columns={columns}
            data={filtered}
            pagination
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            sortFunction={customSort}
            customStyles={customStyles}
            paginationIconLastPage=""
            paginationIconFirstPage=""
          />
        )}

        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <AddUsers vendor={vendors} editModal={editModal} onClose={closeEditModal} refetch={refetch} />
        </Modal>
        <Box mt={2}>
          {success && (
            <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                {success}
              </Alert>
            </Snackbar>
          )}
          {error && (
            <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                {error}
              </Alert>
            </Snackbar>
          )}
        </Box>
      </Container>
    </>
  )
}
export default withTranslation()(Users)
