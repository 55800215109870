import React, { useEffect, useRef, useState } from 'react';
import { useMutation, gql, useApolloClient, useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { UpdatePremiumCustomerConfig, GetStore } from '../../apollo';
import {
    Button, Grid, TextField, DialogActions, Alert, Snackbar, Switch,
    Container, Box,
    Typography
} from '@mui/material';
import useGlobalStyles from '../../utils/globalStyles';
import Header from '../Headers/Header';

const UPDATE_CUSTOMER_CONFIG = gql`
  ${UpdatePremiumCustomerConfig}
`;
const GET_STORE__LANDINGPAGE = gql`${GetStore}`;

function CustomerConfiguration(props) {
    const globalClasses = useGlobalStyles();
    const { onClose } = props;
    const formRef = useRef();
    const userDetailsStr = localStorage.getItem('user-enatega');
    const userDetails = JSON.parse(userDetailsStr);
    const inputTextStyle = { color: 'black' };
    const mutation = UPDATE_CUSTOMER_CONFIG;
    const [error, errorSetter] = useState('');
    const client = useApolloClient();
    const [success, successSetter] = useState('');
    const [openSnackk, setopenSnackk] = useState(false);
    const { t } = props;
    const [formData, setFormData] = useState({
        purchaseAmount: '',
        expiryInMonths: '',
        daysOfPurchase: '',
        activated: false,
        message: ''
    });

    const {  data, refetch } = useQuery(
        GET_STORE__LANDINGPAGE, {
            fetchPolicy: "no-cache",
            variables: { storeId: userDetails ? userDetails.storeId : null },
            onError: error => {
                const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
                if (isForbiddenError) {
                    localStorage.removeItem('user-enatega');
                    localStorage.removeItem('restaurant_id');
                    client.clearStore();
                    props.history.push('/auth/login');
                }
            },
        }
    );

    useEffect(() => {
        if (data && data.getStore && data.getStore.premiumCustomerConfig) {
            const premiumCustomerConfig = data.getStore.premiumCustomerConfig;
            setFormData({
                purchaseAmount: premiumCustomerConfig.purchaseAmount || '',
                expiryInMonths: premiumCustomerConfig.expiryInMonths || '',
                daysOfPurchase: premiumCustomerConfig.daysOfPurchase || '',
                activated: premiumCustomerConfig.activated || false,
                message: premiumCustomerConfig.message || ''
            });
        }
    }, [data]);

    const handleSwitchChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: checked }));
    };

    const handleFormInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const onCompleted = (data) => {
        const message = props.vendor ? t('Costumer Config updated Successfully') : t('Store Added Successfully');
        errorSetter('');
        successSetter(message);
        setopenSnackk(true);
        refetch();
        setTimeout(hideAlert, 1000);
    };

    const onError = ({ graphQLErrors, networkError }) => {
        successSetter('');
        if (graphQLErrors) {
            errorSetter(graphQLErrors[0].message);
        } else if (networkError) {
            errorSetter(networkError.result.errors[0].message);
        } else {
            errorSetter('Something went wrong!');
        }
        setopenSnackk(true);
        setTimeout(hideAlert, 1000);
    };

    const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
        onError,
        onCompleted,
    });

    const hideAlert = () => {
        errorSetter('');
        successSetter('');
        setopenSnackk(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        mutate({
            variables: {
                purchaseAmount: parseFloat(formData.purchaseAmount),
                expiryInMonths: parseInt(formData.expiryInMonths),
                daysOfPurchase: parseInt(formData.daysOfPurchase),
                activated: formData.activated,
                message: formData.message
            }
        });

        setTimeout(() => {
            if (typeof props.onClose === 'function') {
                props.onClose();
            }
        }, 1000);
    };

    return (
        <>
            <Header />
            <Container className={globalClasses.flexBox}>
                <Grid sx={{ backgroundColor: 'white', py: 4, margin: "1rem", padding: "1rem" }}>
                    <form ref={formRef} onSubmit={onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="purchaseAmount"
                                    label="Purchase Amount"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.purchaseAmount}
                                    onChange={handleFormInputChange}
                                    inputProps={{ style: inputTextStyle }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="expiryInMonths"
                                    label="Expiry in Months"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.expiryInMonths}
                                    onChange={handleFormInputChange}
                                    inputProps={{ style: inputTextStyle }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="daysOfPurchase"
                                    label="Days of Purchase"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.daysOfPurchase}
                                    onChange={handleFormInputChange}
                                    inputProps={{ style: inputTextStyle }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Switch
                                        name="activated"
                                        checked={formData.activated}
                                        onChange={handleSwitchChange}
                                    />
                                    <Typography variant="body1" component="span">
                                        Activated
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="message"
                                    label="Message"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.message}
                                    onChange={handleFormInputChange}
                                    inputProps={{ style: inputTextStyle }}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button
                                type="submit"
                                className={globalClasses.dashbordBtn}
                                disabled={mutateLoading}
                            >
                                {t('Update')}
                            </Button>
                            <Button
                                className={globalClasses.modalCancleBtn}
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Grid>
            </Container>
            <Box mt={2}>
                {success && (
                    <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                            {success}
                        </Alert>
                    </Snackbar>
                )}
                {error && (
                    <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                )}
            </Box>
        </>
    );
}

export default withTranslation()(CustomerConfiguration);
