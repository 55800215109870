
import React from 'react'

const ConfigurationContext = React.createContext({})

export const ConfigurationProvider = props => {

  const dummyData = {
    "__typename": "Configuration",
    "_id": "6464509a2e05958d543c2a60",
    "email": "ramiabureeshDev@gmail.com",
    "emailName": "Enatega NM",
    "password": "fwtniizlrrbdljtk",
    "enableEmail": true,
    "clientId": "AeGIgSX--JEVwoQgLjGOb8gh1DUJG0MFVgLc2mBIe6_V5NefV0LM3L78m01fLLI6U2FFB-qJr4ErrtL1",
    "clientSecret": "EHAP6CSZt3kwzcpdxrpw16PqHEspw5wtJCVVux_95e2Qcwbeh6mQp9GncEbxnVFkEbJu4z1i-GuDDthf",
    "sandbox": true,
    "publishableKey": "pk_test_lEaBbVGnTkzja2FyFiNlbqtw",
    "secretKey": "sk_test_rKNqVc2tSkdgZHNO3XnPCLn4",
    "currency": "USD",
    "currencySymbol": "$",
    "deliveryRate": 5,
    "twilioAccountSid": "ACe6f90639dbaca4a3bfdd1e1bdb35c961",
    "twilioAuthToken": "593358942dbb2ca4a34b5c73a131bb74",
    "twilioPhoneNumber": "+14158437365",
    "twilioEnabled": true,
    "formEmail": "sharan.gohar@gmail.com",
    "sendGridApiKey": "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",
    "sendGridEnabled": true,
    "sendGridEmail": "no-reply@enatega.com",
    "sendGridEmailName": "Enatega",
    "sendGridPassword": "!no-replyPassword55",
    "dashboardSentryUrl": "https://e963731ba0f84e5d823a2bbe2968ea4d@o1103026.ingest.sentry.io/6135261",
    "webSentryUrl": "https://e963731ba0f84e5d823a2bbe2968ea4d@o1103026.ingest.sentry.io/6135261",
    "apiSentryUrl": "https://501ffcd8558640fd92f3420a56c80c5f@o1103026.ingest.sentry.io/6135611",
    "customerAppSentryUrl": "https://91b55f514a2c4708845789d6e79abf10@o1103026.ingest.sentry.io/6131933",
    "restaurantAppSentryUrl": "https://91b55f514a2c4708845789d6e79abf10@o1103026.ingest.sentry.io/6131933",
    "riderAppSentryUrl": "https://e963731ba0f84e5d823a2bbe2968ea4d@o1103026.ingest.sentry.io/6135261",
    "googleApiKey": "AIzaSyB4TvRpmfExUIBMq-C6NZCwS3jYrrfXnlU",
    "cloudinaryUploadUrl": "https://api.cloudinary.com/v1_1/do1ia4vzf/image/upload",
    "cloudinaryApiKey": "tscncotw",
    "webAmplitudeApiKey": "42b182f3d18a21e8ab0ae881c6ef475f",
    "appAmplitudeApiKey": "2114f5db4c014dc7ad4ed2ad747341b5",
    "webClientID": "967541328677-ge2hpr0n095d0nro56kot0t4q388dsll.apps.googleusercontent.com",
    "androidClientID": "967541328677-7264tf7tkdtoufk844rck9mimrve135c.apps.googleusercontent.com",
    "iOSClientID": "967541328677-30n1b9dljqadrr4badeku41980rf2dt1.apps.googleusercontent.com",
    "expoClientID": "967541328677-d46sl62t52g5r3o5m0mnl2hpptr242nl.apps.googleusercontent.com",
    "googleMapLibraries": "places,drawing,geometry,localContext,visualization",
    "googleColor": "#dd4b39",
    "termsAndConditions": "https://multivendor.enatega.com/?_gl=1*1yaissu*_ga*MTcwNTMzMjMyOC4xNjk1MTIwMjgz*_ga_DTSL4MVB5L*MTY5NTI3NDgxMC4yLjEuMTY5NTI3NDg0Ni4yNC4wLjA.&_ga=2.41568289.2005342604.1695274812-1705332328.1695120283#/terms",
    "privacyPolicy": "https://multivendor.enatega.com/?_gl=1*1yaissu*_ga*MTcwNTMzMjMyOC4xNjk1MTIwMjgz*_ga_DTSL4MVB5L*MTY5NTI3NDgxMC4yLjEuMTY5NTI3NDg0Ni4yNC4wLjA.&_ga=2.41568289.2005342604.1695274812-1705332328.1695120283#/privacy",
    "testOtp": "111111",
    "firebaseKey": "AIzaSyDx_iSQ9LroTF7NMm20aRvw2wJqhwSnJ3U",
    "authDomain": "enatega-multivender-web.firebaseapp.com",
    "projectId": "enatega-multivender-web",
    "storageBucket": "enatega-multivender-web.appspot.com",
    "msgSenderId": null,
    "appId": "1:438532750182:web:516b850eff4e0349f0a6a7",
    "measurementId": "G-KLBJSEHRYQ"
}

  const configuration = dummyData

  return (
    <ConfigurationContext.Provider value={configuration}>
      {props.children}
    </ConfigurationContext.Provider>
  )
}
export const ConfigurationConsumer = ConfigurationContext.Consumer
export default ConfigurationContext
