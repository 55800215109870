import React from 'react'
import Header from '../components/Headers/Header';
import useGlobalStyles from '../utils/globalStyles';
import { Box, Typography, Container, Grid, } from '@mui/material';
import { getlsadminDashboard } from '../apollo';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { Icon } from '@iconify/react';
import shopOutline from '@iconify-icons/teenyicons/shop-outline';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import CustomLoader from '../components/Loader/CustomLoader';

const GET_CS_ADMIN_DASHBOARD = gql`${getlsadminDashboard}`;

const LocalAdminDashboard = (props) => {
  const history = useHistory();
  const client = useApolloClient();
  const globalClasses = useGlobalStyles();
 
  const {data    } = useQuery(GET_CS_ADMIN_DASHBOARD,{
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  })


  const sty = {
    fontSize: 35,
    fontWeight: 'bold',
    color: "#0b3978",
    textAlign: 'center'
  };

    // Check if data and getSuperAdminDashboard are defined
    if (!data || !data.getLSAdminDashBoard) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CustomLoader />
        </div>
      );
    }
  
    // Destructure the data object to access the nested properties
    
    const {
        getLSAdminDashBoard: {
        completedOrders,
        replenishCount,
        pendingOrders,
        productCount,
        storeCity,
        storeName,
        totalOrders,
      }
    } = data;

    localStorage.setItem("totalProductsCount" ,productCount )
    localStorage.setItem("totalOrdersCount" ,totalOrders )
 
    const handleClickProducts = () => {
      history.push({
        pathname: '/local_admin/products',
        state: { data: '' },
      });
    };
    const handleClickAllorders= () => {
      history.push({
        pathname: '/local_admin/allorders',
        state: { data: '' },
      });
    };
  
  return (
    <>
      <Header />
      <Container sx={{ ml: 5 }} fluid className={globalClasses.flex_admin_dashboard}>
        <Grid container mt={2} spacing={2}>
           <Grid container mt={2} spacing={2} >
              <Grid item xs={12} md={6} onClick={handleClickProducts}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderRadius: 5,
                    bgcolor: '#CADFFF',
                    width: '80%',
                    mb: 3,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                    <Typography sx={headSty} >
                    {storeName} <br /> {storeCity}
                    </Typography>
                    <Typography sx={sty}>
                    {productCount} Products 
                    </Typography>
                  </div>
                  <Icon icon="dashicons:products" width="40" height="40" style={{ color: "#0b3978" }} />
       </Box>
              </Grid>
              <Grid item xs={12} md={6}>
              <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderRadius: 5,
                    bgcolor: '#CADFFF',
                    width: '80%',
                    mb: 3,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                    <Typography sx={headSty} >
                    Replenish Count
                    </Typography>
                    <Typography sx={sty}>
                    {replenishCount} {/* Replace this with actual data */}
                    </Typography>
                  </div>
                  <Icon icon="iconamoon:category"  width={40} height={40} style={{color: "#0b3978"}} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} onClick={handleClickAllorders}>
              <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderRadius: 5,
                    bgcolor: '#CADFFF',
                    width: '80%',
                    mb: 3,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                    <Typography sx={headSty} >
                      Total Orders
                    </Typography>
                    <Typography sx={sty}>
                    {totalOrders}  
                    </Typography>
                  </div>
                  {/* <Icon icon="bi:recycle" width={40} height={40} color="#0b3978" /> */}
                  <Icon icon={shopOutline} width={40} height={40} color="#0b3978"  />  </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        borderRadius: 5,
                        bgcolor: 'common.white',
                        width: '80%',
                        mb: 3
                      }}
                    >
                      <div style={{ marginBottom: '10px' }}>
                        <Typography sx={headSty}>Order Completed</Typography>
                      </div>
                      <div>
                        <Typography sx={sty}>
                         {completedOrders} {/* Replace this with actual data */}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        borderRadius: 5,
                        bgcolor: 'common.white',
                        width: '80%',
                        mb: 3
                      }}
                    >
                      <div style={{ marginBottom: '10px' }}>
                        <Typography sx={headSty}>Order Pending</Typography>
                      </div>
                      <div>
                        <Typography sx={sty}>
                          {pendingOrders} {/* Replace this with actual data */}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
        
            </Grid>


        </Grid>


      </Container>
    </>
  )
}



const headSty = { fontSize: 15, fontWeight: 'bold' }

export default withTranslation()(LocalAdminDashboard);
