import React, { useEffect, useRef, useState } from 'react';
import { useMutation, gql, useQuery, useApolloClient } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { GetAllCustomerTypes, GetCustomerWallet, UpdateCustomerType } from '../../apollo';
import { Button, Box, Typography, Grid, Paper, TextField, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import { Icon } from '@iconify/react';
import useStyles from '../ChangeCustomerType/styles';
import useGlobalStyles from '../../utils/globalStyles';
import DataTable from 'react-data-table-component';
import TableHeader from '../TableHeader';
import CustomLoader from '../Loader/CustomLoader';

import { customStyles } from '../../utils/customeTableForViewWallet';

const GET_CUSTOMER_WALLET = gql`
  ${GetCustomerWallet}
`;
const CHANGE_CUSTOMER_TYPE = gql`
  ${UpdateCustomerType}
`;

const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

function ViewWallet(props) {
  const { onClose } = props;
  console.log(props.data)
  const formRef = useRef();
  const mutation = CHANGE_CUSTOMER_TYPE;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const [selectedWorker, setSelectedWorker] = useState(props.vendor ? props.vendor.storeName : null);
  const [formErrors, setFormErrors] = useState({});
  const client = useApolloClient();
  const [workerError, setWorkerError] = useState(false);
  const [formData, setFormData] = useState({
    customerType: props.vendor ? props.vendor.customerType : '',
  });
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };
  var [tabledata, setTabledata] = useState(null);
  const [openSnackk, setopenSnackk] = useState(false);
  const [page, setPage] = useState(
    1
  );

  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const variables = {
    customerId: props.data ? props.data._id : "" ,
    limit: perPage,
    skip: (page - 1) * perPage,
    // skip: page,
  };
  const { data: customerWalletDetails ,loading:loadingQuery ,refetch } = useQuery(GET_CUSTOMER_WALLET, {
    variables: variables,
    onCompleted: (data) => {
      setTabledata( customerWalletDetails && customerWalletDetails.getCustomerWallet && customerWalletDetails.getCustomerWallet.transactions ? customerWalletDetails.getCustomerWallet.transactions : [])
      setTotalRows(data.getCustomerWallet.totalTxnCount);
    },
    onError: (error) => {
      const isForbiddenError = error && error.graphQLErrors.some((err) => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });
  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)

  };
  useEffect(() => {
    refetch({
      variables: {
        limit: perPage,
        skip: (page - 1) * perPage,
      },
    });
  }, [page, perPage, refetch]);
  const optionsworkerStatus = customerWalletDetails
   
  console.log(optionsworkerStatus ,"optionsworkerStatus")
  const filtered = customerWalletDetails && customerWalletDetails.getCustomerWallet && customerWalletDetails.getCustomerWallet.transactions ? customerWalletDetails.getCustomerWallet.transactions : [];
  const validateFormData = () => {
    let errors = {};

    // Check for null or undefined, but not 0
    if (formData.customerType === null || formData.customerType === undefined || formData.customerType === "") {
      setWorkerError(true);
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const onCompleted = (data) => {
    if (data && data.updateCustomerType) {
      const message = t('Customer Type Updated Successfully');
      errorSetter('');
      successSetter(message);
      setTimeout(hideAlert, 3000);
      setopenSnackk(true);
      props.refetch()
    }
  };

  const onError = (error) => {
    successSetter('');
    if (error.graphQLErrors) {
      errorSetter(error.graphQLErrors ? error.graphQLErrors[0].message : '');
      setopenSnackk(true);
    } else if (error.networkError) {
      errorSetter(error.networkError.result.errors[0].message);
    } else {
      errorSetter('Something went wrong!');
    }
    setTimeout(hideAlert, 3000);
    setopenSnackk(true);
  };

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    onError,
    onCompleted,
  });

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false);
  };


  const handleWorkerChange = (event, value) => {
    setSelectedWorker(value);
    setFormData({ ...formData, customerType: value ? value.customerType : '' });
    if (formData.customerType !== undefined || formData.customerType !== null) {
      setWorkerError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      return;
    }
    mutate({
      variables: {
        "customerId": props.data && props.data._id,
        "type": formData.customerType,
      },
    });
    setTimeout(() => {
      if (typeof props.onClose === 'function') {
        props.onClose();
      }
    }, 2000);
  };
  const getCustomerTypeName = (customerTypeId) => {
    // const customerType = optionsworkerStatus.find(type => type.customerType === customerTypeId);
    // return customerType ? customerType.label : 'N/A';
  };
  const columns = [
    {
      name: "Sl No",
      selector: (row, index) => index + 1
    },
    {
      name: "Order ID",
      selector: (row) => row.orderId,  // Assuming each transaction has an _id
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      wrap: true
    },
    {
      name: "Description",
      selector: (row) => row.description,
      wrap: true
    },
   
    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toLocaleString(), // Formatting date
      sortable: true,  style: { cursor: 'pointer', minWidth: "200px" },
    },
   
  ];
  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor ? t('Edit Product') : t('Walet Details ')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {!props.vendor ?
              <>
                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                   Customer ID :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                    {customerWalletDetails && customerWalletDetails.getCustomerWallet ? customerWalletDetails.getCustomerWallet.customerId : "N/A"}
              
                  </Typography>
                </Grid>
                </Grid>
              

                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                      Wallet Balanace:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>
                    <Icon icon="mdi:rupee" width="14" height="14" style={{ color: " black", }} />
                    {customerWalletDetails && customerWalletDetails.getCustomerWallet ? customerWalletDetails.getCustomerWallet.balance : "N/A"}
              
                  </Typography>
                </Grid>
                </Grid>
                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                   Transaction Count :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                    {customerWalletDetails && customerWalletDetails.getCustomerWallet ? customerWalletDetails.getCustomerWallet.totalTxnCount  ?customerWalletDetails.getCustomerWallet.totalTxnCount  : "N/A": "N/A"}
              
                  </Typography>
                </Grid>
                </Grid>
              
              

                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                  Message :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                    {customerWalletDetails && customerWalletDetails.getCustomerWallet  ? customerWalletDetails.getCustomerWallet.message && customerWalletDetails.getCustomerWallet.message != null ?customerWalletDetails.getCustomerWallet.message : "N/A"  : "N/A"}
              
                  </Typography>
                </Grid>
                </Grid>
              </>
              :
              <>
                {/* Additional fields for vendor */}
              </>
            }
          </Grid>
          <DataTable
          subHeader={true}

          title={<TableHeader title={t('Trasactions')} />}
          columns={columns}
          data={tabledata ||[]}
          
          progressPending={loadingQuery}
          progressComponent={<CustomLoader />}
          paginationDefaultPage={currentPage}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowChange}
          onChangePage={handlePageChange}
          customStyles={customStyles}
        />
        </DialogContent>
        <DialogActions>
          {/* <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}
          >
            {props.vendor ? 'Update Product' : 'Save'}
          </Button> */}
          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}

export default withTranslation()(ViewWallet);
