import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { updateskuquantity } from '../../apollo';
import { Button, Box, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';


const UPDATE_STOCK = gql`
${updateskuquantity}`

function EditProductQuantityCS(props) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const { onClose } = props;
  const formRef = useRef();
  const mutation = UPDATE_STOCK;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const userType = JSON.parse(localStorage.getItem("user-enatega"))
  const [formData, setFormData] = useState({
    skuID: props.vendor ? props.vendor._id : '',
    productId: props.vendor ? props.vendor.productId : '',
    name: props.vendor && props.vendor.Stock.length > 0 ? props.vendor.Stock[0].name : '',
    description: props.vendor ? props.vendor.description : '',
    catalogId: props.vendor && props.vendor.catalog ? props.vendor.catalog._id : '',
    category: props.vendor && props.vendor.category ? props.vendor.category._id : '',
    defaultImage: props.vendor ? props.vendor.defaultImage : '',
    displayName: props.vendor ? props.vendor.displayName : '',
    hsn: props.vendor ? props.vendor.hsn : '',
    images: props.vendor ? props.vendor.images : [],
    isNewProduct: props.vendor ? props.vendor.isNewProduct : '',
    measure: props.vendor ? props.vendor.measure : '',
    subcategory: props.vendor && props.vendor.category && props.vendor.category.subcategories.length > 0 ? props.vendor.category.subcategories[0]._id : '',
    aliases: props.vendor ? props.vendor.aliases : [],
    catalogDefault: props.vendor && props.vendor.catalog ? props.vendor.catalog.parcelable : false,
    // Assuming you have a quantity field in your Sku object
    quantity: props.vendor && props.vendor.Stock.length > 0 ? props.vendor.Stock[0].quantity : '',
    skuId: props.vendor && props.vendor.Stock.length > 0 ? props.vendor.Stock[0].skuId : '',
    storeId: props.vendor && props.vendor.Stock.length > 0 ? props.vendor.Stock[0].storeId : ''
  });

  const [openSnackk, setopenSnackk] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update form data state
    setFormData({ ...formData, [name]: value });
  };

  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Quantity Updated Successfully') : t('Product Added Successfully');
    errorSetter('');
    successSetter(message);
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
    props.refetch()
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      // error = graphQLErrors[0].message
      errorSetter("Something Went Wrong");
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
    props.refetch()

  };
  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    onError,
    onCompleted,
  });

  const clearFields = () => {
    formRef.current.reset();
    setFormData({
      name: '',
      description: '',
      catalogId: '',
      category: '',
      defaultImage: '',
      displayName: '',
      hsn: '',
      images: '',
      isNewProduct: '',
      measure: '',
      subcategory: '',
      aliases: '',
      catalogDefault: '',
    });
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };



  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
            {t('Edit Product Quantity ')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={2}>


            <>
              <Grid item xs={12} sm={12}>
                <TextField name="quantity" label="Enter Quantity" value={formData.quantity} variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField name="id" label="Product ID" variant="outlined" fullWidth onChange={handleInputChange} value={formData.productId} inputProps={{ style: inputTextStyle }} disabled />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField name="name" label="Product Name" variant="outlined" value={formData.name} fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} disabled />
              </Grid>

            </>


          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className={globalClasses.dashbordBtn}
          disabled={mutateLoading}
          onClick={async (e) => {
            e.preventDefault();
            if (true) {

              mutate({
                variables: {
                  skuId: formData.skuID,
                  storeId: formData.storeId ? formData.storeId : userType.storeId,
                  productId: formData.productId,
                  quantity: parseFloat(formData.quantity),

                }
              });
              setTimeout(() => {
                if (typeof props.onClose === 'function') {
                  props.onClose(); // Close the modal
                }
              }, 4000);
            }
          }}
        >
          {props.vendor ? t('Update') : t('Add')}
        </Button>
        <Button
          className={globalClasses.modalCancleBtn}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(EditProductQuantityCS);
