import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import {
  createlocalstore,
  updatelocalstore,
  UpdateDeliveryBoundsAndLocation
} from '../../apollo'
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar
} from '@mui/material'
import useStyles from './styles'
import useGlobalStyles from '../../utils/globalStyles'
import { GoogleMap, Polygon } from '@react-google-maps/api'
import { transformPolygon } from '../../utils/coordinates'

const CREATE_LOCALL_STORE = gql`
  ${createlocalstore}
`
const EDIT_LOCAL_STORE = gql`
  ${updatelocalstore}
`

const EDIT_LOCAL_STORE_BOUNDS = gql`
  ${UpdateDeliveryBoundsAndLocation}
`

function CreateLocalStore(props) {
  const { onClose, vendor } = props
  const formRef = useRef()
  const mutation = props.vendor ? EDIT_LOCAL_STORE : CREATE_LOCALL_STORE
  let [error, errorSetter] = useState('')
  // const [error, errorSetter] = useState('');
  const [success, successSetter] = useState('')
  const [openSnackk, setopenSnackk] = useState(false)
  const { t } = props
  const userType = JSON.parse(localStorage.getItem('user-enatega'))
  const [formData, setFormData] = useState({
    id: props.vendor ? props.vendor._id : '',
    name: props.vendor ? props.vendor.name : '',
    address: props.vendor ? props.vendor.address : '',
    city: props.vendor ? props.vendor.city : '',
    code: props.vendor ? props.vendor.code : '',
    bounds:
      vendor && vendor.deliveryBounds
        ? vendor.deliveryBounds.coordinates[0].map(coord => ({
            longitude: coord[1],
            latitude: coord[0]
          }))
        : Array(5).fill({ longitude: '', latitude: '' }),
    isActive: props.vendor ? props.vendor.isActive : false,
    location: {
      longitude:
        props.vendor &&
        props.vendor.location &&
        props.vendor.location.coordinates[0] !== undefined
          ? props.vendor.location.coordinates[0]
          : '',
      latitude:
        props.vendor &&
        props.vendor.location &&
        props.vendor.location.coordinates[1] !== undefined
          ? props.vendor.location.coordinates[1]
          : ''
    }
  })

  const [center, setCenterState] = useState(
    props.vendor && props.vendor.deliveryBounds
      ? {
          lat: props.vendor.deliveryBounds.coordinates[0][0][1],
          lng: props.vendor.deliveryBounds.coordinates[0][0][0]
        }
      : { lat: 33.684422, lng: 73.047882 }
  )

  const [formErrors, setFormErrors] = useState({})

  const handleInputChange = e => {
    const { name, value } = e.target

    if (name.startsWith('boundLongitude')) {
      const index = parseInt(name.replace('boundLongitude', ''))
      const bounds = [...formData.bounds]
      bounds[index].longitude = parseFloat(value)
      if (index === 0) {
        bounds[4].longitude = parseFloat(value)
      }
      setFormData({ ...formData, bounds })
    } else if (name.startsWith('boundLatitude')) {
      const index = parseInt(name.replace('boundLatitude', ''))
      const bounds = [...formData.bounds]
      bounds[index].latitude = parseFloat(value)
      if (index === 0) {
        bounds[4].latitude = parseFloat(value)
      }
      setFormData({ ...formData, bounds })
    } else if (name === 'latitude' || name === 'longitude') {
      const location = { ...formData.location, [name]: value }
      setFormData({ ...formData, location })
    } else {
      setFormData({ ...formData, [name]: value })
    }
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' })
    }
  }
  // const validateFormData = () => {
  //   let errors = {};
  //   // // if(!formData._id) error._id= "Sub Category ID is required";
  //   if (!formData.name) errors.name = 'Store Name is required';
  //   if (! formData.address) errors.address = 'Address is required';
  //   if (!formData.city) errors.city = 'City Name is required';
  //   if (!formData.Code) errors.Code = 'Code  is required';
  //   // if (props.vendor && !formData.location.longitude) errors.longitude = 'Longitude is required';
  //   // if (props.vendor && !formData.location.latitude) errors.latitude = 'Latitude is required';
  //   // if(!formData.description) error.description= "Discription is required";
  //   setFormErrors(errors);
  //   return Object.keys(errors).length === 0;
  // };
  const onUpdateCompleted = data => {
    const message = t('Bounds Updated Successfully')
    errorSetter('')
    setTimeout(() => {
      successSetter(message)
      setopenSnackk(true)

      props.refetch()
      setTimeout(hideAlert, 1500)
    }, 1000)
  }

  const onCompleted = data => {
    if (props.vendor) {
      // const bounds =

      const convertedArray = path.map(item => [item.lng, item.lat])
      if (convertedArray.length > 0) {
        convertedArray.push([path[0].lng, path[0].lat]) // Closing the loop
      }
      // Call the bounds and location mutation
      updateBoundsAndLocation({
        variables: {
          storeId: formData.id,
          bounds: [convertedArray],

          location: {
            longitude: formData.location.longitude,
            latitude: formData.location.latitude
          }
        }
      })
    }
    if (!props.vendor) clearFields()
    const message = props.vendor
      ? t('Store Updated Successfully')
      : t('Store Added Successfully')
    errorSetter('')
    successSetter(message)
    setopenSnackk(true)
    props.refetch()
    setTimeout(hideAlert, 3000)
  }
  /* const onError = ({ graphQLErrors, networkError }) => {
    successSetter('')
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      // errorSetter(error);
      errorSetter(error)
    } else if (networkError) errorSetter(networkError.result.errors[0].message)
    else errorSetter('Something went wrong!')
    setTimeout(hideAlert, 3000)
    setopenSnackk(true)
    props.refetch()
  }*/
  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('')

    const errorMessage = graphQLErrors
      ? graphQLErrors.some(err => err.message.includes('duplicate key error'))
        ? `Store Code Conflict: '${formData.code}' is already in use. Please enter a unique code.`
        : graphQLErrors[0].message
      : networkError
      ? networkError.result.errors[0].message
      : 'Something went wrong!'

    errorSetter(errorMessage)
    setopenSnackk(true)
    setTimeout(hideAlert, 1000)
  }
  const [updateBoundsAndLocation] = useMutation(EDIT_LOCAL_STORE_BOUNDS, {
    onError,
    onCompleted: onUpdateCompleted
  })

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    // refetchQueries: [{ query: GET_CENTRAL_STORE }],
    onError,
    onCompleted
  })

  const clearFields = () => {
    formRef.current.reset()
    setFormErrors({})
    setPath([])
    setFormData({
      id: props.vendor ? props.vendor._id : '',
      name: props.vendor ? props.vendor.name : '',
      address: props.vendor ? props.vendor.address : '',
      city: props.vendor ? props.vendor.city : '',
      code: props.vendor ? props.vendor.code : '',
      bounds: props.vendor ? props.vendor.bounds : '',
      isActive: props.vendor ? props.vendor.isActive : false,
      location: {
        latitude:
          props.vendor && props.vendor.location.coordinates[0]
            ? props.vendor.location.coordinates[0]
            : '',
        longitude:
          props.vendor && props.vendor.location.coordinates[1]
            ? props.vendor.location.coordinates[1]
            : ''
      }
    })
  }

  const hideAlert = () => {
    errorSetter('')
    successSetter('')
    setopenSnackk(false)
  }

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const inputTextStyle = { color: 'black' }
  var [path, setPath] = useState([])

  useEffect(() => {
    if (props.vendor && props.vendor.deliveryBounds) {
      const newCenter = {
        lat: props.vendor.deliveryBounds.coordinates[0][0][1],
        lng: props.vendor.deliveryBounds.coordinates[0][0][0]
      }
      setCenterState(newCenter)
    }
  }, [props.vendor])

  const listenersRef = useRef([])

  // function setCenter(coordinates) {
  //   return { lat: coordinates[0][1], lng: coordinates[0][0] };
  // }

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove())
    listenersRef.current = []
    polygonRef.current = null
  }, [])

  // const [center, setCenterState] = useState(
  //   props.vendor
  //     ? setCenter(props.vendor.deliveryBounds.coordinates[0])
  //     : { lat: 33.684422, lng: 73.047882 }
  // );

  useEffect(() => {
    if (props.vendor && props.vendor.deliveryBounds) {
      const transformedPath = transformPolygon(
        props.vendor.deliveryBounds.coordinates[0]
      )
      setPath(transformedPath)
    }
  }, [props.vendor])

  const polygonRef = useRef()

  const onClick = e => {
    const newPath = [...path, { lat: e.latLng.lat(), lng: e.latLng.lng() }]
    setPath(newPath)
    const value = newPath.map(({ lng, lat }) => [lng, lat])
    setFormData({ ...formData, bounds: value })
  }

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() }
        })
      setPath(nextPath)
    }
  }, [setPath])

  const onLoadPolygon = useCallback(
    polygon => {
      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      )
    },
    [onEdit]
  )
  const onSubmit = e => {
    e.preventDefault()
    // if (!validateFormData()) {
    //   // setOpenSnack(true);
    //   return;
    // }

    if (true) {
      !props.vendor
        ? mutate({
            variables: {
              localStoreInput: {
                name: formData.name,
                address: formData.address,

                city: formData.city,
                code: formData.code,
                //  centralStore: userType.storeId,

                isActive: true
              }
            }
          })
        : mutate({
            variables: {
              storeId: formData.id,
              localStoreUpdateInput: {
                name: formData.name,
                address: formData.address,
                // bounds: formData.bounds,
                city: formData.city,
                code: formData.code,
                centralStore: userType.storeId,
                isActive: true,
                location: {
                  latitude: formData.location.latitude,
                  longitude: formData.location.longitude
                }
              }
            }
          })
      // Close the modal after 3 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose() // Close the modal
        }
      }, 2000)
    }
  }
  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography
            className={props.vendor ? classes.textWhite : classes.textWhite}>
            {props.vendor ? t('Edit Local Store') : t('Add Local Store')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            {!props.editModal ? (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label="Store Name"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={Boolean(formErrors.name)}
                    helperText={formErrors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="address"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={Boolean(formErrors.address)}
                    helperText={formErrors.address}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="city"
                    label="City"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={Boolean(formErrors.city)}
                    helperText={formErrors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="code"
                    label="Store Code"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={Boolean(formErrors.Code)}
                    helperText={formErrors.Code}
                  />
                </Grid>
              </>
            ) : (
              // Conditionally render the second form fields based on editModal prop
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label="Store Name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={Boolean(formErrors.name)}
                    helperText={formErrors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="address"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={formData.address}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={Boolean(formErrors.address)}
                    helperText={formErrors.address}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="city"
                    label="City"
                    error={Boolean(formErrors.city)}
                    helperText={formErrors.city}
                    variant="outlined"
                    fullWidth
                    value={formData.city}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="code"
                    label="Store Code"
                    error={Boolean(formErrors.code)}
                    helperText={formErrors.code}
                    variant="outlined"
                    fullWidth
                    value={formData.code}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="longitude"
                    label="Longitude"
                    error={Boolean(formErrors.longitude)}
                    helperText={formErrors.longitude}
                    variant="outlined"
                    fullWidth
                    value={formData.location ? formData.location.longitude : ''}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    name="latitude"
                    label="Latitude"
                    error={Boolean(formErrors.latitude)}
                    helperText={formErrors.latitude}
                    variant="outlined"
                    fullWidth
                    value={formData.location ? formData.location.latitude : ''}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={6}>
<TextField
  name="bound1"
  label="Bound 1"
  variant="outlined"
  fullWidth
  value={formData.bounds ? formData.bounds[0] : ""}
  onChange={handleInputChange}
  inputProps={{ style: inputTextStyle }}
/>
            </Grid>


            <Grid item xs={12} sm={6}>
            <TextField
  name="bound2"
  label="Bound 2"
  variant="outlined"
  fullWidth
  value={formData.bounds ? formData.bounds[1] : ""}
  onChange={handleInputChange}
  inputProps={{ style: inputTextStyle }}
/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="bound3"
                label="Bound 3"
                variant="outlined"
                fullWidth
                value={formData.bounds?formData.bounds[2]: "" }
                onChange={handleInputChange}
                inputProps={{ style: inputTextStyle }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="bound4"
                label="Bound 4"
                variant="outlined"
                fullWidth
                value={formData.bounds?formData.bounds[3]: "" }
                onChange={handleInputChange}
                inputProps={{ style: inputTextStyle }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="bound5"
                label="Bound 5"
                variant="outlined"
                fullWidth
                value={formData.bounds?formData.bounds[4]: "" }
                onChange={handleInputChange}
                inputProps={{ style: inputTextStyle }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="bound"
                label="Bound 6"
                variant="outlined"
                fullWidth
                value={formData.bounds?formData.bounds[0]: "" }
                onChange={handleInputChange}
                inputProps={{ style: inputTextStyle }}
                disabled
              />
            </Grid> */}

                <GoogleMap
                  mapContainerStyle={{
                    height: '500px',
                    width: '100%'
                  }}
                  id="example-map"
                  zoom={14}
                  center={center}
                  onClick={onClick}>
                  <Polygon
                    editable
                    draggable
                    path={path}
                    onMouseUp={onEdit}
                    onDragEnd={onEdit}
                    onLoad={onLoadPolygon}
                    onUnmount={onUnmount}
                  />
                </GoogleMap>

                {path &&
                  path.map((coordinate, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={6}>
                        <TextField
                          label="Latitude"
                          aria-readonly
                          // type="number"
                          value={coordinate ? coordinate.lat : 'aswin'}
                          // onChange={(e) => handleCoordinateChange(index, 'lat', e.target.value)}
                          fullWidth
                          inputProps={{ style: inputTextStyle }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          aria-readonly
                          label="Longitude"
                          // type="number"
                          value={coordinate ? coordinate.lng : ''}
                          // onChange={(e) => handleCoordinateChange(index, 'lng', e.target.value)}
                          fullWidth
                          inputProps={{ style: inputTextStyle }}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
              </>
            )}
          </Grid>
          <DialogActions>
            <Button
              className={globalClasses.dashbordBtn}
              disabled={mutateLoading}
              type="submit">
              {props.vendor ? t('Update') : t('Add')}
            </Button>
            <Button
              className={globalClasses.modalCancleBtn}
              onClick={() => {
                onClose()
              }}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>

      <Box mt={2}>
        {success && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  )
}
export default withTranslation()(CreateLocalStore)
