/* eslint-disable react/display-name */
import React, { useState ,useEffect } from 'react'
import { useQuery, gql, useApolloClient } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import CustomLoader from '../components/Loader/CustomLoader'
// core components
import Header from '../components/Headers/Header'
import {   worlerWorkinpending } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { withRouter } from 'react-router-dom';
import { Container, Button, useTheme,} from '@mui/material'
import { customStyles } from '../utils/ppbbtable'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'

const GET_IN_PROGRESS_ORDER = gql`
  ${worlerWorkinpending}
`


const PackageNewOrderList = props => {
  const { t } = props;
  const globalClasses = useGlobalStyles()
  const client = useApolloClient();
  const [page, setPage] = useState(
    1
  );
  const [currentPage, setCurrentPage] = useState(page);
 const [totalRows ,setTotalRows] = useState(0)
 const [perPage ,setPerPage] = useState(10)
  const variables = {
    limit: perPage,
      skip: (page - 1) * perPage,
  };
  const { data, loading: loadingQuery, refetch } = useQuery(GET_IN_PROGRESS_ORDER, {
    // fetchPolicy: 'network-only',
    variables,
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });


useEffect(() => {
  if (data) {;
    setTotalRows(data.getWorkerInProgressOrders.totalCount);
  }
}, [data]);

// Update the query variables when the page value changes
useEffect(() => {
  refetch({
    variables: {
      limit: perPage,
      skip: (page - 1) * perPage,
    },
  });
}, [page, perPage, refetch]);


  const handlePerRowChange=(newPerPage) =>{
    setPerPage(newPerPage )
  }
  // Inside your handlePageChange function, update the local storage
const handlePageChange = (page) => {
  setPage(page);
  setCurrentPage(page)

};
  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }
 

  const getOrderStatusWord = (statusNumber) => {
    const statusMap = {
      0: "INITIATED",
      1: "CONFIRMED",
      2: "PACKING",
      3: "BILLING",
      4: "READY_FOR_DELIVERY",
      5: "RIDER_ASSIGNED",
      6: "OUT_FOR_DELIVERY",
      7: "DELIVERED",
      8: "CANCELLED",
      9: "FAILED_OUT_OF_STOCK",
      10: "FAILED_INVALID_DELIVERY_ADDRESS",
      11: "FAILED",
    };
  
    return statusMap[statusNumber] || "N/A";
  };

  const columns = [

  
    {
      name: "Order ID",
      selector: '_id', // Updated selector
       
      style: { cursor: 'pointer',minWidth:"300px" },
    },

    {
      name: "Store Name",
      selector: 'localStore', // Assuming you want to display localStore
      cell: (row) => <>{row.localStore.join(', ')}</>, // Assuming localStore is an array
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Order Date",
      selector: 'orderDate', // Updated selector
      cell: (row) => <>{new Date(row.orderDate).toLocaleDateString()}</>, // Show only the date part
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Delivery Date",
      selector: 'deliveryDate', // Updated selector
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Completion Time",
      selector: 'completionTime', // Updated selector
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Items Count",
      selector: 'itemsCount', // Updated selector
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Order Status",
      selector: 'orderStatus', // Updated selector
      cell: (row) => <>{getOrderStatusWord(row.orderStatus)}</>, // Use getOrderStatusWord method
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Order Amount",
      selector: 'orderAmount', // Updated selector
      style: {
        cursor: 'pointer',
      },
    },
   
    {
      name: t('Action'),
      cell: row => <>{actionButtons(row)}</>,
    },
  ];
  const theme = useTheme();


  const actionButtons = row => {
 
    return (
      <>
      <Button
  size="20px"
  variant="contained"
  sx={{
    color: theme.palette.common.white,
    fontWeight: 'bold',
    backgroundColor: theme.palette.warning.dark,
    padding: 0,
    height: '15px',
    fontSize: '7px',
    '&:hover': {
      border: '2px solid #637BFE',
      color: theme.palette.warning.dark,
      backgroundColor: "white"
    }
  }}
  onClick={e => {
    e.preventDefault();
    const userType = JSON.parse(localStorage.getItem('user-enatega')).workerType;

    // Conditionally determine the path based on userType
    let path = '';
    if (userType === 0) {
      path = '/package_admin/OrderDetails';
    } else if (userType === 1) {
      path = '/billing_admin/OrderDetails';
    } else {
      path = '/default/path';
    }
    localStorage.setItem('OrderData',JSON.stringify(row) );
    localStorage.setItem('orderId', row._id);
    props.history.push({
      pathname: path,
      state: { id: row._id }
    });
  }}
>
  {t('ViewDetails')}
</Button>

      </>
    )
  }
  const filtered = data &&data.getWorkerInProgressOrders.orders
  return (
    <>
      <Header />

      <Container className={globalClasses.flex} fluid>
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            customStyles={customStyles}
            subHeader={true}
          
              title={<TableHeader title={t('All In Progress Orders')} />}
            columns={columns}
            data={filtered}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            sortFunction={customSort}
            // customStyles={customStyles}
          />
        )}
       
      </Container>
    </>
  )
}

export default withTranslation()(withRouter(PackageNewOrderList));

