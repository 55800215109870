import React, { useEffect, useRef, useState } from 'react'
import { useMutation, gql, useQuery } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import {
  getalllocalstore,
  createlsadmin,
  updatelocaladmin,
  UpdateAdminPassword,
  AddCreditToNestCash
} from '../../apollo'
import {
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar
} from '@mui/material'
import useStyles from './styles'
import useGlobalStyles from '../../utils/globalStyles'

const GET_LOCAL_STORE = gql`
  ${getalllocalstore}
`
const ADD_NESTCASH = gql`
  ${AddCreditToNestCash}
`
const EDIT__LOCAL_STORE_ADMIN = gql`
  ${updatelocaladmin}
`

const EDIT__LOCAL_STORE_ADMIN_PASSWORD = gql`
  ${UpdateAdminPassword}
`

const CustomPaperComponent = props => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
)

function AddNestCash(props) {
  const { onClose } = props
  const formRef = useRef()
  const mutation = ADD_NESTCASH

  let [error, errorSetter] = useState('')
  const [success, successSetter] = useState('')
  const { t } = props
  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    phone: props.vendor ? props.vendor.phone : '',
    amount: props.vendor ? props.vendor.amount : '',
    expiry: props.vendor ? props.vendor.expiry : '',
    applyPercent: props.vendor ? props.vendor.applyPercent : '',
    reason: props.vendor ? props.vendor.reason : '',
 
  })
  const formatToYYYYMMDD = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];  // Convert to "YYYY-MM-DD" format
  };
  const [StartDate, setStartDate] = useState(formatToYYYYMMDD(formData.expiry));
  console.log(formData )
  const [passwordModified, setPasswordModified] = useState(false)
  const onError = ({ graphQLErrors, networkError }) => {
    console.log(graphQLErrors, networkError);
    successSetter(''); // Clear any success message if an error occurs
  
    if (graphQLErrors && graphQLErrors.length > 0) {
      // Extract the first error message
      const errorMessage = graphQLErrors[0]? graphQLErrors[0].message : 'An unknown error occurred';
      errorSetter(errorMessage); // Set the error message to be displayed in Snackbar
    } else if (networkError) {
      // Handle network errors
      const networkErrorMessage = networkError.result? networkError.result.errors[0]?networkError.result.errors[0].message : 'Network error occurred' :'Something Went Wrong' ;
      errorSetter(networkErrorMessage);
    } else {
      // Generic fallback error
      errorSetter('Something went wrong!');
    }
  
    setopenSnackk(true); // Show the Snackbar with the error message
    setTimeout(hideAlert, 3000); // Hide the Snackbar after 3 seconds
    props.refetch(); // Refetch data (if applicable)
  };
  
  const onCompleted = data => {
    if (!props.vendor) clearFields()
    const message = props.vendor
      ? t('Local Admin  Updated Successfully')
      : t('Nest Cash Added Successfully')
    errorSetter('')
    props.refetch()
    successSetter(message)
    setTimeout(hideAlert, 3000)
    props.refetch()
    setopenSnackk(true)
  }
  const [updatePassword] = useMutation(EDIT__LOCAL_STORE_ADMIN_PASSWORD, {
    onError,
    onCompleted
  })

  const [openSnackk, setopenSnackk] = useState(false)
  // Define a state to hold your query data

  const [selectedCategory, setSelectedCategory] = useState(
    props.vendor ? props.vendor.storeName : null
  )

  const { data, refetch: refetchCentralStore } = useQuery(GET_LOCAL_STORE)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetchCentralStore()
      } catch (error) {}
    }

    fetchData()
  }, [])
  // Check if centralStoreData is undefined or null before accessing its properties

  useEffect(() => {
    // Other code
  }, [])
  useEffect(() => {
    refetchCentralStore()
  }, [refetchCentralStore])

  useEffect(() => {
    if (data && props.vendor && props.vendor.storeId) {
      const selectedStore = data.getAllLocalStores.find(
        store => store._id === props.vendor.storeId
      )

      setSelectedCategory(selectedStore || null)
    }
  }, [data, props.vendor])

  const options = data ? data.getAllLocalStores || [] : []

  const handleCategoryChange = (event, value) => {
    if (value) {
      setSelectedCategory(value) // Assuming value is an object from options array
      setFormData({ ...formData, storeId: value._id })
    } else {
      setSelectedCategory(null)
      setFormData({ ...formData, storeId: '' }) // Clear storeId if no option is selected
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    // Update form data state
    setFormData({ ...formData, [name]: value })
    if (name === 'password' && props.vendor) {
      setPasswordModified(true)
    }
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' })
    }
  }
  const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    const isoString = date.toISOString(); // This will give you the date in "2024-09-04T00:00:00.000Z" format
    return isoString.replace('Z', '+00:00'); // Replacing 'Z' with '+00:00' to match your required format
  };
  const validateFormData = () => {
    let errors = {}

    if (!formData.phone) errors.phone = 'Phone is required'
    if (!formData.amount)
      errors.amount = 'Amount is required'
    if (!formData.expiry) errors.expiry = 'Expiry is required'
  
    if (!formData.applyPercent) errors.applyPercent = 'Apply Percent is required'
    if (!formData.reason) errors.reason = 'Reason is required'

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_LOCAL_STORE }],
    onError,
    onCompleted
  })
  const clearFields = () => {
    formRef.current.reset()

    setFormData({
      phone: props.vendor ? props.vendor.phone : '',
      amount: props.vendor ? props.vendor.amount : '',
      expiry: props.vendor ? props.vendor.expiry : '',
      applyPercent: props.vendor ? props.vendor.applyPercent : '',
      reason: props.vendor ? props.vendor.reason : '',
    })
  }

  const hideAlert = () => {
    errorSetter('')
    successSetter('')
    setopenSnackk(false)
  }

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const inputTextStyle = { color: 'black' }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!validateFormData()) {
      // setOpenSnack(true);
      return
    }
    if (true) {
      mutate({
            variables: { 
              
            "phone": formData.phone,
              "amount": parseFloat(formData.amount), 
               "expiry": formData.expiry,
              "applyPercent": parseFloat(formData.applyPercent),
              "reason": formData.reason
            }
          })
     
      // Close the modal after 3 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose() // Close the modal
        }
      }, 4000)
    }
  }
  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      maxWidth="sm"
      fullWidth>
      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography
              className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor
                ? t('Edit Nest Cash')
                : t('Add Nest Cash  ')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
           
          
                <Grid item xs={12} sm={6}>
                  <TextField
                  disabled
                  name="phone"
                   type='phone'
                  onInput={e => {
                    e.target.value = e.target.value.slice(0, 10)
                  }}
                  helperText={formErrors.phone}
                  error={Boolean(formErrors.phone)}
                  label="Phone"
                  variant="outlined"
                  value={formData.phone}
                  fullWidth
                  onChange={handleInputChange}
                  inputProps={{ style: inputTextStyle, maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="amount"
                    value={formData.amount}
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.amount}
                    helperText={formErrors.amount}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                  label="Select Expiry Date"
                  type="date"
                  value={StartDate}

                  onChange={(value) => {
                    const formattedEndDate = formatDateToISO(value.target.value);
                    setStartDate(value.target.value);
                    setFormData({ ...formData, expiry: formattedEndDate });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: { xs: '100%', sm: '50%', md: '100%', lg: '100%' },
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                />
              </Grid>
             
                <Grid item xs={12} sm={6}>
                  <TextField
                  
                    name="applyPercent"
                    label="Apply Percent"
                    value={formData.applyPercent}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.applyPercent}
                    helperText={formErrors.applyPercent}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                <TextField
                  
                  name="reason"
                  label="Reason"
                  value={formData.reason}
                  variant="outlined"
                  fullWidth
                  onChange={handleInputChange}
                  inputProps={{ style: inputTextStyle }}
                  error={!!formErrors.reason}
                  helperText={formErrors.reason}
                />
                </Grid>
                
          
         
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}>
            { t('Add')}
          </Button>
          {/* <Button
          className={globalClasses.dashbordBtn}
          disabled={mutateLoading}
          onClick={async (e) => {
            e.preventDefault();
            // if (onSubmitValidaiton() && !mutateLoading) {
              if(true){
     !props.vendor ?       
       mutate({
                variables:{
                  lsAdminInput: {
                    contactAddress: formData.contactAddress,
                    email: formData.email,
                    name: formData.name,
                    password: formData.password,
                    phone: formData.phone,
                    storeId: formData.storeId
                  }
                }
                
              }): 
              mutate({
                variables:{
                  adminId : formData.id, 
                  lsAdminUpdateInput : {
                    contactAddress: formData.contactAddress,
                    email: formData.email,
                    name: formData.name,
                    // password: formData.password,
                    phone: formData.phone,
                    storeId: formData.storeId
                  }
                }
                
              }) 
              ;
              if (passwordModified && formData.password) {
                updatePassword({
                  variables: {
                    password: formData.password,
                    adminId: formData.id,
                  },
                });
              }
              // Close the modal after 3 seconds by calling the parent's onClose callback
              setTimeout(() => {
                if (typeof props.onClose === 'function') {
                  props.onClose(); // Close the modal
                }
              }, 4000);
            }
          }}
        >
          {props.vendor ? t('Update') : t('Save')}
        </Button> */}
          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose()
            }}>
            Cancel
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  )
}
export default withTranslation()(AddNestCash)

