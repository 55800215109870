// export const GetSuperAdminDashboard = `query GetSuperAdminDashboard {
//   getSuperAdminDashboard {
//     centralStoreCount
//     categoryCount
//     productCount
//   }
// }`
export const getRefreshResponse =`
query Query($refreshId: String!) {
  getRefreshResponse(refreshId: $refreshId)
}`

export const GetRefreshedData =`
query GetRefreshedData($skip: Int!) {
  getRefreshedData(skip: $skip) {
    _id
    storeId
    encodedResponse
    createdAt
  }
}`
export const GetCustomerNestCash =` 
query GetCustomerNestCash($customerId: String!, $skip: Int!, $limit: Int!) {
  getCustomerNestCash(customerId: $customerId, skip: $skip, limit: $limit) {
    customerId
    phone
    balance
    transactions {
      _id
      type
      accountingType
      orderId
      amount
      reason
      description
      nestBalance
      createdAt
      expiry
      expired
    }
    createdAt
    expiry
    isActive
    applyPercent
    activeTxnId
    totalTxnCount
    message
  }
}`
export const GetCustomerWalletByOrder =`
query GetCustomerWalletByOrder($orderId: String!, $skip: Int!, $limit: Int!) {
  getCustomerWalletByOrder(orderId: $orderId, skip: $skip, limit: $limit) {
    transactions {
      type
      orderId
      amount
      reason
      description
      balance
      createdAt
    }
    createdAt
    totalTxnCount
    message
    customerId
    balance
  }
}`
export const GetCustomerWallet =`
query GetCustomerWallet($customerId: String!, $skip: Int!, $limit: Int!) {
  getCustomerWallet(customerId: $customerId, skip: $skip, limit: $limit) {
    customerId
    balance
    transactions {
      type
      orderId
      amount
      reason
      description
      balance
      createdAt
    }
    createdAt
    totalTxnCount
    message
  }
}`
export const SearchCatalogs =`
query SearchCatalogs($text: String!, $limit: Int!, $skip: Int!) {
  searchCatalogs(text: $text, limit: $limit, skip: $skip) {
    catalogs {
      _id
      name
      description
      isActive
      parcelable
    }
    totalCount
  }
}`

export const SearchCategories =`
query SearchCategories($text: String!, $limit: Int!, $skip: Int!) {
  searchCategories(text: $text, limit: $limit, skip: $skip) {
    categories {
      _id
      name
      isActive
      images
      subcategories {
        _id
        name
        description
        isActive
        images
        categoryId
      }
      description
    }
    totalCount
  }
}`

export const SearchProducts =`
query SearchProducts($text: String!, $limit: Int!, $skip: Int!) {
  searchProducts(text: $text, limit: $limit, skip: $skip) {
    products {
      _id
      name
      description
      aliases
      images
      defaultImage
      isActive
      category {
        _id
        name
        isActive
        images
        subcategories {
          _id
          name
          description
          isActive
          images
          categoryId
        }
        description
      }
      catalog {
        _id
        name
        description
        isActive
        parcelable
      }
      sortOrder
      hsn
      isNewProduct
      displayName
      skus {
        _id
        storeId
        productId
        discount
        barcode
        isActive
        mrp
        prices {
          customerType
          sellingPrice
        }
        unit
        measure
        minimumQty
        stockId
        stockUnits
        Stock {
          _id
          storeId
          quantity
          name
          store
        }
        localStocks {
          storeId
          skuId
          quantity
          replenishQty
          defaultLSQty
        }
      }
      offerImage
      offerEnabled
    }
    totalCount
  }
}`
export const GetAllCoupons =`
query GetAllCoupons {
  getAllCoupons {
    name
    description
    storeId
    type
    code
    start
    end
    applyAmount
    orderAmount
  }
}`
export const FilterProductsByCategories = `
query FilterProductsByCategories($skip: Int!, $categoryId: String, $subCategoryId: String, $limit: Int) {
  filterProductsByCategories(skip: $skip, categoryId: $categoryId, subCategoryId: $subCategoryId, limit: $limit) {
    products {
      _id
      name
      description
      aliases
      images
      defaultImage
      isActive
      category {
        _id
        name
        isActive
        images
        subcategories {
          _id
          name
          description
          isActive
          images
          categoryId
        }
        description
      }
      catalog {
        _id
        name
        description
        isActive
        parcelable
      }
      sortOrder
      hsn
      isNewProduct
      displayName
      skus {
        _id
        storeId
        productId
        discount
        barcode
        isActive
        mrp
        prices {
          customerType
          sellingPrice
        }
        unit
        measure
        minimumQty
        stockId
        stockUnits
        Stock {
          _id
          storeId
          quantity
          name
          store
        }
        localStocks {
          storeId
          skuId
          quantity
          replenishQty
          defaultLSQty
        }
      }
      offerImage
      offerEnabled
    }
    totalCount
  }
}`
export const GetCustomerDetailsById = `
query GetCustomerDetailsById($customerId: String!) {
  getCustomerDetailsById(customerId: $customerId) {
    _id
    name
    phone
    email
    gender
    nativePlace
    customerType
    isActive
    jwtToken
    refreshToken
    addresses {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    status
    permittedPaymentOptions {
      method
      label
    }
    selectedPaymentMethod
    cart {
      items {
        productId
        skuId
        name
        description
        image
        quantity
        mrp
        prices {
          customerType
          sellingPrice
        }
        status
      }
      deliveryAddress {
        _id
        deliveryAddress
        details
        label
        selected
        isActive
      }
      billDetails {
        itemsTotal
        deliveryCharge
        couponDiscount
        grandTotal
        totalSaving
        handlingCharge
        itemSavings
        itemsMrp
        chargesWaived
      }
    }
    GST
  }
}
`
export const GetAllCustomerTypes = `
query GetAllCustomerTypes {
  getAllCustomerTypes {
    name
    _id
    customerType
    description
    charges {
      handling {
        waiverAmount
        rate
      }
      delivery {
        waiverAmount
        rate
      }
    }
    default
  }
}`
export const GetRiderAllotmentReport = `
query GetRiderAllotmentReport($lsId: String!, $skip: Int!, $limit: Int!) {
  getRiderAllotmentReport(lsId: $lsId, skip: $skip, limit: $limit) {
    riderAllotments {
      lsId
      csId
      riders
      orders
      expansionRatio
      thresholdFactor
      assigned {
        riderEmail
        totalOrders
        orders {
          _id
          customerId
          deliveryDate
        }
      }
      unassigned {
        _id
        customerId
        deliveryAddress {
          _id
          location {
            coordinates
          }
          deliveryAddress
          details
          label
          selected
          isActive
        }
        deliveryDate
      }
      message
      createdAt
    }
    count
  }
}`

export const GetStore = `
query GetStore($storeId: String!) {
  getStore(storeId: $storeId) {
    _id
    name
    type
    code
    address
    city
    location {
      coordinates
    }
    admins {
      _id
      name
      email
      phone
      userType
      isActive
      jwtToken
      createdBy
      storeId
      storeName
      contact_address
    }
    deliveryBounds {
      coordinates
    }
    landingPage {
      _id
      landingPageDisplays {
        deviceType
        _id
        displays {
          _id
          displayType
          enabled
          rows
          columns
          displayPosition
        }
      }
    }
    premiumCustomerConfig {
      purchaseAmount
      expiryInMonths
      daysOfPurchase
      activated
      message
    }
    removeUpgradedCustomerSlot {
      start
      end
      _id
    }
  }
}`



export const GetCentralStore = `
query GetCentralStore($storeId: String!) {
  getCentralStore(storeId: $storeId) {
    _id
    name
    type
    code
    address
    city
    location {
      coordinates
    }
    admins {
      _id
      name
      email
      phone
      userType
      isActive
      jwtToken
      createdBy
      storeId
      storeName
      contact_address
    }
    deliveryBounds {
      coordinates
    }
    landingPage {
      _id
      landingPageDisplays {
        deviceType
        _id
        displays {
          _id
          displayType
          enabled
          rows
          columns
          displayPosition
        }
      }
    }
  }
}`

export const getAllBanner = `
query GetBanner {
  getBanner {
    _id
    name
    contents {
      image
      action
    }
    isActive
    updatedAt
    storeId
  }
}
`
export const getorderFilter = `
query GetAllOrdersByFilter($filterInput: FilterInput, $limit: Int!, $skip: Int!) {
  getAllOrdersByFilter(filterInput: $filterInput, limit: $limit, skip: $skip) {
    totalCount
    orders {
      _id
      customerId
      orderDate
      deliveryDate
      completionTime
      itemsCount
      orderStatus
      orderAmount
      localStore
      centralStore
      deliveryAddress {
        _id
        location {
          coordinates
        }
        deliveryAddress
        details
        label
        selected
        isActive
      }
      orderState {
        status
        workerStatus
        ppWorkerAssigned
        ppWorkerAssignedEmail
        billingWorkerAssigned
        billingWorkerAssignedEmail
        riderAssigned
        riderAssignedEmail
      }
      invoiceId
      deliveredImage
      paymentMethod
      paymentStatus
    }
  }
}`
export const GetWorkersByOrderStatus = `
query GetWorkersByOrderStatus($orderId: String!) {
  getWorkersByOrderStatus(orderId: $orderId) {
    _id
    name
    email
    phone
    workerType
    available
    isActive
    jwtToken
    storeId
    storeName
  }
}`
export const GetRiderConfigForLS = `
query GetRiderConfigForLS {
  getRiderConfigForLS {
    expansionRatio
    thresholdFactor
    timeSlots {
      start
      _id
    }
  }
}`
export const GetOrdersByWorkerEmail = `
query GetOrdersByWorkerEmail($email: String!, $skip: Int!, $limit: Int!) {
  getOrdersByWorkerEmail(email: $email, skip: $skip, limit: $limit) {
    orderWorkerStatus {
      _id
      customerId
      orderDate
      deliveryDate
      completionTime
      itemsCount
      orderStatus
      orderAmount
      localStore
      centralStore
      orderState {
        status
        workerStatus
        ppWorkerAssigned
        ppWorkerAssignedEmail
        billingWorkerAssigned
        billingWorkerAssignedEmail
        riderAssigned
        riderAssignedEmail
      }
    }
    totalCount
  }
}`
export const GetWorkerDetailsById = `
query GetWorkerDetailsById($workerId: String) {
  getWorkerDetailsById(workerId: $workerId) {
    _id
    available
    email
    name
    phone
    storeName
  }
}`
export const GetReplenishableProductsForWorker = `
query GetReplenishableProductsForWorker($skip: Int!, $limit: Int!) {
  getReplenishableProductsForWorker(skip: $skip, limit: $limit) {
    products {
      _id
      name
      skuId
      stockId
      localQty
      sortOrder
      minimumQty
      defaultLSQty
      replenishQty
    }
    totalCount
  }
}`
export const GetReplenishRequest = `
query GetReplenishRequest($replenishRequestId: String!) {
  getReplenishRequest(replenishRequestId: $replenishRequestId) {
    _id
    localStore
    localStoreName
    centralStore
    centralStoreName
    requestedItems {
      productId
      skuId
      stockId
      requestedQty
      approvedQty
      receivedQty
      Stock {
        _id
        storeId
        quantity
        name
      }
      requestedItemStates {
        requestedItemStatus
        requestedItemStatusString
        reason
        reasonString
      }
    }
    requestedByEmail
    requestedBy
    approvedByEmail
    approvedBy
    receivedByEmail
    receivedBy
    requestedDate
    approvedDate
    sendDate
    receivedDate
    requestStates {
      type
      requestStatus
      requestStatusString
    }
    status
    statusString
    itemCount {
      requestedCount
      approvedCount
      receivedCount
    }
    comments
  }
}`
export const GetAllReplenishRequests = `
query GetAllReplenishRequests($status: Int!, $limit: Int!, $skip: Int!) {
  getAllReplenishRequests(status: $status, limit: $limit, skip: $skip) {
    repleshishRequestSummary {
      _id
      approvedByEmail
      approvedDate
      centralStore
      centralStoreName
      itemCount {
        approvedCount
        receivedCount
        requestedCount
      }
      localStore
      localStoreName
      receivedByEmail
      receivedDate
      requestedByEmail
      requestedDate
      status
      statusString
    }
    totalCount
  }
}`
export const getskuforprodict = `
query GetSkusForProduct($productId: String, $storeId: String) {
  getSkusForProduct(productId: $productId, storeId: $storeId) {
    Stock {
      _id
      name
      quantity
      storeId
    }
    _id
    barcode
    discount
    isActive
    localStocks {
      defaultLSQty
      quantity
      replenishQty
      skuId
      storeId
    }
    minimumQty
    measure
    mrp
    prices {
      customerType
      sellingPrice
    }
    productId
    stockId
    stockUnits
    storeId
    unit
  }
}`
export const searchproductls = `
query SearchProductsLS($text: String!, $skip: Int!, $limit: Int!) {
  searchProductsLS(text: $text, skip: $skip, limit: $limit) {
    products {
      _id
      name
      description
      aliases
      images
      defaultImage
      isActive
      category {
        _id
        name
        isActive
        images
        subcategories {
          _id
          name
          description
          isActive
          images
          categoryId
        }
        description
      }
      catalog {
        _id
        name
        description
        isActive
        parcelable
      }
      sortOrder
      hsn
      isNewProduct
      displayName
      skus {
        _id
        storeId
        productId
        discount
        barcode
        isActive
        mrp
        prices {
          customerType
          sellingPrice
        }
        unit
        measure
        minimumQty
        stockId
        stockUnits
        Stock {
          _id
          storeId
          quantity
          name
        }
        localStocks {
          storeId
          skuId
          quantity
          replenishQty
          defaultLSQty
        }
      }
    }
    totalCount
  }
}
`
export const searchproductforcs = `
query SearchProductsCS($text: String!, $skip: Int!, $limit: Int!) {
  searchProductsCS(text: $text, skip: $skip, limit: $limit) {
    products {
      name
      _id
      aliases
      catalog {
        _id
        isActive
        description
        name
        parcelable
      }
      category {
        _id
        description
        images
        isActive
        name
        subcategories {
          _id
          categoryId
          description
          images
          isActive
          name
        }
      }
      defaultImage
      description
      displayName
      hsn
      images
      isActive
      isNewProduct
      skus {
        Stock {
          _id
          name
          quantity
          storeId
        }
        _id
        barcode
        discount
        isActive
        localStocks {
          quantity
          replenishQty
          skuId
          storeId
        }
        measure
        minimumQty
        mrp
        productId
        stockId
        stockUnits
        storeId
        unit
      }
      sortOrder
    }
    totalCount
  }
}`
export const getskuforproduct = `
{
  query GetSkusForProduct($productId: String, $storeId: String) {
    getSkusForProduct(productId: $productId, storeId: $storeId) {
      Stock {
        _id
        name
        quantity
        storeId
      }
      _id
      barcode
      discount
      isActive
      localStocks {
        defaultLSQty
        quantity
        replenishQty
        skuId
        storeId
      }
      minimumQty
      measure
      mrp
      prices {
        customerType
        sellingPrice
      }
      productId
      stockId
      stockUnits
      storeId
      unit
    }
  }`
export const getsubcategory = `
query GetSubCategories($categoryId: String!) {
  getSubCategories(categoryId: $categoryId) {
    _id
    name
    description
    isActive
    images
    categoryId
  }
}`
export const getallworker = `
query GetAllWorkersForLocalStore {
  getAllWorkersForLocalStore {
    _id
    name
    email
    phone
    workerType
    isActive
    jwtToken
    storeId
    storeName
  }
}`
export const getWorkerInProgressOrders = `
query GetWorkerInProgressOrders($skip: Int!, $limit: Int!) {
  getWorkerInProgressOrders(skip: $skip, limit: $limit) {
    _id
    customerId
    orderDate
    deliveryDate
    completionTime
    itemsCount
    orderStatus
    orderAmount
    localStore
    centralStore
  }
}`
export const getworkerassignedorder = `query GetWorkerAssignedOrders($skip: Int!, $limit: Int!) {
  getWorkerAssignedOrders(skip: $skip, limit: $limit) {
    orders {
      _id
      centralStore
      completionTime
      customerId
      deliveryAddress {
        _id
        deliveryAddress
        details
        isActive
        label
        location {
          coordinates
        }
        selected
      }
      deliveryDate
      itemsCount
      localStore
      orderAmount
      orderDate
      orderStatus
    }
    totalCount
  }
}`

export const getworkerordercompleted = `
query GetWorkerCompletedOrders($skip: Int!, $limit: Int!) {
  getWorkerCompletedOrders(skip: $skip, limit: $limit) {
    orders {
      deliveryAddress {
        _id
        location {
          coordinates
        }
        deliveryAddress
        details
        label
        selected
        isActive
      }
      _id
      centralStore
      completionTime
      customerId
      deliveryDate
      itemsCount
      localStore
      orderAmount
      orderDate
      orderStatus
    }
    totalCount
  }
}`
export const getOrderDetailsforworker = `
query GetOrderDetailsById($orderId: String!) {
  getOrderDetailsById(orderId: $orderId) {
    _id
    items {
      productId
      skuId
      name
      description
      image
      quantity
      mrp
      prices {
        customerType
        sellingPrice
      }
      status
      packingChecked
      billingChecked
    }
    customerType
    customer {
      _id
      name
      phone
      email
      gender
      nativePlace
      customerType
      isActive
      jwtToken
      refreshToken
      addresses {
        _id
        deliveryAddress
        details
        label
        selected
        isActive
      }
      status
      permittedPaymentOptions {
        method
        label
      }
      selectedPaymentMethod
      cart {
        items {
          productId
          skuId
          name
          description
          image
          quantity
          mrp
          prices {
            customerType
            sellingPrice
          }
          status
          packingChecked
          billingChecked
        }
        deliveryAddress {
          _id
          location {
            coordinates
          }
          deliveryAddress
          details
          label
          selected
          isActive
        }
        billDetails {
          itemsTotal
          deliveryCharge
          grandTotal
          totalSaving
          handlingCharge
          itemSavings
          itemsMrp
          chargesWaived
          couponDiscount
          walletInfo {
            applied
            amount
            message
          }
          couponInfo {
            applied
            amount
            code
            message
            cancellationApplied
          }
          nestInfo {
            applied
            amount
            message
            couponRefundAmount
            couponRefundAccomodated
          }
          cancelledInfo {
            nestInfo {
              applied
              amount
              message
              couponCancellationAmount
              couponCancellationAccomodated
            }
          }
        }
      }
      GST
    }
    paymentStatus
    paymentMethod
    paidAmount
    orderAmount
    orderDate
    deliveryDate
    billDetails {
      itemsTotal
      deliveryCharge
      grandTotal
      totalSaving
      handlingCharge
      itemSavings
      itemsMrp
      chargesWaived
      couponDiscount
      walletInfo {
        applied
        amount
        message
      }
      couponInfo {
        applied
        amount
        code
        message
        cancellationApplied
      }
      nestInfo {
        applied
        amount
        message
        couponRefundAmount
        couponRefundAccomodated
      }
      cancelledInfo {
        nestInfo {
          applied
          amount
          message
          couponCancellationAmount
          couponCancellationAccomodated
        }
      }
    }
    deliveryAddress {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    isOrderModified
    refundStatus
    orderState {
      status
      workerStatus
      ppWorkerAssigned
      ppWorkerAssignedEmail
      billingWorkerAssigned
      billingWorkerAssignedEmail
      riderAssigned
      riderAssignedEmail
    }
    invoiceId
    deliveredImage
  }
}`
export const worlerWorkinpending = `
query GetWorkerInProgressOrders($skip: Int!, $limit: Int!) {
  getWorkerInProgressOrders(skip: $skip, limit: $limit) {
    orders {
      _id
      customerId
      orderDate
      deliveryDate
      completionTime
      itemsCount
      orderStatus
      orderAmount
      localStore
      centralStore
      deliveryAddress {
        _id
        deliveryAddress
        details
        isActive
        label
        location {
          coordinates
        }
        selected
      }
    }
    totalCount
  }
}`
export const workerdashboard = `
query GetWorkerDashBoard {
  getWorkerDashBoard {
    assignedOrdersCount
    completedOrdersCount
    inProgressOrdersCount
  }
}`

export const getallorder = `
query GetAllOrders($limit: Int!, $skip: Int!) {
  getAllOrders(limit: $limit, skip: $skip) {
    totalCount
    orders {
      _id
      customerId
      orderDate
      deliveryDate
      completionTime
      itemsCount
      orderStatus
      orderAmount
      localStore
      centralStore
      deliveryAddress {
        _id
        location {
          coordinates
        }
        deliveryAddress
        details
        label
        selected
        isActive
      }
      orderState {
        status
        workerStatus
        ppWorkerAssigned
        ppWorkerAssignedEmail
        billingWorkerAssigned
        billingWorkerAssignedEmail
        riderAssigned
        riderAssignedEmail
      }
    }
  }
}
`
export const GetSuperAdminDashBoard = `
query Query {
  getSuperAdminDashboard {
    centralStoreCount
    totalOrders
    pendingOrders
    completedOrders
    categoryCount
    productCount
  }
}
`;
export const getcatlog = `query GetCatalogs {
  getCatalogs {
    _id
    description
    isActive
    name
    parcelable
  }
}`
export const getsinglecatlog = `query GetCategory($categoryId: String!) {
  getCategory(categoryId: $categoryId) {
    _id
    images
    isActive
    name
    subcategories {
      _id
      name
      isActive
      images
      categoryId
    }
  }
}`
export const getallcentralStore = `
query GetAllCentralStores {
  getAllCentralStores {
    _id
    name
    type
    code
    address
    city
    location {
      coordinates
    }
    admins {
      _id
      name
      email
      phone
      userType
      isActive
      jwtToken
      createdBy
      storeId
      storeName
    }
    deliveryBounds {
      coordinates
    }
    landingPage {
      _id
      landingPageDisplays {
        deviceType
        _id
        displays {
          _id
          displayType
          enabled
          rows
          columns
          displayPosition
        }
      }
    }
  }
}`



export const getallcategories = `query GetCategories {
  getCategories {
    _id
    name
    isActive
    images
    subcategories {
      _id
      name
      isActive
      images
      categoryId
    }
    description
  }
}`

export const getsinglecategory = `query GetCategory($categoryId: String!) {
  getCategory(categoryId: $categoryId) {
    _id
    description
    images
    isActive
    name
    subcategories {
      _id
      categoryId
      description
      images
      isActive
      name
    }
  }
}`

export const getallcentralstoreadmin = `query GetCSAdminsForAllStores {
  getCSAdminsForAllStores {
    _id
    name
    email
    phone
    userType
    isActive
    jwtToken
    createdBy
    storeId
    storeName
  }
}`

export const getcsadmindashboard = `query GetCSAdminDashBoard {
  getCSAdminDashBoard {
    completedOrders
    localStoreCount
    pendingOrders
    productCount
    replenishCount
    storeCity
    storeName
    totalOrders
  }
}`

export const getproductsforcs = `
query GetProductsForCS($storeId: String!, $limit: Int!, $skip: Int!) {
  getProductsForCS(storeId: $storeId, limit: $limit, skip: $skip) {
    totalCount
    products {
      _id
      aliases
      catalog {
        _id
        description
        isActive
        name
        parcelable
      }
      category {
        _id
        description
        images
        isActive
        name
        subcategories {
          _id
          categoryId
          description
          images
          isActive
          name
        }
      }
      defaultImage
      description
      displayName
      hsn
      images
      isActive
      isNewProduct
      name
      skus {
        Stock {
          _id
          name
          quantity
          storeId
        }
        _id
        barcode
        discount
        isActive
        localStocks {
          defaultLSQty
          quantity
          replenishQty
          skuId
          storeId
        }
        measure
        minimumQty
        mrp
        prices {
          customerType
          sellingPrice
        }
        productId
        stockUnits
        stockId
        storeId
        unit
      }
    }
  }
}
`
export const getalllocalstore = `
query GetAllLocalStores {
  getAllLocalStores {
    _id
    name
    type
    code
    address
    city
    admins {
      _id
      name
      email
      phone
      userType
      isActive
      jwtToken
      createdBy
      storeId
      storeName
    }
    deliveryBounds {
      coordinates
    }
    location {
      coordinates
    }
  }
}`
export const getproductsforsa = `
query GetProducts($limit: Int!, $skip: Int!) {
  getProducts(limit: $limit, skip: $skip) {
    products {
      _id
      name
      description
      aliases
      images
      defaultImage
      isActive
      category {
        _id
        name
        isActive
        images
        subcategories {
          _id
          name
          description
          isActive
          images
          categoryId
        }
        description
      }
      catalog {
        _id
        name
        description
        isActive
        parcelable
      }
      sortOrder
      hsn
      isNewProduct
      displayName
      skus {
        _id
        storeId
        productId
        discount
        barcode
        isActive
        mrp
        prices {
          customerType
          sellingPrice
        }
        unit
        measure
        minimumQty
        stockId
        stockUnits
        Stock {
          _id
          storeId
          quantity
          name
          store
        }
        localStocks {
          storeId
          skuId
          quantity
          replenishQty
          defaultLSQty
        }
      }
      offerImage
      offerEnabled
    }
    totalCount
  }
}`
export const getllocaladmins = `query GetStoreAdmins($storeId: String!) {
  getStoreAdmins(storeId: $storeId) {
    _id
    address
    admins {
      _id
      createdBy
      email
      isActive
      name
      phone
      storeId
      storeName
      userType
    }
    city
    code
    deliveryBounds {
      coordinates
    }
    location {
      coordinates
    }
    name
    type
  }
}`
export const getproducts = `
query GetProducts($limit: Int!, $skip: Int!) {
  getProducts(limit: $limit, skip: $skip) {
    products {
      _id
      name
      description
      aliases
      images
      defaultImage
      isActive
      category {
        _id
        name
        isActive
        images
        subcategories {
          _id
          name
          description
          isActive
          images
          categoryId
        }
        description
      }
      catalog {
        _id
        name
        description
        isActive
        parcelable
      }
      sortOrder
      hsn
      isNewProduct
      displayName
      skus {
        _id
        storeId
        productId
        discount
        barcode
        isActive
        mrp
        prices {
          customerType
          sellingPrice
        }
        unit
        measure
        minimumQty
        stockId
        stockUnits
        Stock {
          _id
          storeId
          quantity
          name
          store
        }
        localStocks {
          storeId
          skuId
          quantity
          replenishQty
          defaultLSQty
        }
      }
      offerImage
      offerEnabled
    }
    totalCount
  }
}`

export const getlsadmin = `query Query {
  getLSAdminsForStore {
    _id
    email
    name
    phone
    storeId
    storeName
    isActive
    createdBy
    contact_address
  }
}`

export const gettallcustomers = `
query GetCustomersForCentralStore($limit: Int!, $skip: Int!) {
  getCustomersForCentralStore(limit: $limit, skip: $skip) {
    customers {
      _id
      name
      phoneNumber
      addresses {
        _id
        location {
          coordinates
        }
        deliveryAddress
        details
        label
        selected
        isActive
      }
      orderCount
      localStores
      customerType
    }
    totalCount
  }
}`

//local admin 

export const getlsadminDashboard = `
query GetLSAdminDashBoard {
  getLSAdminDashBoard {
    completedOrders
    pendingOrders
    productCount
    replenishCount
    storeCity
    storeName
    totalOrders
  }
}
`
export const getlocaladminproducts = `
query GetProductsForLS($storeId: String!, $limit: Int!, $skip: Int!) {
  getProductsForLS(storeId: $storeId, limit: $limit, skip: $skip) {
    products {
      _id
      name
      description
      aliases
      images
      defaultImage
      isActive
      category {
        _id
        name
        isActive
        images
        subcategories {
          _id
          name
          description
          isActive
          images
          categoryId
        }
        description
      }
      catalog {
        _id
        name
        description
        isActive
        parcelable
      }
      sortOrder
      hsn
      isNewProduct
      displayName
      skus {
        _id
        storeId
        productId
        discount
        barcode
        isActive
        mrp
        prices {
          customerType
          sellingPrice
        }
        unit
        measure
        minimumQty
        stockId
        stockUnits
        Stock {
          _id
          storeId
          quantity
          name
        }
        localStocks {
          storeId
          skuId
          quantity
          replenishQty
          defaultLSQty
        }
      }
    }
    totalCount
  }
}`
export const getOrders = `query Orders($page:Int){
    allOrders(page:$page){
      _id
      deliveryAddress
      deliveryCharges
      orderAmount
      paidAmount
      paymentMethod
      orderId
      user{
        _id
        name
        email
        phone
      }
      items{
        _id
        food{
          _id
          title
          description
          image
        }
        variation{
          _id
          title
          price
          discounted
        }
        addons{
          _id
          title
          description
          quantityMinimum
          quantityMaximum
          options{
            _id
            title
            price
          }
        }
        specialInstructions
        quantity
      }
      reason
      status
      paymentStatus
      orderStatus
      createdAt
      review{
        _id
        rating
        description
      }
      rider{
        _id
        name
      }
    }
  }`

