import React, { useState } from 'react'
import Header from '../components/Headers/Header';
import useGlobalStyles from '../utils/globalStyles';
import { Box, Typography, Container, Grid, Modal, Button } from '@mui/material';
import { getcsadmindashboard } from '../apollo';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { Icon } from '@iconify/react';
import shopOutline from '@iconify-icons/teenyicons/shop-outline';
import { withTranslation } from 'react-i18next';
import CreateLocalStore from '../components/Vendor/CreateLocalStore';
import { useHistory } from 'react-router-dom'
import CustomLoader from '../components/Loader/CustomLoader';

const GET_CS_ADMIN_DASHBOARD = gql`${getcsadmindashboard}`;

const AdminDashbord = (props) => {
  const history = useHistory();
  const client = useApolloClient();
  const globalClasses = useGlobalStyles();
  const { data, loading } = useQuery(GET_CS_ADMIN_DASHBOARD, {
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  })
  const [addVendorModal, setAddVendorModal] = useState(false)
  const userType = localStorage.getItem('user-enatega')
    ? JSON.parse(localStorage.getItem('user-enatega')).userType
    : null;
  const sty = {
    fontSize: 35,
    fontWeight: 'bold',
    color: "#0b3978",
    textAlign: 'center'
  };
  // Check if data and getSuperAdminDashboard are defined
  if (!data || !data.getCSAdminDashBoard) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CustomLoader />
      </div>
    );
  }
  // Destructure the data object to access the nested properties
  const {
    getCSAdminDashBoard: {
      storeCity,
      storeName,
      localStoreCount,
      productCount,
      completedOrders,
      replenishCount,
      pendingOrders,
      totalOrders
    }
  } = data;
  localStorage.setItem("totalProductsCount", productCount)
  localStorage.setItem("totalOrdersCount", totalOrders)

  const closeAddVendorModal = () => {
    setAddVendorModal(false);

  };
  const handleClick = () => {
    history.push({
      pathname: '/cs_admin/managestore',
      state: { data: '' },
    });
  };
  const handleClickProducts = () => {
    history.push({
      pathname: '/cs_admin/products',
      state: { data: '' },
    });
  };
  const handleClickReplention = () => {
    history.push({
      pathname: '/cs_admin/repletion',
      state: { data: '' },
    });
  };

  return (
    <>
      <Header />
      <Container sx={{ ml: 5 }} fluid className={globalClasses.flex_admin_dashboard}>
        {loading ? (
          <CustomLoader />
        ) :
          (
            <>
              <Grid container mt={2} spacing={2}>
                {userType === 1 ?
                  <Grid container mt={2} spacing={2}>
                    <Grid item xs={12} md={6} onClick={handleClickProducts}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          p: 2,
                          borderRadius: 5,
                          bgcolor: '#CADFFF',
                          width: '80%',
                          mb: 3,
                          // height: '100%' // Set the height to 100%
                        }}
                      >
                        <div>
                          <Typography sx={headSty}>
                            {storeName} <br /> {storeCity}
                          </Typography>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography sx={sty} style={{ marginRight: 'auto', flex: 1 }}>
                              {productCount} Products
                            </Typography>
                            <div style={{ marginLeft: '80px' }}>
                              <Icon icon="dashicons:products" width="40" height="40" style={{ color: "#0b3978" }} />
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} onClick={handleClickReplention}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              p: 2,
                              borderRadius: 5,
                              bgcolor: '#CADFFF',
                              width: '90%',
                              mb: 3,
                              // height: '100%' // Set the height to 100%
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                              <Typography sx={headSty} >
                                Replenish
                              </Typography>
                              <Typography sx={sty}>
                                {replenishCount}
                              </Typography>
                            </div>
                            <Icon icon="iconamoon:category" width={40} height={40} style={{ color: "#0b3978" }} />
                          </Box>
                        </Grid>

                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} onClick={handleClick}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          p: 2,
                          borderRadius: 5,
                          bgcolor: '#CADFFF',
                          width: '80%',
                          mb: 3,
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                          <Typography sx={headSty} >
                            Total local  Store
                          </Typography>
                          <Typography sx={sty}>
                            {localStoreCount} Store
                          </Typography>
                        </div>
                        <Icon icon={shopOutline} width={40} height={40} color="#0b3978" />  </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              p: 2,
                              borderRadius: 5,
                              bgcolor: 'common.white',
                              width: '80%',
                              mb: 3
                            }}
                          >
                            <div style={{ marginBottom: '10px' }}>
                              <Typography sx={headSty}>Order Completed  </Typography>
                            </div>
                            <div>
                              <Typography sx={sty}>
                                {completedOrders} {/* Replace this with actual data */}
                              </Typography>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              p: 2,
                              borderRadius: 5,
                              bgcolor: 'common.white',
                              width: '80%',
                              mb: 3
                            }}
                          >
                            <div style={{ marginBottom: '10px' }}>
                              <Typography sx={headSty}>Order Pending</Typography>
                            </div>
                            <div>
                              <Typography sx={sty}>
                                {pendingOrders} {/* Replace this with actual data */}
                              </Typography>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>

                  :
                  <Grid container mt={2} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          p: 2,
                          borderRadius: 5,
                          bgcolor: '#CADFFF',
                          width: '80%',
                          mb: 3,
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                          <Typography sx={headSty} >
                            Central Store Benagaluru
                          </Typography>
                          <Typography sx={sty}>
                            {productCount} Products
                          </Typography>
                        </div>
                        <Icon icon="bi:recycle" width={40} height={40} color="#0b3978" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              p: 2,
                              borderRadius: 5,
                              bgcolor: 'common.white',
                              width: '80%',
                              mb: 3
                            }}
                          >
                            <div style={{ marginBottom: '10px' }}>
                              <Typography sx={headSty}>Order Completed</Typography>
                            </div>
                            <div>
                              <Typography sx={sty}>
                                {loading ? '...' : "Placeholder for the data"} {/* Replace this with actual data */}
                              </Typography>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              p: 2,
                              borderRadius: 5,
                              bgcolor: 'common.white',
                              width: '80%',
                              mb: 3
                            }}
                          >
                            <div style={{ marginBottom: '10px' }}>
                              <Typography sx={headSty}>Order Pending</Typography>
                            </div>
                            <div>
                              <Typography sx={sty}>
                                {loading ? '...' : "Placeholder for the data"} {/* Replace this with actual data */}
                              </Typography>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }

                {userType === "CENTRALADMIN" ?
                  <Grid container mt={2} spacing={2}>
                    <Grid item xs={12} md={3}>
                      <Box>
                        <Button
                          className={globalClasses.dashbordBtn}
                          onClick={() => { }}>
                          <Icon icon="ic:twotone-plus" style={{ color: "white" }} />  Add Local Store2
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  : null}
              </Grid>

              {addVendorModal ? <Grid container>
                {/* <Grid item xs={12} order={{ xs: 2, lg: 1 }}> */}
                <Grid
                  sx={{ display: { xs: 'none', lg: 'block' } }}
                  item
                  mt={5}
                  ml={-2}
                  order={{ xs: 1, lg: 2 }}
                >
                  <Modal
                    open={addVendorModal}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    onClose={closeAddVendorModal}
                  >
                    <CreateLocalStore onClose={closeAddVendorModal} />
                  </Modal>
                </Grid>
                {/* </Grid> */}
              </Grid> : null}
            </>)}
      </Container>
    </>
  )
}

const headSty = { fontSize: 15, fontWeight: 'bold' }

export default withTranslation()(AdminDashbord);
