import React, { useRef, useState } from 'react';
import { useMutation, gql, useQuery, useApolloClient } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { AddCreditToCustomerWalletForOrder, GetAllCustomerTypes, UpdateCustomerType } from '../../apollo';
import { Button, Box, Typography, Grid, Paper, TextField, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';

const GET_CUSTOMER_TYPE = gql`
  ${GetAllCustomerTypes}
`;

const ADD_REFUND = gql`
  ${AddCreditToCustomerWalletForOrder}
`;

const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

function Refund(props) {
  const { onClose ,total } = props;
  const formRef = useRef();
  const mutation = ADD_REFUND;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const [selectedWorker, setSelectedWorker] = useState(props.vendor ? props.vendor.storeName : null);
  const [formErrors, setFormErrors] = useState({});
  const client = useApolloClient();
  const [workerError, setWorkerError] = useState(false);
  const [formData, setFormData] = useState({
    amount: props.vendor ? props.vendor.amount : '',
    reason :  props.vendor ? props.vendor.amount : '',
  });
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const [openSnackk, setopenSnackk] = useState(false);

  const { data: workerStatus } = useQuery(GET_CUSTOMER_TYPE, {
    onError: (error) => {
      const isForbiddenError = error && error.graphQLErrors.some((err) => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });

  const handleInputChange = e => {
    const { name, value } = e.target
    // Update form data state
    setFormData({ ...formData, [name]: value })
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' })
    }
  }

  const optionsworkerStatus = workerStatus
    ? workerStatus.getAllCustomerTypes.map((type) => ({
      label: type.name,
      customerType: type.customerType
    }))
    : [];

    const validateFormData = () => {
        let errors = {}
    
        if (!formData.amount) {
            errors.amount = 'Amount is required';
          } else if (parseFloat(formData.amount) > total) {
            errors.amount = `Amount should not be greater than the total (${total})`;
          }
        
          if (!formData.reason) {
            errors.reason = 'Reason is required';
          }
        setFormErrors(errors)
        return Object.keys(errors).length === 0
      }
  const onCompleted = (data) => {
    if (data ) {
      const message = t('Refund Added Successfully');
      errorSetter('');
      successSetter(message);
      setTimeout(hideAlert, 3000);
      setopenSnackk(true);
      props.refetch()
    }
  };

  const onError = (error) => {
    successSetter('');
    if (error.graphQLErrors) {
      errorSetter(error.graphQLErrors ? error.graphQLErrors[0].message : '');
      setopenSnackk(true);
    } else if (error.networkError) {
      errorSetter(error.networkError.result.errors[0].message);
    } else {
      errorSetter('Something went wrong!');
    }
    setTimeout(hideAlert, 3000);
    setopenSnackk(true);
  };

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    onError,
    onCompleted,
  });

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false);
  };


  const handleWorkerChange = (event, value) => {
    setSelectedWorker(value);
    setFormData({ ...formData, customerType: value ? value.customerType : '' });
    if (formData.customerType !== undefined || formData.customerType !== null) {
      setWorkerError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      return;
    }
    mutate({
        variables: {
            orderId: props.data,
            amount:parseFloat(formData.amount) , // Pass the selling price here
            description: formData.reason// Add a description if needed
          }
    });
    setTimeout(() => {
      if (typeof props.onClose === 'function') {
        props.onClose();
      }
    }, 2000);
  };
  const getCustomerTypeName = (customerTypeId) => {
    const customerType = optionsworkerStatus.find(type => type.customerType === customerTypeId);
    return customerType ? customerType.label : 'N/A';
  };
  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor ? t('Edit Product') : t('CRefund Details')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {!props.vendor ?
              <>
              <Grid item xs={12} sm={6}>
                  <TextField
                    name="amount"
                    label=" Amount"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.amount}
                    helperText={formErrors.amount}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="reason"
                    label=" Reason"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.reason}
                    helperText={formErrors.reason}
                  />
                </Grid>
             
              
              </>
              :
              <>
                {/* Additional fields for vendor */}
              </>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading || !!formErrors.amount} 
          >
            {props.vendor ? 'Update Product' : 'Save'}
          </Button>
          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}

export default withTranslation()(Refund);
