import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { useQuery,useMutation , gql, useApolloClient } from '@apollo/client'
import { DeleteTimeSlotForRiderConfigLS ,GetRiderConfigForLS} from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableCustomStyles'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper,Box,Snackbar ,Alert
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import { Icon } from '@iconify/react';
import AddLocalLocal from '../components/AddLocalUsers/AddLocalLocal'
import AddTimeSLot from '../components/AddTimeSlot/AddTimeSLot'


const GET_ALL_TIME_CONFIG  = gql`
${GetRiderConfigForLS}
`
const DELETE_TIME_SLOT = gql`
${DeleteTimeSlotForRiderConfigLS}`
const TimeSlotConfigLS = props => {
  const { t } = props
  const client = useApolloClient();
  const [editModal, setEditModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [vendors, setVendor] = useState(null)
 let [error, errorSetter] = useState('');
 const [success, successSetter] = useState('');
 const [openSnackk, setopenSnackk] = useState(false);
  const { data, error: errorQuery, loading: loadingQuery, refetch } = useQuery(
    GET_ALL_TIME_CONFIG ,{
        onError: error => {
          const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
    
          if (isForbiddenError) {
            localStorage.removeItem('user-enatega');
            localStorage.removeItem('restaurant_id');
            client.clearStore();
            props.history.push('/auth/login');
          }
        },
    } )
    const hideAlert = () => {
      errorSetter('');
      successSetter('');
      setopenSnackk(false);
    };
    const [mutate,] = useMutation(DELETE_TIME_SLOT, {
      refetchQueries: [{ query: GET_ALL_TIME_CONFIG }],
      onCompleted: () => {
        successSetter('Time slot deleted successfully');
        setopenSnackk(true);
        setTimeout(hideAlert, 3000);
      },
      onError: (error) => {
        errorSetter('Failed to delete time slot');
        setopenSnackk(true);
        setTimeout(hideAlert, 3000);
      }
    });
    const golbalClasses = useGlobalStyles()
    const closeEditModal = () => {
      setEditModal(false);
      // setAddVendorModal(false);
    };
  
    const openaAddVendorModal = () => {
      setAddVendorModal(true);
    };
    const closeAddVendorModal = () => {
      setAddVendorModal(false);
    };


    const columns = [
      {
        name: "Slot No.",
        cell: (row, index) => <>{index + 1}</>,
      },
      {
        name: 'Start',
        sortable: true,
        selector: 'start',
        
        style: { cursor: 'pointer' },
      },
   
      {
        name: 'Action',
        cell: row => <>{actionButtons(row)}</>,
      },
    ]
  

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }


  const filtered = data && data.getRiderConfigForLS.timeSlots
      
  const globalClasses = useGlobalStyles()
  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>
       
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
             
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                    
                  }, 5000)
                  // uncomment this for paud version
                  mutate({ variables: { id: row._id } });
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }
  return (
    <>
      <Header />
     
      <Container classNname={globalClasses.flex} fluid>
      <Box sx={{ textAlign: 'right', mb: 3 }}>
  <Button
    className={golbalClasses.dashbordBtn}
    onClick={openaAddVendorModal}
  >
      <Icon icon="ic:twotone-plus"  style={{color: "white"}} />  {t('Add New Time Slot')}
  </Button>
</Box>
     { addVendorModal ?  <Grid container>
          {/* <Grid item xs={12} order={{ xs: 2, lg: 1 }}> */}
            <Grid
              sx={{ display: { xs: 'none', lg: 'block' } }}
              item
              mt={5}
              ml={-2}
              order={{ xs: 1, lg: 2 }}
            >
              <Modal
                open={addVendorModal}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClose={closeAddVendorModal}
              >
                <AddTimeSLot onClose={closeAddVendorModal}  refetch={refetch}/>
              </Modal>
            </Grid>  </Grid> :null}
        {errorQuery ? (
          <span>
            `${t('Error')}! ${errorQuery.message}`
          </span>
        ) : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
              title={<TableHeader title={t('Time Configuration')} />}
            columns={columns}
            data={filtered}
            pagination
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            sortFunction={customSort}
            customStyles={customStyles}
            // selectableRows
            paginationIconLastPage=""
            paginationIconFirstPage=""
          />
        )}

<Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <AddLocalLocal vendor={vendors} editModal={editModal} onClose={closeEditModal} refetch ={refetch} />
        </Modal>
        <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
      </Container>
    </>
  )
}
export default withTranslation()(TimeSlotConfigLS)
