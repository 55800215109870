import React, { useEffect, useRef, useState } from 'react'
import { useMutation, gql, useQuery } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import {
  getalllocalstore,
  createlsadmin,
  updatelocaladmin,
  UpdateAdminPassword
} from '../../apollo'
import {
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar
} from '@mui/material'
import useStyles from './styles'
import useGlobalStyles from '../../utils/globalStyles'

const GET_LOCAL_STORE = gql`
  ${getalllocalstore}
`
const CREATE_LOCAL_STORE_ADMIN = gql`
  ${createlsadmin}
`
const EDIT__LOCAL_STORE_ADMIN = gql`
  ${updatelocaladmin}
`

const EDIT__LOCAL_STORE_ADMIN_PASSWORD = gql`
  ${UpdateAdminPassword}
`

const CustomPaperComponent = props => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
)

function AddLocalAdmin(props) {
  const { onClose } = props
  const formRef = useRef()
  const mutation = props.vendor
    ? EDIT__LOCAL_STORE_ADMIN
    : CREATE_LOCAL_STORE_ADMIN
  let [error, errorSetter] = useState('')
  const [success, successSetter] = useState('')
  const { t } = props
  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    id: props.vendor ? props.vendor._id : '',
    name: props.vendor ? props.vendor.name : '',
    email: props.vendor ? props.vendor.email : '',
    contactAddress: props.vendor ? props.vendor.contact_address : '',
    password: props.vendor ? props.vendor.password : '',
    phone: props.vendor ? props.vendor.phone : '',
    storeId: props.vendor ? props.vendor.storeId : ''
  })
  const [passwordModified, setPasswordModified] = useState(false)
  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('')
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      // errorSetter(error);
      errorSetter(error)
    } else if (networkError) errorSetter(networkError.result.errors[0].message)
    else errorSetter('Something went wrong!')
    setTimeout(hideAlert, 3000)
    setopenSnackk(true)
    props.refetch()
  }
  const onCompleted = data => {
    if (!props.vendor) clearFields()
    const message = props.vendor
      ? t('Local Admin  Updated Successfully')
      : t('Local Admin  Added Successfully')
    errorSetter('')
    props.refetch()
    successSetter(message)
    setTimeout(hideAlert, 3000)
    props.refetch()
    setopenSnackk(true)
  }
  const [updatePassword] = useMutation(EDIT__LOCAL_STORE_ADMIN_PASSWORD, {
    onError,
    onCompleted
  })

  const [openSnackk, setopenSnackk] = useState(false)
  // Define a state to hold your query data

  const [selectedCategory, setSelectedCategory] = useState(
    props.vendor ? props.vendor.storeName : null
  )

  const { data, refetch: refetchCentralStore } = useQuery(GET_LOCAL_STORE)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetchCentralStore()
      } catch (error) {}
    }

    fetchData()
  }, [])
  // Check if centralStoreData is undefined or null before accessing its properties

  useEffect(() => {
    // Other code
  }, [])
  useEffect(() => {
    refetchCentralStore()
  }, [refetchCentralStore])

  useEffect(() => {
    if (data && props.vendor && props.vendor.storeId) {
      const selectedStore = data.getAllLocalStores.find(
        store => store._id === props.vendor.storeId
      )

      setSelectedCategory(selectedStore || null)
    }
  }, [data, props.vendor])

  const options = data ? data.getAllLocalStores || [] : []

  const handleCategoryChange = (event, value) => {
    if (value) {
      setSelectedCategory(value) // Assuming value is an object from options array
      setFormData({ ...formData, storeId: value._id })
    } else {
      setSelectedCategory(null)
      setFormData({ ...formData, storeId: '' }) // Clear storeId if no option is selected
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    // Update form data state
    setFormData({ ...formData, [name]: value })
    if (name === 'password' && props.vendor) {
      setPasswordModified(true)
    }
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' })
    }
  }

  const validateFormData = () => {
    let errors = {}

    if (!formData.name) errors.name = 'Name is required'
    if (!formData.contactAddress)
      errors.contactAddress = 'Contact Address is required'
    if (!formData.email) errors.email = 'Email is required'
    if (!formData.password && !props.vendor)
      errors.password = 'Password is required'
    if (!formData.phone) errors.phone = 'Phone is required'
    if (!formData.storeId) errors.storeId = 'Store is required'

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_LOCAL_STORE }],
    onError,
    onCompleted
  })
  const clearFields = () => {
    formRef.current.reset()

    setFormData({
      name: props.vendor ? props.vendor.name : '',
      email: props.vendor ? props.vendor.email : '',
      contactAddress: props.vendor ? props.vendor.contactAddress : '',
      password: props.vendor ? props.vendor.password : '',
      phone: props.vendor ? props.vendor.phone : '',
      storeId: props.vendor ? props.vendor.storeId : ''
    })
  }

  const hideAlert = () => {
    errorSetter('')
    successSetter('')
    setopenSnackk(false)
  }

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const inputTextStyle = { color: 'black' }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!validateFormData()) {
      // setOpenSnack(true);
      return
    }
    if (true) {
      !props.vendor
        ? mutate({
            variables: {
              lsAdminInput: {
                contactAddress: formData.contactAddress,
                email: formData.email,
                name: formData.name,
                password: formData.password,
                phone: formData.phone,
                storeId: formData.storeId
              }
            }
          })
        : mutate({
            variables: {
              adminId: formData.id,
              lsAdminUpdateInput: {
                contactAddress: formData.contactAddress,
                email: formData.email,
                name: formData.name,
                // password: formData.password,
                phone: formData.phone,
                storeId: formData.storeId
              }
            }
          })
      if (passwordModified && formData.password) {
        updatePassword({
          variables: {
            password: formData.password,
            adminId: formData.id
          }
        })
      }
      // Close the modal after 3 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose() // Close the modal
        }
      }, 4000)
    }
  }
  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      maxWidth="sm"
      fullWidth>
      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography
              className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor
                ? t('Edit Local Admin User')
                : t('Add Local Admin User ')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {!props.editModal ? (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label=" Name"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="contactAddress"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.contactAddress}
                    helperText={formErrors.contactAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="password"
                    name="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.password}
                    helperText={formErrors.password}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    type="number"
                    name="phone"
                    label="Phone"
                    helperText={formErrors.phone}
                    error={Boolean(formErrors.phone)}
                    onInput={e => {
                      e.target.value = e.target.value.slice(0, 10)
                    }}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle, maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    // options={data.getAllCentralStores || []}
                    options={options}
                    getOptionLabel={option => option.name}
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Store"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: inputTextStyle
                        }}
                      />
                    )}
                    // inputProps={{ style: inputTextStyle }}
                    PaperComponent={CustomPaperComponent} // Use the custom paper component to style options
                  />
                </Grid>
              </>
            ) : (
              // Conditionally render the second form fields based on editModal prop
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    value={formData.name}
                    label=" Name"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="contactAddress"
                    value={formData.contactAddress}
                    label="Address"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.contactAddress}
                    helperText={formErrors.contactAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="email"
                    name="email"
                    label="Email"
                    value={formData.email}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="password"
                    name="password"
                    label="Password"
                    value={formData.password}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.password}
                    helperText={formErrors.password}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    name="phone"
                    type="phone"
                    onInput={e => {
                      e.target.value = e.target.value.slice(0, 10)
                    }}
                    helperText={formErrors.phone}
                    error={Boolean(formErrors.phone)}
                    label="Phone"
                    variant="outlined"
                    value={formData.phone}
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle, maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    // options={data.getAllLocalStores|| []}
                    options={options}
                    getOptionLabel={option => option.name}
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Store"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: inputTextStyle
                        }}
                      />
                    )}
                    // inputProps={{ style: inputTextStyle }}
                    PaperComponent={CustomPaperComponent} // Use the custom paper component to style options
                  />{' '}
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}>
            {props.vendor ? t('Update') : t('Add')}
          </Button>
          {/* <Button
          className={globalClasses.dashbordBtn}
          disabled={mutateLoading}
          onClick={async (e) => {
            e.preventDefault();
            // if (onSubmitValidaiton() && !mutateLoading) {
              if(true){
     !props.vendor ?       
       mutate({
                variables:{
                  lsAdminInput: {
                    contactAddress: formData.contactAddress,
                    email: formData.email,
                    name: formData.name,
                    password: formData.password,
                    phone: formData.phone,
                    storeId: formData.storeId
                  }
                }
                
              }): 
              mutate({
                variables:{
                  adminId : formData.id, 
                  lsAdminUpdateInput : {
                    contactAddress: formData.contactAddress,
                    email: formData.email,
                    name: formData.name,
                    // password: formData.password,
                    phone: formData.phone,
                    storeId: formData.storeId
                  }
                }
                
              }) 
              ;
              if (passwordModified && formData.password) {
                updatePassword({
                  variables: {
                    password: formData.password,
                    adminId: formData.id,
                  },
                });
              }
              // Close the modal after 3 seconds by calling the parent's onClose callback
              setTimeout(() => {
                if (typeof props.onClose === 'function') {
                  props.onClose(); // Close the modal
                }
              }, 4000);
            }
          }}
        >
          {props.vendor ? t('Update') : t('Save')}
        </Button> */}
          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose()
            }}>
            Cancel
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  )
}
export default withTranslation()(AddLocalAdmin)

