/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useQuery, gql, useApolloClient } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import CustomLoader from '../components/Loader/CustomLoader'
// core components
import Header from '../components/Headers/Header'
import {  getworkerordercompleted} from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { withRouter } from 'react-router-dom';
import { Container, Button, useTheme, } from '@mui/material'
import { customStyles } from '../utils/ppbbtable'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'

const GET_PACKEDORDERS = gql`
  ${getworkerordercompleted}
`
const PackagePackedOrderList = props => {
  const { t } = props;
  const globalClasses = useGlobalStyles()
  const workerType = JSON.parse(localStorage.getItem('user-enatega')).workerType
  const [page, setPage] = useState(
    1
  );
  var [tableData ,setTableData] =useState([])
  const [currentPage, setCurrentPage] = useState(page);
 const [totalRows ,setTotalRows] = useState(0)
 const [perPage ,setPerPage] = useState(10)
 const client = useApolloClient();
  const variables = {
    limit: perPage,
    skip: (page - 1) * perPage,
  };
  const {
    data,
    loading: loadingQuery,
    refetch
  } = useQuery(GET_PACKEDORDERS, { fetchPolicy: 'network-only',variables,  onCompleted: (data) => {
    if (data && data.getWorkerCompletedOrders) {
      tableData  =data.getWorkerCompletedOrders.orders || []
      setTableData(tableData);
      setTotalRows(data.getWorkerCompletedOrders.totalCount || 0);
     
    }
  }, 
  onError: error => {
    const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

    if (isForbiddenError) {
      localStorage.removeItem('user-enatega');
      localStorage.removeItem('restaurant_id');
      client.clearStore();
      props.history.push('/auth/login');
    }
  },})
 
useEffect(() => {
  if (data) {
    tableData  =data.getWorkerCompletedOrders.orders || []
    setTableData(tableData);
    setTotalRows(data.getWorkerCompletedOrders.totalCount || 0);
    }
}, [data]);

// Update the query variables when the page value changes
useEffect(() => {
  refetch({
    variables: {
      // storeId: userDetails.storeId,
      limit: perPage,
      skip: (page - 1) * perPage,
      // skip: page,
    },
  });
}, [page, perPage, refetch]);

  const handlePerRowChange=(newPerPage) =>{
    setPerPage(newPerPage )
  }
  // Inside your handlePageChange function, update the local storage
const handlePageChange = (page) => {
  setPage(page);
  setCurrentPage(page)

};
  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }
  const getOrderStatusWord = (statusNumber) => {
    const statusMap = {
      0: "INITIATED",
      1: "CONFIRMED",
      2: "PACKING",
      3: "BILLING",
      4: "READY FOR DELIVERY",
      5: "RIDER ASSIGNED",
      6: "OUT FOR DELIVERY",
      7: "DELIVERED",
      8: "CANCELLED",
      9: "FAILED OUT OF STOCK",
      10: "FAILED INVALID DELIVERY ADDRESS",
      11: "FAILED",
    };
  
    return statusMap[statusNumber] || "N/A";
  };
  const columns = [

    {
      name: "Order ID",
      selector: '_id', // Updated selector
      
      style: { cursor: 'pointer',minWidth:"300px" },
    },

    {
      name: "Store Name",
      selector: 'localStore', // Assuming you want to display localStore
      cell: (row) => <>{row.localStore.join(', ')}</>, // Assuming localStore is an array
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Order Date",
      selector: 'orderDate', // Updated selector
      cell: (row) => <>{new Date(row.orderDate).toLocaleDateString()}</>, // Show only the date part
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Delivery Date",
      selector: 'deliveryDate', // Updated selector
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Completion Time",
      selector: 'completionTime', // Updated selector
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Items Count",
      selector: 'itemsCount', // Updated selector
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Order Status",
      selector: 'orderStatus', // Updated selector
      cell: (row) => <>{getOrderStatusWord(row.orderStatus)}</>, // Use getOrderStatusWord method
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: "Order Amount",
      selector: 'orderAmount', // Updated selector
      style: {
        cursor: 'pointer',
      },
    },
   
    {
      name: t('Action'),
      cell: row => <>{actionButtons(row)}</>,
    }
  ];
  const theme = useTheme();

  const actionButtons = row => {
    return (
      <>
    
        <Button
          size="20px"
          variant="contained"
          sx={{
            fontWeight: 'bold',
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.common.white,
            padding: 0,
            height: '15px',
            fontSize: '7px',
            '&:hover': {
              border: '2px solid #637BFE',
              color: theme.palette.warning.dark,
              backgroundColor: "white"
            }
          }}
          onClick={e => {
            e.preventDefault()
            localStorage.setItem('OrderData', JSON.stringify(row))
            if (workerType === 0) {
              props.history.push({
                pathname: '/package_admin/OrderDetails',
                state: { id: row._id }
              });
            } else if (workerType === 1) {
              props.history.push({
                pathname: '/billing_admin/OrderDetails',
                state: { id: row._id }
              });
            } 
          }}>
          {t('View Details')}
        </Button>

      </>
    )
  }

  return (
    <>
      <Header />
      <Container className={globalClasses.flex} fluid>
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            customStyles={customStyles}
            subHeader={true}
        
              title={<TableHeader title={t('All Completed Orders')} />}
            columns={columns}
            data={tableData || []}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            sortFunction={customSort}
          />
        )}
      </Container>
    </>
  )
}
export default withTranslation()(withRouter(PackagePackedOrderList));

