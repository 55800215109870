import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { FilterProductsByCategories, getlocaladminproducts, searchproductls } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import SearchBar from '../components/TableHeader/SearchBar'
import { customStyles } from '../utils/tableForManageProductsForLS'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography, Button,
  Paper, Box, Badge,
} from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import TableHeader from '../components/TableHeader'
import Alert from '../components/Alert'
import CSSku from '../components/AddSKU/CSSku'
import EditProductLs from '../components/AddProduct/EditProductLs'
import AllPRoductFIlter from '../utils/fIlters/AllPRoductFIlter'

const GET_PRODUCTS = gql`
  ${getlocaladminproducts}
`
const GET_SEARCH_PRODUCTS = gql`
  ${searchproductls}
`
const GET_ALL_PRODUCT_filter = gql`
${FilterProductsByCategories}
`

const LocalAdminProducts = props => {
  const { t } = props;
  const [editModal, setEditModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [skuModal, setSkuModal] = useState(false)
  const userDetailsStr = localStorage.getItem('user-enatega');
  const userDetails = JSON.parse(userDetailsStr);
  const golbalClasses = useGlobalStyles()
  const [badgeContent, setbadgeContent] = useState(false);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const client = useApolloClient();
  var [tabledata, setTabledata] = useState(null);
  const [OpenFilter, setOpenFilter] = useState(false)
  var [FilterPaload, setFilterPaload] = useState({})
  var [DefaultValue, setDefaultValue] = useState({})
  const [reload, setReload] = useState(false)
  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };
  const closeSkuModal = () => {
    setSearchQuery(false);
    setSkuModal(false)
    // setAddVendorModal(false);
  };
  const handleOpenFilter = () => {
    setOpenFilter(true)
  }
  const CloseFilter = () => {
    setOpenFilter(false)
  }
  const handleResetFilters = () => {
    setFilterPaload({});
    setDefaultValue({});
    setPage(1);
    setReload(!reload)
    setbadgeContent(false)
  };
  const [refetchfilterData] = useLazyQuery(GET_ALL_PRODUCT_filter, {
    fetchPolicy: 'no-cache',

    onCompleted: (resultData) => {
      if (resultData && resultData.filterProductsByCategories) {
        CloseFilter()
        tabledata = resultData.filterProductsByCategories.products || []
        setTabledata(tabledata);
        setTotalRows(resultData.filterProductsByCategories.totalCount || 0);
      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },

  });
  const filterData = (filterInput) => {
    setDefaultValue(filterInput)
    FilterPaload = {

      "categoryId": filterInput.orderStatus,
      "subCategoryId": filterInput.workerStatus,
      "limit": perPage,
      "skip": (page - 1) * perPage,

    }
    //  setFilterPaload(filterInput)
    refetchfilterData({
      variables: FilterPaload,
    })
    setbadgeContent(true)
  }
  // Fetch data using the stored page number
  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_PRODUCTS,
    {
      variables: {
        storeId: userDetails.storeId,
        limit: perPage,
        skip: (page - 1) * perPage,
        // skip: page,
      }, onCompleted: (data, errors) => {
        setTabledata(data && data.getProductsForLS.products ? data.getProductsForLS.products : [])

        setTotalRows(data.getProductsForLS.totalCount);
      },
      onError: error => {
        const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

        if (isForbiddenError) {
          localStorage.removeItem('user-enatega');
          localStorage.removeItem('restaurant_id');
          client.clearStore();
          props.history.push('/auth/login');
        }
      },
    }
  );
  const variablesSearch = {
    text: searchQuery, // Assuming `text` is the variable used in your search query
    limit: perPage,
    skip: (page - 1) * perPage,
  };
  const { loading, data: dataSearch, refetch: refetchSearch } = useQuery(
    GET_SEARCH_PRODUCTS, {
    variables: variablesSearch,

    onCompleted: (data, errors) => {
      setTabledata(data && data.searchProductsLS.products ? data.searchProductsLS.products : [])

      setTotalRows(data.searchProductsLS.totalCount);
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  )
  const onChangeSearch = e => {
    setSearchQuery(e.target.value)
    setTimeout(() => {
      refetchSearch()
    }, 1000)

  }

  const onClickRefetch = cb => {
    refetch()
    setReload(!reload)
    setbadgeContent(false)
    cb()
    setFilterPaload({});
    setDefaultValue({});
  }
  useEffect(() => {
    if (data && data.getProductsForLS.products) {
      setTabledata(data && data.getProductsForLS.products ? data.getProductsForLS.products : [])
      setTotalRows(data.getProductsForLS.totalCount);
    } else if (data && data.searchProductsLS.products) {
      setTabledata(dataSearch && data.searchProductsLS.products ? data.searchProductsLS.products : [])
      setTotalRows(data.searchProductsLS.totalCount);

    }

  }, [data, reload]);

  // Update the query variables when the page value changes
  useEffect(() => {
    refetch({
      variables: {
        storeId: userDetails.storeId,
        limit: perPage,
        skip: (page - 1) * perPage,
      },
    });
  }, [page, perPage, refetch]);

  useEffect(() => {
  }, [tabledata])
  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)

  };

  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }


  const columns = [
    {
      name: "Product ID",
      selector: '_id'
    },
    {
      name: "Product Name",
      sortable: true,
      selector: 'displayName'
      ,
      style: { cursor: 'pointer', minWidth: "250px" },
    },
    {
      name: "Quantity",
      selector: 'skus',
      cell: row => {
        return row.skus.filter(sku => sku.isActive).map(sku => {
          const localStock = sku.localStocks.find(stock => stock.storeId === userDetails.storeId);
          return localStock ? localStock.quantity : 0;
        }).join(', ');
      },
      sortable: false
    },
    {
      name: "Aliases",
      sortable: false,
      cell: row => row.aliases.join(', ')  // Join aliases array into a string
    },

    {
      name: "Description",
      sortable: true,
      selector: 'description',
      style: { cursor: 'pointer', minWidth: "300px" },
    },
    {
      name: "Catalog Name",
      sortable: true,
      selector: 'catalog.name',
      style: { cursor: 'pointer', minWidth: "300px" },
    },
    {
      name: "Category",
      sortable: true,
      selector: 'category.name' // Access category name from the nested object
    },
    {
      name: "Default Image",
      sortable: false,
      cell: row => (
        <img
          src={row.defaultImage}
          alt={row.displayName}
          style={{ width: 50, height: 50 }}
        />
      )
    },

    {
      name: "HSN",
      sortable: true,
      selector: 'hsn'
    },
    {
      name: t('Action'),
      cell: row => <>{actionButtons(row)}</>
    }
  ];


  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(false)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  toggleModal(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      {isOpen && (
        <Alert
          message={t('AvailableAfterPurchasing')}
          severity="warning"
        />
      )}

      <Container className={golbalClasses.flex}>


        {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery || loading ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
            subHeaderComponent={
              <>
                <SearchBar
                  value={searchQuery}
                  onChange={onChangeSearch}
                  onClick={() => onClickRefetch(refetch)}

                />
                <Box style={{ marginTop: -40 }}>
                  {badgeContent === true ? (
                    <Badge
                      badgeContent={''}
                      color="success"
                      variant="dot"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Button
                        onClick={handleOpenFilter}
                      >

                        <TuneIcon />
                      </Button>
                    </Badge>
                  ) : <Button
                    onClick={handleOpenFilter}
                  >

                    <TuneIcon />
                  </Button>}
                </Box>
              </>
            }
            title={<TableHeader title={t('LS Manage Products ')} />}
            columns={columns}
            data={tabledata || []}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            sortFunction={customSort}
            customStyles={customStyles}

          />
        )}
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <EditProductLs vendor={vendors} onClose={closeEditModal} refetch={refetch} />
        </Modal>
        <Modal
          open={skuModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <CSSku vendor={vendors} onClose={closeSkuModal} refetch={refetch} />
        </Modal>

        {OpenFilter &&
          <AllPRoductFIlter open={OpenFilter} close={CloseFilter} filterData={filterData} DefaultValue={DefaultValue} handleResetFilters={handleResetFilters} />
        }
      </Container>
    </>
  )
}

export default withTranslation()(LocalAdminProducts)
