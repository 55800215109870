
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import Header from '../components/Headers/Header';
import CustomLoader from '../components/Loader/CustomLoader';
import { GetReplenishableProductsForWorker } from '../apollo';
import DataTable from 'react-data-table-component';
import orderBy from 'lodash/orderBy';
import { customStyles } from '../utils/tableforManageRepletion';
import useGlobalStyles from '../utils/globalStyles';
import { Icon } from '@iconify/react';
import {
  Container,
  Button,
  Box,
  TextField,
  Autocomplete,
  Paper
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import TableHeader from '../components/TableHeader';
import VerifyStockRequestModal from './VerifyStockRequestModal'; // Import the modal component

const GET_REPLENTION_PRODUCTS = gql`${GetReplenishableProductsForWorker}`;

const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

const CreateNewReplentionRequest = props => {
  const { t } = props;
  const [editMode, setEditMode] = useState(null);
  const [editedLocalQty, setEditedLocalQty] = useState({});
  const [requestedQty, setRequestedQty] = useState({});
  const [editedRows, setEditedRows] = useState([]);
  const [mergedModalData, setMergedModalData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [reasons, setReasons] = useState({});
  const userDetailsStr = localStorage.getItem('user-enatega');
  const userDetails = JSON.parse(userDetailsStr);
  const golbalClasses = useGlobalStyles();
  const client = useApolloClient();
  const [page, setPage] = useState(1);
  const [tabledata, setTabledata] = useState([]);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const inputTextStyle = { color: 'black' };
  // const [selectedRowsForModal, setSelectedRowsForModal] = useState([]);
  const reasonOptions = [
    { label: "NEED REPLENISH", id: 0 },
    { label: "QUNTITY SAME CS", id: 1 },
    { label: "QUNTITY CHANGE CS", id: 2 },
    { label: "QUNTITY NOT AVAILABLE", id: 3 },
    { label: "QUNTITY DAMAGED TRANSIT", id: 4 },
    { label: "QUNTITY MISSING TRANSIT", id: 5 },
    { label: "ITEM REMOVED", id: 6 },
    { label: "REPLENISHED", id: 7 }
  ];

  const handleBackClick = () => {
    localStorage.removeItem('selectedRowIds'); // Remove selectedRowIds from local storage
    localStorage.removeItem('editedRows'); 

      // Determine the route based on workerType
      const workerType = userDetails.workerType;
      const route = workerType === 0 
        ? '/package_admin/repletion' 
        : '/billing_admin/repletion';

      props.history.push({
        pathname: route,
      });// Navigate back to ManageCategory component
  };

  const variables = {
    limit: perPage,
    skip: (page - 1) * perPage,
  };

  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_REPLENTION_PRODUCTS, {
      variables,
      onCompleted: (data) => {
        let fetchedData = data && data.getReplenishableProductsForWorker.products ? data.getReplenishableProductsForWorker.products : [];
        // Merge the edited rows with the fetched data
        const updatedData = mergeEditedData(fetchedData);
        setTabledata(updatedData);
        setTotalRows(data.getReplenishableProductsForWorker.totalCount);
      },
      onError: error => {
        const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
        if (isForbiddenError) {
          localStorage.removeItem('user-enatega');
          localStorage.removeItem('restaurant_id');
          client.clearStore();
          props.history.push('/auth/login');
        }
      },
    }
  );

  useEffect(() => {
    if (data && data.getReplenishableProductsForWorker.products) {
      let fetchedData = data.getReplenishableProductsForWorker.products;
      // Merge the edited rows with the fetched data
      const updatedData = mergeEditedData(fetchedData);
      setTabledata(updatedData);
      setTotalRows(data.getReplenishableProductsForWorker.totalCount);
    }
  }, [data]);

  useEffect(() => {
    refetch({
      variables: {
        limit: perPage,
        skip: (page - 1) * perPage,
      },
    });
  }, [page, perPage, refetch]);

  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page);
  };

  const handleEditClick = (row) => {
    setEditMode(row._id);
    setEditedLocalQty(prev => ({ ...prev, [row._id]: row.localQty }));
    setRequestedQty(prev => ({ ...prev, [row._id]: row.requestedQty || (row.defaultLSQty - row.localQty  -row.replenishQty) }));
    setReasons(prev => ({ ...prev, [row._id]: reasons[row._id] || { label: "NEED REPLENISH", id: 0 } }));
  };

  const handleSaveClick = (row) => {
    const updatedRow = {
      ...row,
      localQty: editedLocalQty[row._id],
      requestedQty: requestedQty[row._id],
      reason: reasons[row._id].id
    };
    setEditedRows(prev => {
      const index = prev.findIndex(r => r._id === row._id);
      if (index !== -1) {
        const updated = [...prev];
        updated[index] = updatedRow;
        localStorage.setItem('editedRows', JSON.stringify(updated));
        return updated;
      } else {
        const updated = [...prev, updatedRow];
        localStorage.setItem('editedRows', JSON.stringify(updated));
        return updated;
      }
    });

    // Update the tabledata state to reflect the changes
    setTabledata(prev => {
      const index = prev.findIndex(r => r._id === row._id);
      if (index !== -1) {
        const updated = [...prev];
        updated[index] = updatedRow;
        return updated;
      }
      return prev;
    });

    setEditMode(null);
  };
  const handleCancelClick = () => {
    setEditMode(null);
  };
  const handleRequestedQtyChange = (id, value) => {
    setRequestedQty(prev => ({ ...prev, [id]: value }));
  };

  const handleReasonChange = (id, value) => {
    setReasons(prev => ({ ...prev, [id]: value }));
  };
  const handleRowSelected = state => {
    const { selectedRows } = state;
  
    // Merge requestedQty into selected rows
    const updatedSelectedRows = selectedRows.map(row => ({
      ...row,
      requestedQty: requestedQty[row._id] || (row.defaultLSQty - row.localQty - row.replenishQty)
    }));
  
    // Store selected row IDs in local storage
    const newIds = updatedSelectedRows.map(row => row._id);
    const storedIds = localStorage.getItem('selectedRowIds');
    let updatedIds = storedIds ? JSON.parse(storedIds) : [];
  
    // Only add unique IDs to updatedIds
    updatedIds = [...new Set([...newIds])];
    localStorage.setItem('selectedRowIds', JSON.stringify(updatedIds));
    
    setSelectedRows(updatedSelectedRows);
    setSelectedRowIds(updatedIds);
  };

  const columns = useMemo(() => [
    {
      name: "Product Name",
      sortable: true,
      selector: 'name',
      style: { cursor: 'pointer' },
    },
    {
      name: "SKU ID",
      sortable: true,
      selector: 'skuId',
      style: { cursor: 'pointer' },
    },
    {
      name: "Local Quantity",
      sortable: true,
      selector: 'localQty',
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: "Minimum Quantity",
      sortable: true,
      selector: 'minimumQty',
      style: { cursor: 'pointer' },
    },
    {
      name: "Default Quantity",
      sortable: true,
      selector: 'defaultLSQty',
      style: { cursor: 'pointer' },
    },
   
    {
      name: "Replenish Quantity",
      sortable: true,
      selector: 'replenishQty',
      style: { cursor: 'pointer' },
    },
    {
      name: "Require Quantity",
      cell: row => editMode === row._id ? (
        <TextField
          inputProps={{ style: inputTextStyle }}
          type="text"
          autoFocus
          value={requestedQty[row._id]}
          onChange={e => handleRequestedQtyChange(row._id, e.target.value)}
        />
      ) : (
        requestedQty[row._id] || (row.defaultLSQty - row.localQty  -row.replenishQty)
      ),
      style: { cursor: 'pointer' },
    },
    {
      name: "Reason",
      cell: row => editMode === row._id ? (
        <Autocomplete
        style={{minWidth:"100%"}}
          options={reasonOptions}
          getOptionLabel={(option) => option.label}
          value={reasons[row._id]}
          onChange={(e, value) => handleReasonChange(row._id, value)}
          renderInput={(params) => <TextField {...params}
          variant="outlined"
          inputProps={{ ...params.inputProps, style: inputTextStyle }}
        />}
          PaperComponent={CustomPaperComponent}
        />
      ) : (
        reasons[row._id]?  reasons[row._id].label : "NEED REPLENISH"
      ),
      style: { cursor: 'pointer' , minWidth:"150px" },
    },
    {
      name: 'Action',
      cell: row => selectedRowIds.includes(row._id) ? (
        editMode === row._id ? (
          <>
            <Button onClick={() => handleSaveClick(row)}>Save</Button>
            <Button onClick={handleCancelClick}>Cancel</Button>
          </>
        ) : (
          <Button onClick={() => handleEditClick(row)}>Edit</Button>
        )
      ) : null,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ],
  [editMode, handleSaveClick, handleCancelClick, handleEditClick, editedLocalQty, requestedQty, reasons, selectedRowIds]);
  const customSort = useCallback((rows, selector, direction) => {
    const handleField = row => {
      if (typeof selector === 'function') {
        const data = selector(row);
        return data ? data.toString().toLowerCase() : "";
      }
      return "";
    };
    return orderBy(rows, handleField, direction);
  }, []);

  const handlePrint = () => {
    const doc = new jsPDF();
    const tableColumn = ["Product Name", "Local Quantity", "Minimum Quantity", "SKU ID", "Requested Quantity", "Reason"];
    const tableRows = selectedRows.map(row => [
      row.name,
      row.localQty,
      row.minimumQty,
      row.skuId,
      requestedQty[row._id] || (row.defaultLSQty - row.localQty -row.replenishQty),
      reasons[row._id]?  reasons[row._id].label : "NEED REPLENISH"
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      theme: 'grid',
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40 },
        3: { cellWidth: 40 },
        4: { cellWidth: 40 },
        5: { cellWidth: 40 }
      },
    });

    doc.save('selected_products.pdf');
  };

  useEffect(() => {
    // Get the selected row IDs from local storage
    const storedIds = localStorage.getItem('selectedRowIds');
    const storedEditedRows = localStorage.getItem('editedRows');
    if (storedIds) {
      setSelectedRowIds(JSON.parse(storedIds));
    }
    if (storedEditedRows) {
      const parsedEditedRows = JSON.parse(storedEditedRows);
      setEditedRows(parsedEditedRows);
      // Set the requested quantity and reason for the parsed edited rows
      const requestedQtyState = {};
      const reasonsState = {};
      parsedEditedRows.forEach(row => {
        requestedQtyState[row._id] = row.requestedQty;
        reasonsState[row._id] = reasonOptions.find(option => option.id === row.reason) || { label: "NEED REPLENISH", id: 0 };
      });
      setRequestedQty(requestedQtyState);
      setReasons(reasonsState);
    }
  }, []);

  const mergeEditedData = (fetchedData) => {
    const storedEditedRows = JSON.parse(localStorage.getItem('editedRows') || '[]');
    const editedMap = new Map(storedEditedRows.map(row => [row._id, row]));
    const editedDatalist = fetchedData.map(row => {
      const editedRow = editedMap.get(row._id);
      return editedRow ? { ...row, requestedQty: editedRow.requestedQty, reason: editedRow.reason } : row;
    });
    return editedDatalist;
  };

  const handleOpenModal = () => {
    const selectedAndEditedRows = mergeRows(editedRows, selectedRows); // Merge selected rows with edited rows
    setMergedModalData(selectedAndEditedRows)
    setIsModalOpen(true);
  };
useEffect(()=>{
}, [editMode])
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    // Implement your submit functionality here
    handleCloseModal();
  };
  const mergeRows = (editedRows, selectedRows) => {
    const editedRowsMap = new Map(editedRows.map(row => [row._id, row]));
  
    return selectedRows.map(row => {
      if (editedRowsMap.has(row._id)) {
        return { ...row, ...editedRowsMap.get(row._id) };
      }
      return row;
    });
  };

  return (
    <>
      <Header />
      <Container className={golbalClasses.flex}>
        {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <>
            <Box sx={{ textAlign: 'right', mb: 3 }}>
              <Button
                className={golbalClasses.dashbordBtn}
                onClick={handleOpenModal}
              >
                <Icon icon="ic:twotone-plus" style={{ color: "white" }} /> {t('Submit Stock Request')}
              </Button>
              <Button
                className={golbalClasses.dashbordBtn}
                onClick={handlePrint}
              >
                <Icon icon="gridicons:print" style={{ color: "white" }} /> {t('Print')}
              </Button>
              <Button className={golbalClasses.dashbordBtn} onClick={handleBackClick}>
                <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
              </Button>
            </Box>
            <DataTable
              subHeader={true}
              title={<TableHeader title={t('Manage Replention ')} />}
              columns={columns}
              data={tabledata || []}
              progressPending={loadingQuery}
              progressComponent={<CustomLoader />}
              paginationDefaultPage={currentPage}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowChange}
              onChangePage={handlePageChange}
              sortFunction={customSort}
              selectableRows
              selectableRowSelected={(row) => selectedRowIds.includes(row._id)}
              onSelectedRowsChange={handleRowSelected}
              customStyles={customStyles} // Pass customStyles here
            />
          </>
        )}
      </Container>
    
      <VerifyStockRequestModal 
        open={isModalOpen}
        onClose={handleCloseModal}
        editedRows={mergedModalData}
        onSubmit={handleSubmit}
        t={t}
        // selectedRows={selectedRowsForModal}
        refetch={refetch}
        // customStyles={customStyles} // Pass customStyles here
      />

   
     
    </>
  );
};

export default withTranslation()(CreateNewReplentionRequest);



