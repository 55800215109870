import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import useStyles from '../../components/AddUser/styles';
import useGlobalStyles from '../globalStyles';
import { getalllocalstore } from '../../apollo';
import { gql, useQuery } from '@apollo/client';

const GET_LOCAL_STORE = gql`
  ${getalllocalstore}
`;

const AllOrderFilter = ({ open, close, filterData, DefaultValue, handleResetFilters }) => {
  const formRef = useRef();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const userType = JSON.parse(localStorage.getItem('user-enatega')).userType
  const CustomPaperComponent = (props) => (
    <Paper {...props} style={{ background: 'white', color: 'black' }} />
  );

  const { data } = useQuery(GET_LOCAL_STORE);
  const StoreDta = data ? data.getAllLocalStores || [] : [];

  const workerStatus = [
    { label: 'NOT_STARTED', value: 0 },
    { label: 'PP_ASSIGNED', value: 1 },
    { label: 'PP_IN_PROGRESS', value: 2 },
    { label: 'PP_COMPLETED', value: 3 },
    { label: 'BILLING_ASSIGNED', value: 4 },
    { label: 'BILLING_IN_PROGRESS', value: 5 },
    { label: 'BILLING_COMPLETED', value: 6 },
    { label: 'RIDER_ASSIGN_MANUAL', value: 7 },
    { label: 'RIDER_ASSIGNED', value: 8 },
    { label: 'RIDER_OUT_FOR_DELIVERY', value: 9 },
    { label: 'RIDER_DELIVERED', value: 10 },
    { label: 'CANCELLED', value: 11 }
  ];
  const StatusOPtion = [
    { label: 'INITIATED', value: 0 },
    { label: 'CONFIRMED', value: 1 },
    { label: 'PACKING', value: 2 },
    { label: 'BILLING', value: 3 },
    { label: 'READY_FOR_DELIVERY', value: 4 },
    { label: 'RIDER_ASSIGNED', value: 5 },
    { label: 'OUT_FOR_DELIVERY', value: 6 },
    { label: 'DELIVERED', value: 7 },
    { label: 'CANCELLED', value: 8 },
    { label: 'FAILED_OUT_OF_STOCK', value: 9 },
    { label: 'FAILED_INVALID_DELIVERY_ADDRESS', value: 10 },
    { label: 'FAILED', value: 11 },
    { label: 'FAILED_NO_ITEMS_CART', value: 12 },
    { label: 'QUERY_CART_NOT_CALLED', value: 13 }
  ];
  const emailoption = [
    'rider01@gmail.com',
    'pickandpack02@gmail.com',
    'pickandpack01@gmail.com'
  ];


  const [formData, setFormData] = useState({
    lsName: (DefaultValue && DefaultValue.lsName !== undefined) ? DefaultValue.lsName : '',
    endDate: (DefaultValue && DefaultValue.endDate !== undefined) ? DefaultValue.endDate : '',
    orderStatus: (DefaultValue && DefaultValue.orderStatus !== undefined) ? DefaultValue.orderStatus : null,
    startDate: (DefaultValue && DefaultValue.startDate !== undefined) ? DefaultValue.startDate : '',
    workerEmail: (DefaultValue && DefaultValue.workerEmail !== undefined) ? DefaultValue.workerEmail : '',
    workerStatus: (DefaultValue && DefaultValue.workerStatus !== undefined) ? DefaultValue.workerStatus : null,
  });



  const [selectedCategory, setSelectedCategory] = useState((DefaultValue && DefaultValue.lsName) ? { name: DefaultValue.lsName } : null);
  const [selectEmail, setselectEmail] = useState(DefaultValue ? DefaultValue.workerEmail : '',);
  const [StartDate, setStartDate] = useState(DefaultValue ? DefaultValue.startDate : '',);
  const [EndDate, setEndDate] = useState(DefaultValue ? DefaultValue.endDate : '',);
  const [orderStatus, setorderStatus] = useState(DefaultValue ? (StatusOPtion.find(option => option.value === DefaultValue.orderStatus)) : null,);
  const [WorkerStatus, setWorkerStatus] = useState(DefaultValue ? (workerStatus.find(option => option.value === DefaultValue.workerStatus)) : null,);

  const handleCategoryChange = (event, value) => {
    if (value) {
      setSelectedCategory(value);
      setFormData({ ...formData, lsName: value.name });
    } else {
      setSelectedCategory(null);
      setFormData({ ...formData, lsName: '' });
    }
  };


  const handleEmailChange = (event, value) => {
    if (value) {
      setselectEmail(value);
      setFormData({ ...formData, workerEmail: value });
    } else {
      setselectEmail(null);
      setFormData({ ...formData, workerEmail: '' });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    filterData(formData);
  };
  const handleClose = () => {
    setFormData({
      lsName: '',
      endDate: '',
      orderStatus: null,
      startDate: '',
      workerEmail: '',
      workerStatus: null,
    });
    DefaultValue = {}

    close();
    handleResetFilters();
  };
  const handleReset = () => {
    setFormData({
      lsName: '',
      endDate: '',
      orderStatus: null,
      startDate: '',
      workerEmail: '',
      workerStatus: null,
    });

    setSelectedCategory(null);
    setselectEmail('');
    setStartDate('');
    setEndDate('');
    setorderStatus(null);  // Ensure this is set to null or default value
    setWorkerStatus(null); // Ensure this is set to null or default value

    // Optionally refetch data if needed or re-render components
    // refetchCentralStore(); 
  };

  const [endDateError, setEndDateError] = useState('');

  const handleEndDateChange = (value) => {
    const newEndDate = value.target.value;
    if (newEndDate < StartDate) {
      setEndDateError('End date cannot be less than start date.');
    } else {
      setEndDateError('');
      setEndDate(newEndDate);
      setFormData({ ...formData, endDate: newEndDate });
    }
  };
  return (
    <div>
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
        <form ref={formRef} onSubmit={handleSubmit}>
          <DialogTitle>
            <Box className={classes.heading}>
              <Typography className={classes.textWhite}>Filters</Typography>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              {userType === 2 ?

                null :

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disabled={userType === 2}
                    id="combo-box-demo"
                    options={StoreDta}
                    getOptionLabel={(option) => option.name}
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Store"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                  />
                </Grid>}
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  freeSolo
                  id="combo-box-demo"
                  options={emailoption}
                  onChange={handleEmailChange}
                  value={selectEmail && selectEmail ? selectEmail : ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Workers Email"
                      variant="outlined"
                      inputProps={{ ...params.inputProps, style: inputTextStyle }}
                    />
                  )}
                  PaperComponent={CustomPaperComponent}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Select Start Date"
                  type="date"
                  value={StartDate}

                  onChange={(value) => {
                    setStartDate(value.target.value);
                    setFormData({ ...formData, startDate: value.target.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: { xs: '100%', sm: '50%', md: '100%', lg: '100%' },
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Select End Date"
                  type="date"
                  value={EndDate}
                  onChange={handleEndDateChange}
                  error={!!endDateError}
                  helperText={endDateError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: { xs: '100%', sm: '50%', md: '100%', lg: '100%' },
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="order-status-autocomplete"
                  options={StatusOPtion}
                  getOptionLabel={(option) => option.label}
                  value={orderStatus || null}  // Ensure value is an object with `label` and `value`
                  onChange={(event, newValue) => {
                    setorderStatus(newValue);
                    setFormData({ ...formData, orderStatus: newValue ? newValue.value : null });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Order Status"
                      variant="outlined"
                      inputProps={{ ...params.inputProps, style: inputTextStyle }}
                    />
                  )}
                  PaperComponent={CustomPaperComponent}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="worker-status-autocomplete"
                  options={workerStatus}
                  getOptionLabel={(option) => option.label}
                  value={WorkerStatus || null}  // Ensure value is an object with `label` and `value`
                  onChange={(event, newValue) => {
                    setWorkerStatus(newValue);
                    setFormData({ ...formData, workerStatus: newValue ? newValue.value : null });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Workers Status"
                      variant="outlined"
                      inputProps={{ ...params.inputProps, style: inputTextStyle }}
                    />
                  )}
                  PaperComponent={CustomPaperComponent}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" className={globalClasses.dashbordBtn}>
              Apply
            </Button>
            <Button className={globalClasses.modalResetBtn} onClick={handleReset}>
              Reset
            </Button>
            <Button className={globalClasses.modalCancleBtn} onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AllOrderFilter;
