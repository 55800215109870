import React, { useState } from 'react';
import Header from '../components/Headers/Header';
import useGlobalStyles from '../utils/globalStyles';
import { Box, Typography, Container, Grid, Switch } from '@mui/material';
import VendorStat from '../assets/img/VendorStat.png';
import { workerdashboard, workeravailable } from '../apollo';
import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import { withTranslation } from 'react-i18next';
import CustomLoader from '../components/Loader/CustomLoader';

const UPDATE_WORKER_AVAILABILITY = gql`
  ${workeravailable}
`;

const WORKERDASHBOARD = gql`
  ${workerdashboard}
`;

const BillingAdmin = (props) => {
  const globalClasses = useGlobalStyles();
  const [isAvailable, setIsAvailable] = useState(false); // Default state
  const client = useApolloClient();
  const [updateWorkerAvailability] = useMutation(UPDATE_WORKER_AVAILABILITY);
  const { data, loading, error } = useQuery(WORKERDASHBOARD, {
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });
  if (loading) return <LinearProgress />; // Show a loading indicator
  if (error) return <p>Error: {error.message}</p>;
  const { getWorkerDashBoard } = data;
  const { assignedOrdersCount, completedOrdersCount, inProgressOrdersCount } = getWorkerDashBoard;
  localStorage.setItem("assignedOrdersCount", assignedOrdersCount)
  localStorage.setItem("completedOrdersCount", completedOrdersCount)
  localStorage.setItem("inProgressOrdersCount", inProgressOrdersCount)
  const sty = {
    fontSize: 35,
    fontWeight: 'bold',
    // color: theme.palette.secondary.lightest,
    color: "#0b3978",
    textAlign: 'center'
  };
  const switchColor = isAvailable ? 'success' : 'error'; // Green for available, red for unavailable
  const textStyling = isAvailable ? { color: 'green' } : { color: 'red' }; // Text color based on availability
  const handleSwitchChange = async () => {
    const availableValue = !isAvailable; // Toggle state
    setIsAvailable(availableValue);
    try {
      await updateWorkerAvailability({
        variables: { available: availableValue },
      });
    } catch (err) {
      // Handle error if needed
    }
  };
  if (!data || !data.getWorkerDashBoard) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CustomLoader />
      </div>
    );
  }
  return (
    <>
      <Header />
      <Container fluid className={globalClasses.flex_admin_dashboard}>
        <Grid container spacing={2} sx={{
          marginTop:"1rem",
        }}>
          {/* Left side */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                borderRadius: 5,
                bgcolor: 'common.white',
                width: '80%',
                mb: 3
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                <Typography sx={headSty} >
                  Assigned Orders  
                </Typography>
                <Typography sx={sty}>
                  {assignedOrdersCount ? assignedOrdersCount : '...'} Orders
                </Typography>
              </div>
              <div>
                <img
                  src={VendorStat}
                  alt="stat"
                  width={30}
                  height={40}
                />
              </div>
            </Box>
          </Grid>

          {/* Right side */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {/* Order Pending */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderRadius: 5,
                    bgcolor: 'common.white',
                    width: '80%',
                    mb: 3
                  }}
                >
                  <div style={{ marginBottom: '10px' }}>
                    <Typography sx={headSty}>In Progress </Typography>
                  </div>
                  <div>
                    <Typography sx={sty}>
                      {inProgressOrdersCount ? inProgressOrdersCount : '...'}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderRadius: 5,
                    bgcolor: 'common.white',
                    width: '80%',
                    mb: 3
                  }}
                >
                  <div style={{ marginBottom: '10px' }}>
                    <Typography sx={headSty}>Order Completed</Typography>
                  </div>
                  <div>
                    <Typography sx={sty}>
                      {completedOrdersCount ? completedOrdersCount : '...'}
                    </Typography>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Switch */}
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Switch
                    checked={isAvailable}
                    onChange={handleSwitchChange}
                    color={switchColor} // Set the color based on availability
                  />
                  <Typography variant="body1" style={{ ...textStyling, marginTop: '0.4rem' }}>
                    {isAvailable ? 'Available' : 'Not Available'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

    </>
  )
}
const headSty = { fontSize: 15, fontWeight: 'bold' }

export default withTranslation()(BillingAdmin)
