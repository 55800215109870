/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useQuery, gql, useApolloClient, useLazyQuery } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import CustomLoader from '../components/Loader/CustomLoader'
// core components
import Header from '../components/Headers/Header'
import { getallorder, getorderFilter } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { Container, Button, useTheme, Typography, Box, Badge, } from '@mui/material'
import { customStyles } from '../utils/tableStyleFOrAllOrders'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'
import { Link } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import AllOrderFilter from '../utils/fIlters/AllOrderFilter'

const GET_ALL_ORDER = gql`
  ${getallorder}
`
const GET_ALL_ORDER_filter = gql`
  ${getorderFilter}
`


const Restaurants = props => {
  const { t } = props;
  const theme = useTheme();
  const client = useApolloClient();
  const globalClasses = useGlobalStyles()
  const userType = JSON.parse(localStorage.getItem('user-enatega')).userType
  const [page, setPage] = useState(
    1
  );
  var [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  var [FilterPaload, setFilterPaload] = useState({})
  var [DefaultValue, setDefaultValue] = useState({})
  const [badgeContent, setbadgeContent] = useState(false);
  const [reload, setReload] = useState(false)

  const [refetchfilterData] = useLazyQuery(GET_ALL_ORDER_filter, {
    fetchPolicy: 'no-cache',

    onCompleted: (resultData) => {
      if (resultData && resultData.getAllOrdersByFilter) {
        tableData = resultData.getAllOrdersByFilter.orders || []
        setTableData(tableData);
        setTotalRows(resultData.getAllOrdersByFilter.totalCount || 0);
        CloseFilter()
      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },

  });

  const { data, loading: loadingQuery, refetch } = useQuery(GET_ALL_ORDER, {
    fetchPolicy: 'network-only',
    variables: {
      limit: perPage,
      skip: (page - 1) * perPage,
    },
    onCompleted: (data) => {
      if (data && data.getAllOrders) {
        tableData = data.getAllOrders.orders || []
        setTableData(tableData);
        setTotalRows(data.getAllOrders.totalCount || 0);
      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });
  useEffect(() => {
    if (data) {
      setTotalRows(data.getAllOrders.totalCount);
      tableData = data.getAllOrders.orders
      setTableData(tableData)
    }
  }, [data, reload]);
  // Update the query variables when the page value changes
  useEffect(() => {

    if(badgeContent === true){

      refetchfilterData({
        variables:  {
          filterInput: {
            endDate: DefaultValue.endDate,
            lsName: DefaultValue.lsName,
            orderStatus: DefaultValue.orderStatus,
            startDate: DefaultValue.startDate,
            workerEmail: DefaultValue.workerEmail,
            workerStatus: DefaultValue.workerStatus,
          },
          limit: perPage,
          skip: (page - 1) * perPage,
        },
      })
    }
    else  {
      refetch({
        variables: {
          limit: perPage,
          skip: (page - 1) * perPage,
        },
      });
    }
 
  }, [page, perPage
    // , refetch
  ]);

  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)
  };
  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }
  const getWorkerStatus = (status) => {
    const statusMap = {
      0: 'Not Started',
      1: 'Pp Assigned',
      2: 'Pp In Progress',
      3: 'Pp Completed',
      4: 'Billing Assigned',
      5: 'Billing In Progress',
      6: 'Billing Completed',
      7: 'Rider Assign Manual',
      8: "Rider Assigned",
      9: 'Rider Out For Delivery',
      10: 'Rider Delivered'
    };
    return statusMap[status] || 'N/A';
  };

  function CheckOrderStatus(orderStatusCode) {

    if (orderStatusCode === 0) {
      return "Initiated"
    }
    else if (orderStatusCode === 1) {
      return "Confirmed"
    }
    else if (orderStatusCode === 2) {
      return "Packing"
    }
    else if (orderStatusCode === 3) {
      return "Order billing"
    }
    else if (orderStatusCode === 4) {
      return "Ready for delivery"
    }
    else if (orderStatusCode === 5) {
      return "Rider Assigned"
    }
    else if (orderStatusCode === 6) {
      return "Out for delivery"
    }
    else if (orderStatusCode === 7) {
      return "Delivered"
    }
    else if (orderStatusCode === 8) {
      return "Cancelled"
    }
    else if (orderStatusCode === 9) {
      return "Out of stock"
    }
    else if (orderStatusCode === 10) {
      return "Invalid delivery address"
    }
    else if (orderStatusCode === 12) {
      return "No Items Cart"
    }
    else {
      return "Failed"
    }

  }
  function getOrderStatusColor(orderStatusCode) {
    switch (orderStatusCode) {
      case 0: return "grey"; // Initiated
      case 1: return "blue"; // Confirmed
      case 2: return "orange"; // Packing
      case 3: return "purple"; // Order billing
      case 4: return "darkblue"; // Ready for delivery
      case 5: return "teal"; // Rider Assigned
      case 6: return "green"; // Out for delivery
      case 7: return "lightgreen"; // Delivered
      case 8: return "red"; // Cancelled
      case 9: return "darkred"; // Out of stock
      case 10: return "brown"; // Invalid delivery address
      case 12: return "yellow"; // No Items Cart
      default: return "black"; // Failed
    }
  }

  const getOrderWorkerEmail = (orderState) => {
    switch (orderState.workerStatus) {
      case 1:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "NA"
      case 2:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "NA"
      case 3:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "NA"
      case 4:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "NA";
      case 5:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "NA";
      case 6:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "NA";
      case 7:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "NA";
      case 8:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "NA";
      case 9:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "NA";
      default:
        return 'No Worker Assigned';
    }
  };

  const columns = [
    {
      name: 'Order ID',
      selector: '_id', // Assuming "_id" is the field containing the order ID
      cell: (row) => <>{row._id.slice(-5)}</>, // Display only the last 5 characters of the Order ID
      style: { cursor: 'pointer' },
    },
    {
      name: 'Order Date',
      selector: 'orderDate',
      cell: (row) => <>{new Date(row.orderDate).toLocaleDateString()}</>, // Show only the date part
      style: { cursor: 'pointer', minWidth: "110px" },
    },
    {
      name: 'Delivery Date',
      selector: 'deliveryDate',
      cell: (row) => {
        // Extract the date part from the delivery date string
        const datePart = row.deliveryDate.split(',')[0];
        // Parse the date part and format it
        const [day, month, year] = datePart.split(' ');
        const formattedDate = new Date(`${month} ${day.replace(/\D/g, '')}, ${year}`).toLocaleDateString();
        return <>{formattedDate}</>;
      },
      style: { cursor: 'pointer', minWidth: "110px" },
    },

    {
      name: 'Amount',
      selector: 'orderAmount',
      style: { cursor: 'pointer' },
    },
    {
      name: 'Order Status',
      selector: 'orderStatus',
      cell: (row) => (
        <Typography style={{
          color: getOrderStatusColor(row.orderStatus), fontWeight: 600, fontFamily: ['Euclid Circular A', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
          fontOpticalSizing: "auto", fontSize: '13px',
        }}>
          {CheckOrderStatus(row.orderStatus)}
        </Typography>
      ),
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Worker Status',
      selector: 'workerEmail',
      cell: (row) => <>{getWorkerStatus(row.orderState.workerStatus)}</>,
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Worker Email',
      selector: 'workerEmail',
      cell: (row) => {
        const workerEmail = getOrderWorkerEmail(row.orderState);
        const handleClick = () => {
          localStorage.setItem('workerEmail', workerEmail);
        };
        return userType === 1 ? (
          <Link to={`/cs_admin/workerdetails`} onClick={handleClick}>
            {workerEmail}
          </Link>
        ) : (
          <>{workerEmail}</>
        );
      },
      style: { cursor: 'pointer', minWidth: "250px" },
    },
    {
      name: "Delivery Address",
      sortable: false,
      cell: row => row.deliveryAddress ? row.deliveryAddress.deliveryAddress : "NA", // Safely access delivery address
      style: { cursor: 'pointer', minWidth: "300px", textAlign: 'left' }, // Set text alignment to left
    },
    ...(userType !== 2
      ? [
        {
          name: 'Local Store',
          selector: 'localStore',
          cell: row => <>{row.localStore.join(', ')}</>,
          style: { cursor: 'pointer' },
        },
      ]
      : []),
    {
      name: t('Action'),
      cell: (row) => <>{actionButtons(row)}</>,
    },

  ];

 

  const filterData = (filterInput) => {
    setDefaultValue(filterInput)
    FilterPaload = {
      filterInput: {
        endDate: filterInput.endDate,
        lsName: filterInput.lsName,
        orderStatus: filterInput.orderStatus,
        startDate: filterInput.startDate,
        workerEmail: filterInput.workerEmail,
        workerStatus: filterInput.workerStatus,
      },
      limit: perPage,
      skip: (page - 1) * perPage,
    }
    //  setFilterPaload(filterInput)
    refetchfilterData({
      variables: FilterPaload,
    })
    setbadgeContent(true)
  }

  const actionButtons = row => {

    return (
      <>
        <Button
          size="20px"
          variant="contained"
          sx={{
            fontWeight: 'bold',
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.common.white,
            padding: 0,
            height: '15px',
            fontSize: '7px',
            '&:hover': {
              border: '2px solid #637BFE',
              color: theme.palette.warning.dark,
              backgroundColor: "white"
            }
          }}
          onClick={e => {
            e.preventDefault()
            localStorage.setItem('orderDetails', JSON.stringify(row))
            const userType = JSON.parse(localStorage.getItem('user-enatega')).userType;
            let routePath = '';
            switch (userType) {
              case 0:
                routePath = '/super_admin/OrderDetails';
                break;
              case 1:
                routePath = '/cs_admin/OrderDetails';
                break;
              case 2:
                routePath = '/local_admin/OrderDetails';
                break;
              default:
                routePath = '/super_admin/OrderDetails'; // Default to super admin if userType is not defined
                break;
            }
            props.history.push({
              pathname: routePath,
              state: { id: row._id }
            });
          }}
        >
          {t('ViewDetails')}
        </Button>
      </>
    )
  }
  const [OpenFilter, setOpenFilter] = useState(false)
  const handleOpenFilter = () => {
    setOpenFilter(true)
  }
  const CloseFilter = () => {
    setOpenFilter(false)
  }
  const handleResetFilters = () => {
    setFilterPaload({});
    setDefaultValue({});
    setPage(1);
    // refetch({
    //   variables: {
    //     limit: perPage,
    //     skip: (page - 1) * perPage,
    //   },
    // });
    setReload(!reload)
    setbadgeContent(false)
  };
  return (
    <>
      {OpenFilter &&
        <AllOrderFilter open={OpenFilter} close={CloseFilter} filterData={filterData} DefaultValue={DefaultValue} handleResetFilters={handleResetFilters} />
      }
      <Header />
      <Container className={globalClasses.flex} fluid>
        {loadingQuery ? (
          <CustomLoader />
        ) :
          <DataTable
            customStyles={customStyles}
            subHeaderComponent={
              <Box style={{ marginTop: -40 }}>
                {badgeContent === true ? (
                  <Badge
                    badgeContent={''}
                    color="success"
                    variant="dot"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Button
                      onClick={handleOpenFilter}
                    >

                      <TuneIcon />
                    </Button>
                  </Badge>
                ) : <Button
                  onClick={handleOpenFilter}
                >

                  <TuneIcon />
                </Button>}
              </Box>
            }
            subHeader={true}
            title={<TableHeader title={t('All Orders')} />}
            columns={columns}
            data={tableData || []}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            sortFunction={customSort}
          />
        }
      </Container>
    </>
  )
}
export default withTranslation()(Restaurants)
