import React, { useEffect } from 'react'

import ReactDOM from 'react-dom'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
  createHttpLink,
  Observable,
} from '@apollo/client'
import 'firebase/messaging'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ConfigurableValues from './config/constants'
import { ConfigurationProvider } from './context/Configuration'
import App from './app'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import theme from './utils/theme'


function Main() {
  const {
    SENTRY_DSN,
    SERVER_URL,
  } = ConfigurableValues()
  useEffect(() => {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1
    })
  }, [])

  const cache = new InMemoryCache()
  const httpLink = createHttpLink({
    uri: `${SERVER_URL}/graphql/`
    
  })
  
  const request = async operation => {
    const data = localStorage.getItem('user-enatega')
    let token = "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InZpc2l0b3ItYXBwbGljYXRpb24tc2VydmVyLTIwMjEwMjIifQ.eyJwaWQiOiI1ZDBmNGY2YjM2ZWFiOTcyMTExOGM4NGUiLCJ2aWQiOiI1ZDBmNGY2YjM2ZWFiOTcyMTExOGM4NGUtSzhrSUo1M0pnVXNIaU5CZmtkVmY3Iiwic2lkIjoiNjVmNmQ2NDViZmNkOTM3OTk3ODUxYWMzIiwiaWF0IjoxNzEwNjc1NTI1LCJleHAiOjE3MTA2NzczMjUsImp0aSI6Ing5RXhOY3NNSmxaQ0hhMVpaWE9MQiJ9.wBaZaChQCJ7SWP7J4uWHIxWPBdoGGtTnvQcbksQM2tHpoW9ikP7lSXaP-IBMH6igduqII99btsSv3ye0YpJrYQ"
    if (data) {
      token = JSON.parse(data).jwtToken
    }
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })
  }

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle
        Promise.resolve(operation)
          .then(oper => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            })
          })
          .catch(observer.error.bind(observer))

        return () => {
          if (handle) handle.unsubscribe()
        }
      })
  )

  const client = new ApolloClient({
    link: concat(ApolloLink.from([
      // terminatingLink, to remove subscription message (27-6-24)
      requestLink]), httpLink),
    cache,
    resolvers: {},
    fetchPolicy: 'no-cache',
    connectToDevTools: true
  })

  return (
    <ApolloProvider client={client}>
      <ConfigurationProvider>

        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
          
              <App />
           
          </ThemeProvider>
        </StyledEngineProvider>
      </ConfigurationProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(<Main />, document.getElementById('root'))
