import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { getalllocalstore, deletelocalstore} from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableStyleForSore'
import { Icon } from '@iconify/react';
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper,Box,Snackbar,Alert
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../components/TableHeader'
import CreateLocalStore from '../components/Vendor/CreateLocalStore'

const GET_ALL_LOCAL_STORE = gql`
${getalllocalstore}
`
const DELETE_LOCAL_STORE = gql`
  ${deletelocalstore}
`
const MAnageLocalStore = props => {
  const { t } = props;
  const [editModal, setEditModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const golbalClasses = useGlobalStyles()
  const [openSnackk, setopenSnackk] =useState(false);
    // Define a state to hold your query data
    const client = useApolloClient();
    let [error, errorSetter] = useState('');
    // const [error, errorSetter] = useState('');
    const [success, successSetter] = useState('');
  const userType =JSON.parse( localStorage.getItem('user-enatega'))
  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };

  const openaAddVendorModal = () => {
    setAddVendorModal(true);
  };
  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };
 const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_ALL_LOCAL_STORE ,{
        variables:{
            adminID: userType._id
        },
        onError: error => {
          const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
    
          if (isForbiddenError) {
            localStorage.removeItem('user-enatega');
            localStorage.removeItem('restaurant_id');
            client.clearStore();
            props.history.push('/auth/login');
          }
        },
        
    }
  )

  useEffect(() => {
    // Manually trigger refetch when the component mounts
    refetch();
  }, []);
  // const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
  //   GET_VENDORS
  // )



  const [mutate] = useMutation(DELETE_LOCAL_STORE, {
    refetchQueries: [{ refetchQueries: GET_ALL_LOCAL_STORE }],
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        errorSetter('An error occurred while deleting the Local Store.');
        setTimeout(hideAlert, 3000);
        setopenSnackk(true)
      }
      if (networkError) {
        errorSetter(networkError.message);
        setTimeout(hideAlert, 3000);
      }
    },
    onCompleted: () => {
      const message = 'Local Store Deleted Successfully';
   
      errorSetter('');
      props.refetch()
      successSetter(message);
      setTimeout(hideAlert, 3000);
      setopenSnackk(true)
    }
  })
  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const filtered = data && data.getAllLocalStores
       const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  useEffect(()=>{
  },[data])

  
  const columns = [
    {
      name: "Sl No.",
      cell: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Store Name",
      sortable: true,
      selector: 'name',
      style: { cursor: 'pointer',minWidth:"200px" },
    },
    {
      name: "Address",
      sortable: true,
      selector: 'address'    ,
      style: { cursor: 'pointer',minWidth:"200px" },
    },
    {
      name: "City",
      sortable: true,
      selector: 'city'
    },
    {
      name: "Admin Name",
      sortable: true  ,
      cell: row => (
        row.admins.length > 0 ? (
          <>
          {row.admins[0].name}
          </>
            
       
        ) : "N/A" // Display "N/A" if admins array is empty
      )
    },
    {
      name: "Admin Email",
      sortable: true, 
      style: { cursor: 'pointer',minWidth:"200px" },
      selector: row => (row.admins.length > 0 ? row.admins[0].email : "N/A")
    },
    
    {
      name: "Action",
      cell: row => <>{actionButtons(row)}</>
    }
  ];
  

  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>
    
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                  }, 5000)
                  // uncomment this for paud version
                  toggleModal(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
              <MenuItem
              disabled
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                  }, 5000)
                  // uncomment this for paud version
                  mutate({ variables: { storeId: row._id } });
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
    
 {/* {loadingQuery && <CustomLoader />} */}
      <Container className={golbalClasses.flex}>
      <Box sx={{ textAlign: 'right', mb: 3 }}>
  <Button
 
    className={golbalClasses.dashbordBtn}
    onClick={openaAddVendorModal}
  >
      <Icon icon="ic:twotone-plus"  style={{color: "white"}} />  {t('Add New Local Store')}
  </Button>
</Box>
     { addVendorModal ?  <Grid container>
          {/* <Grid item xs={12} order={{ xs: 2, lg: 1 }}> */}
            <Grid
              sx={{ display: { xs: 'none', lg: 'block' } }}
              item
              mt={5}
              ml={-2}
              order={{ xs: 1, lg: 2 }}
            >
              <Modal
                open={addVendorModal}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClose={closeAddVendorModal}
              >
                <CreateLocalStore onClose={closeAddVendorModal}  refetch={refetch}/>
              </Modal>
            </Grid>
          {/* </Grid> */}
        </Grid> :null
    }
        { errorQuery && errorQuery? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
         
              title={<TableHeader title={t('Manage Local Store')} />}
              // title={<TableHeader title={t('Vendors')} />}
            columns={columns}
            data={filtered}
            pagination
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            sortFunction={customSort}
            // defaultSortField="email"
            customStyles={customStyles}
            // selectableRows
          />
        )}
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <CreateLocalStore vendor={vendors} editModal={editModal} onClose={closeEditModal} refetch={refetch} />
        </Modal>
        <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
      </Container>
    </>
  )
}

export default withTranslation()(MAnageLocalStore)
