
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { wokerOrderstatusUpdate, getOrderDetailsforworker, RetryRefund } from '../apollo'
import DataTable from 'react-data-table-component'
import { customStyles } from '../utils/orderTable'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper, Box,
} from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@material-ui/lab/Alert'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import TableHeader from '../components/TableHeader'
import useStyles from '../components/Section/styles';
import EditOrderByWorker from '../components/EditOrder/EditOrderByWorker'
import moment from 'moment'; // Import moment library
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Icon } from '@iconify/react';
import Divider from '@mui/material/Divider';
import { MdOutlineDirectionsBike, MdOutlineCheckCircle, MdOutlineCancel, MdErrorOutline, MdLocalShipping, MdDone, MdAssignment } from 'react-icons/md';
import {
  MdPersonAdd,
  MdPerson,
  MdPersonOutline,
  MdPersonPin,
  MdHourglassEmpty,
  MdAccessTime,
} from 'react-icons/md';
import ConfigurableValues from '../config/constants'
import { SetOrderItemChecked } from '../apollo/mutations'
import { Checkbox } from '@mui/material';



const GET_ORDERDETAILS = gql`
  ${getOrderDetailsforworker}
`

const UPDATEWORKERORDERSTATUS = gql`
  ${wokerOrderstatusUpdate}
`
const SET_ORDER_ITEM_CHECKED = gql`
   ${SetOrderItemChecked}
`
const RETRY_REFUND = gql`
${RetryRefund}`
const PackageOrderDetails = props => {
  const history = useHistory();
  const {
    SERVER_URL,
  } = ConfigurableValues()
  const { t, } = props;
  const [editModal, setEditModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const workerType = JSON.parse(localStorage.getItem('user-enatega')).workerType
  const orderDetails = JSON.parse(localStorage.getItem("OrderData"))
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  var [openSnackk, setopenSnackk] = useState(false);
  const golbalClasses = useGlobalStyles()
  const client = useApolloClient();
  const [sellingPrice, setSellingPrice] = useState(0)
  const [setOrderItemChecked] = useMutation(SET_ORDER_ITEM_CHECKED);
  const [updateOrderStatus] = useMutation(
    UPDATEWORKERORDERSTATUS, {
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  );
  const formatDate = date => {
    const formattedDate = moment(date).format('Do MMMM YYYY, hh:mm a'); // Assuming the end time is 2 hours ahead
    return `${formattedDate}`;
  };



  const handleBackClick = () => {
    if (workerType === 0) {
      history.push('/package_admin/orderlist');
    } else if (workerType === 1) {
      history.push('/billing_admin/billinglist');
    }
  };
  const handleReadyButtonClickAccept = async (row) => {
    try {
      let status;
      if (workerType === 0) {
        status = 0;
      } else if (workerType === 1) {
        status = 0;
      } else {
        // Handle other workerType values if needed
        status = 1; // Default status value
      }

      const { data, error } = await updateOrderStatus({
        variables: {
          orderId: orderDetails._id,
          status: status
        }
      });
      if (data) {
        const message = "Order Accepted."
        errorSetter('');
        successSetter(message);
        setTimeout(hideAlert, 3000);
        setopenSnackk(true)
        refetch()
      }
      if (error) {
        errorSetter("Something Went Wrong");
        // successSetter(message);
        setTimeout(hideAlert, 3000);
        setopenSnackk(true)
      }
    } catch (error) {
    }
  };
  const handleReadyButtonClick = async (row) => {
    try {
      let status;
      if (workerType === 0) {
        status = 1;
      } else if (workerType === 1) {
        status = 1;
      } else {
        // Handle other workerType values if needed
        status = 1; // Default status value
      }
      const { data, error } = await updateOrderStatus({
        variables: {
          orderId: orderDetails._id,
          status: status
        }
      });
      if (data) {

        const message = t(' Order Successfully Completed');
        errorSetter('');
        successSetter(message);
        refetch()
        openSnackk = true
        setopenSnackk(openSnackk)
        setTimeout(hideAlert, 3000);
        if (workerType === 0) {
          history.push('/package_admin/orderList');
        }

      } else if (!data.updateWorkerOrderStatus) {
        const message = t('Unable To Complete The Order!');

        errorSetter(message);
        refetch()
        openSnackk = true
        setopenSnackk(openSnackk)
        setTimeout(hideAlert, 3000);
      }

      if (error) {
        const message = t('Something Went Wrong!');
        errorSetter(message);
        successSetter('');
        refetch()
        openSnackk = true
        setopenSnackk(openSnackk)
        setTimeout(hideAlert, 3000);
      }
    } catch (error) {
    }
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    openSnackk = false
    setopenSnackk(openSnackk)
  };

  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };

  const { loading: loadingQuery, data, refetch } = useQuery(
    GET_ORDERDETAILS, {
    variables: {
      orderId: orderDetails._id
    }
  }
  )
  const [retryRefund, { data: refundData }] = useMutation(RETRY_REFUND, {
    onCompleted: (refundData) => {
      if (refundData) {
        const message = t('Refunding  Updated Successfully')
        errorSetter('');
        successSetter(message);
        setTimeout(hideAlert, 3000);
        setopenSnackk(true)
      } else {

      }
      refetch()
    },
    onError: (graphQLErrors, networkError) => {
      successSetter('');
      if (graphQLErrors) {
        // const errorMessage = graphQLErrors[0].message;
        const errorMessage = "Something Went Wrong "
        error = graphQLErrors[0].message
        errorSetter(errorMessage);
        setopenSnackk(true)
      }

      else if (networkError) errorSetter(networkError.result.errors[0].message);
      else errorSetter('Something went wrong!');
      setTimeout(hideAlert, 3000);
      setopenSnackk(true)
    }
  });

  const handleRetryClick = () => {
    const orderId = orderDetails._id
    retryRefund({
      variables: { orderId }
    });
  };


  const filtered =
    data &&
    data.getOrderDetailsById.items
  const payMethodVar = data && data.getOrderDetailsById.paymentMethod

  const customerType = data ? data.getOrderDetailsById.customer.customerType : 'N/A';

  const billDetails = data ? data.getOrderDetailsById.billDetails : null
  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
    let sellingPrice = getSellingPrice(vendor.prices, customerType)
    setSellingPrice(sellingPrice)
  }
  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const getSellingPrice = (prices, customerType) => {
    const price = prices.find(price => price.customerType === customerType);
    return price ? price.sellingPrice : 'N/A';
  };
  const handleCheckBoxToggle = async (item) => {
   
  
    const payload = {
      orderId: orderDetails._id, // Assuming you have order details available
      itemId: `${item.productId}_${item.skuId}`,
      checked: !item.packingChecked
    };
  
    try {
      const response = await setOrderItemChecked({ variables: payload });
      if (response) {
        refetch()
        // Successfully updated, you can update the UI accordingly
        console.log('Packing status updated');
      }
    } catch (error) {
      console.error('Error updating packing status', error);
    }
  };
  const columns = [
    {
      name: "Sl No",
      selector: (row, index) => index + 1
    },

    {
      name: "Name",
      selector: "name",
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Image",
      cell: row => <img src={row.image} alt="Product" style={{ width: 50 }} />
    },
    {
      name: "Quantity",
      selector: "quantity"
    },
    {
      name: "MRP",
      selector: "mrp"
    },
    {
      name: "Selling Price",
      cell: (row) => getSellingPrice(row.prices, customerType)
    },
    ...(workerType === 0 && data && data.getOrderDetailsById.orderState.status === 2 ? [ // Conditionally show for package admin
      {
        name: "Packing Checked",
        cell: (row) => (
          <Checkbox
            checked={row.packingChecked}
            onChange={() => handleCheckBoxToggle(row, 'packingChecked')} // Differentiate between packing and billing
            color="primary"
          />
        )
      }
    ] : []), // If workerType !== 0, don't include the column
    ...(workerType === 1 &&   data && data.getOrderDetailsById.orderState.status === 3 ? [ // Conditionally show for billing admin
      {
        name: "Billing Checked",
        cell: (row) => (
          <Checkbox
            checked={row.billingChecked}
            onChange={() => handleCheckBoxToggle(row, 'billingChecked')} // Differentiate between packing and billing
            color="secondary"
          />
        )
      }
    ] : []), 
    {
      name: "Action",
      cell: row => <>{actionButtons(row)}</>
    }
  ];
  const columnsPDF = [
    {
      name: "Name",
      selector: "name",

      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Description",
      selector: "description",

      style: { cursor: 'pointer', minWidth: "300px" },
    },

    {
      name: "Quantity",
      selector: "quantity"
    },
    {
      name: "MRP",
      selector: "mrp"
    },
    {
      name: "Selling Price",
      cell: (row) => getSellingPrice(row.prices, customerType)
    },

  ];

  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              {data && data.getOrderDetailsById.orderState.workerStatus === 2 || data && data.getOrderDetailsById.orderState.workerStatus === 5 ?
                <MenuItem
                  onClick={e => {
                    e.preventDefault()
                    setTimeout(() => {
                    }, 5000)
                    // uncomment this for paud version
                    toggleModal(row);
                    localStorage.setItem('orderId', orderDetails._id)
                  }}
                  style={{ height: 25 }}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" style={{ color: 'green' }} />
                  </ListItemIcon>
                  <Typography color="green">{t('Edit')}</Typography>
                </MenuItem>
                :
                <div onClick={e => {
                  e.preventDefault()
                  openSnackk = true
                  setopenSnackk(openSnackk)
                  errorSetter("Please Accept the Order Before Edit.");
                  // successSetter(message);
                  setTimeout(hideAlert, 3000);
                }}>
                  <MenuItem
                    disabled

                    style={{ height: 25 }}>
                    <ListItemIcon>
                      <EditIcon fontSize="small" style={{ color: 'green' }} />
                    </ListItemIcon>
                    <Typography color="green">{t('Edit')}</Typography>
                  </MenuItem>
                </div>
              }
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  const getOrderStatusWord = (statusNumber) => {
    const statusMap = {
      0: { text: "INITIATED", icon: <MdAssignment style={{ color: '#66a1ff' }} /> },
      1: { text: "CONFIRMED", icon: <MdDone style={{ color: '#66a1ff' }} /> },
      2: { text: "PACKING", icon: <MdOutlineCheckCircle style={{ color: '#66a1ff' }} /> },
      3: { text: "BILLING", icon: <MdAssignment style={{ color: '#66a1ff' }} /> },
      4: { text: "READY FOR DELIVERY", icon: <MdLocalShipping style={{ color: '#66a1ff' }} /> },
      5: { text: "RIDER ASSIGNED", icon: <MdOutlineDirectionsBike style={{ color: '#66a1ff' }} /> },
      6: { text: "OUT FOR DELIVERY", icon: <MdLocalShipping style={{ color: '#66a1ff' }} /> },
      7: { text: "DELIVERED", icon: <MdDone style={{ color: '#66a1ff' }} /> },
      8: { text: "CANCELLED", icon: <MdOutlineCancel style={{ color: '#66a1ff' }} /> },
      9: { text: "FAILED OUT OF STOCK", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
      10: { text: "FAILED INVALID DELIVERY ADDRESS", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
      11: { text: "NO ITEM CART", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
      12: { text: "NO ITEM CART", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
    };

    const status = statusMap[statusNumber] || { text: "N/A", icon: null };
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {status.icon}
        <span style={{ marginLeft: '8px' }}>{status.text}</span>
      </div>
    );
  };

  const getWorkerStatus = (status) => {
    const statusMap = {
      0: { text: 'Not Started', icon: <MdHourglassEmpty style={{ color: '#66a1ff' }} /> },
      1: { text: 'Pp Assigned', icon: <MdPersonAdd style={{ color: '#66a1ff' }} /> },
      2: { text: 'Pp In Progress', icon: <MdPerson style={{ color: '#66a1ff' }} /> },
      3: { text: 'Pp Completed', icon: <MdDone style={{ color: '#66a1ff' }} /> },
      4: { text: 'Billing Assigned', icon: <MdPersonPin style={{ color: '#66a1ff' }} /> },
      5: { text: 'Billing In Progress', icon: <MdAccessTime style={{ color: '#66a1ff' }} /> },
      6: { text: 'Billing Completed', icon: <MdDone style={{ color: '#66a1ff' }} /> },
      7: { text: 'Rider Assign Manual', icon: <MdPersonOutline style={{ color: '#66a1ff' }} /> },
      8: { text: 'Rider Assigned', icon: <MdOutlineDirectionsBike style={{ color: '#66a1ff' }} /> },
      9: { text: 'Rider Out For Delivery', icon: <MdLocalShipping style={{ color: '#66a1ff' }} /> },
      10: { text: 'Rider Delivered', icon: <MdDone style={{ color: '#66a1ff' }} /> }
    };

    const workerStatus = statusMap[status] || { text: 'N/A', icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> };
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {workerStatus.icon}
        <span style={{ marginLeft: '8px' }}>{workerStatus.text}</span>
      </div>
    );
  };


  const getPaymentMethod = (statusNumber) => {
    const statusMap = {
      0: "COD",
      1: "ONLINE",

    };

    return statusMap[statusNumber] || "N/A";
  };

  const getPaymentStatus = (statusNumber) => {
    const statusMap = {
      0: "PENDING",
      1: "COMPLETED",

    };

    return statusMap[statusNumber] || "N/A";
  };



  const handlePrint = () => {
    const doc = new jsPDF();
    const tableColumn = columnsPDF.map(col => col.name);
    const tableRows = filtered.map(row => [
      row.name,
      row.description,
      row.quantity,
      row.mrp,
      getSellingPrice(row.prices, customerType),
    ]);

    doc.setFontSize(12);
    let currentY = 20;

    // Header
    doc.setFontSize(16);
    doc.text('ORDER DETAILS', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
    doc.setFontSize(12);

    // Order Details
    doc.text(`Order Number: ${orderDetails._id}`, 14, currentY);
    currentY += 6;
    doc.text(`Order Date: ${formatDate(orderDetails.createdAt)}`, 14, currentY);
    currentY += 6;
    doc.text(`Customer Name: ${(data && data.getOrderDetailsById) ? data.getOrderDetailsById.customer.name : 'N/A'}`, 14, currentY);
    currentY += 6;
    doc.text(`Customer Phone: ${(data && data.getOrderDetailsById) ? data.getOrderDetailsById.customer.phone : 'N/A'}`, 14, currentY);
    currentY += 6;
    doc.text(`Item Count: ${(data && data.getOrderDetailsById) ? data.getOrderDetailsById.items.length : 'N/A'}`, 14, currentY);
    currentY += 6;

    // Handle long delivery address
    const address = data && data.getOrderDetailsById && data.getOrderDetailsById.deliveryAddress ?
      `${data.getOrderDetailsById.deliveryAddress.deliveryAddress}, ${data.getOrderDetailsById.deliveryAddress.details}`
      :
      'N/A';
    const wrappedAddress = doc.splitTextToSize(address, 180); // Adjust the width as needed
    doc.text('Customer Address:', 14, currentY);
    currentY += 6;
    doc.text(wrappedAddress, 14, currentY);
    currentY += wrappedAddress.length * 6;

    const customerTypeText = customerType === 0 ? 'Premium' : customerType === 1 ? 'Regular' : 'N/A';
    doc.text(`Customer Type: ${customerTypeText}`, 14, currentY);
    currentY += 6;

    // Payment Details above the table
    doc.text(`Payment Method: ${(data && data.getOrderDetailsById) ? getPaymentMethod(data.getOrderDetailsById.paymentMethod) : 'N/A'}`, 14, currentY);
    currentY += 6;
    doc.text(`Payment Status: ${(data && data.getOrderDetailsById) ? getPaymentStatus(data.getOrderDetailsById.paymentStatus) : 'N/A'}`, 14, currentY);
    currentY += 10;

    // Table of Items
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: currentY,
      theme: 'grid',
    });

    currentY = doc.lastAutoTable.finalY + 10;

    // Billing Details in the right corner
    const rightAlignX = doc.internal.pageSize.getWidth() - 60;

    doc.text(`Order Amount: ${(data && data.getOrderDetailsById) ? data.getOrderDetailsById.orderAmount : 'N/A'}`, rightAlignX, currentY);
    currentY += 6;
    doc.text(`Discount: ${billDetails ? billDetails.couponDiscount : 0}`, rightAlignX, currentY);
    currentY += 6;
    doc.text(`Discounted Price: ${billDetails ? billDetails.totalSaving : 0}`, rightAlignX, currentY);
    currentY += 6;
    doc.text(`Total Price: ${billDetails ? billDetails.grandTotal : 0}`, rightAlignX, currentY);

    doc.save('order-details.pdf');
  };

  const handleViewDetails = async () => {
    const invoiceId = (data && data.getOrderDetailsById) ? data.getOrderDetailsById.invoiceId : null;
    try {
      const response = await fetch(SERVER_URL + '/invoice/download', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ invoiceId }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // Handle the error accordingly, e.g., show a snackbar with an error message
    }
  };
  const getPhonePayRefundStatus = (statusNumber) => {
    const refundStatusMap = {
      0: "Not Applicable",
      1: "Refund Pending",
      2: "Refund Initiated",
      3: "Refund Success",
      4: "Refund Failed",
      5: "Refund Applicable"
    };

    return refundStatusMap[statusNumber] || "N/A";
  };
  const getOrderWorkerEmail = (orderState) => {
    switch (orderState.workerStatus) {
      case 1:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 2:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 3:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 4:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 5:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 6:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 7:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      case 8:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      case 9:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      default:
        return 'No Worker Assigned';
    }
  };
  return (
    <>
      <Header />
      <Container className={globalClasses.flex}>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button

            className={golbalClasses.dashbordBtn}
            onClick={handlePrint}
          >
            <Icon icon="gridicons:print" style={{ color: "white" }} />  {t('Print')}
          </Button>
          <Button className={golbalClasses.dashbordBtn} onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
        <Box container className={classes.container}>
          <Box className={classes.flexRow}>
            <Box
              item
              className={
                classes.heading
              }>
              <Typography
                variant="h6"
                className={
                  classes.textWhite
                }>
                {t('Order Details ')}
              </Typography>
            </Box>

          </Box>
          <Box className={classes.form} style={{ height: "663px" }}>
            <Grid container spacing={1} mt={1} className={classes.section}>
              <Grid container item xs={12} md={6}>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Order ID

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById._id : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Customer Name

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById.customer.name : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Contact Number

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.headingText} style={{ display: 'inline-flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>
                    <Icon icon="fluent:phone-12-regular" width="20" height="20" style={{ color: " #66a1ff" }} />  {(data && data.getOrderDetailsById) ? data.getOrderDetailsById.customer.phone : "N/A"}
                    {/* {data.getOrderDetailsById.customer.phone} */}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Item Count

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>

                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById.items.length : "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6}  >
                  <Typography className={classes.typographyOrder}  >
                    Order Amount

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>
                    <Icon icon="mdi:rupee" width="14" height="14" style={{ color: " black", }} />
                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById.orderAmount : "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Order Date

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>

                    {(data && data.getOrderDetailsById) ? formatDate(data.getOrderDetailsById.orderDate) : "N/A"}

                  </Typography>
                </Grid>

                <Grid item xs={6} md={6}  >
                  <Typography className={classes.typographyOrder}  >
                    Deliver Date

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById.deliveryDate : "N/A"}

                  </Typography>
                </Grid>

              </Grid>

              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4, // Increase thickness

                  }} />
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder}>
                    Payment Information :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    Payment Method : {(data && data.getOrderDetailsById) ? getPaymentMethod(data.getOrderDetailsById.paymentMethod) : "N/A"}
                    <br />
                    Payment Status : {(data && data.getOrderDetailsById) ? getPaymentStatus(data.getOrderDetailsById.paymentStatus) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3} style={{ marginTop: "10px" }}>

                  <Typography className={classes.typographyOrder} >
                    Refund Status :
                  </Typography>

                </Grid>
                <Grid item xs={6} md={6} container alignItems="center">
                  <Typography className={classes.typographyOrder} style={{ marginRight: '8px' }}>
                    {data && data.getOrderDetailsById ? getPhonePayRefundStatus(data.getOrderDetailsById.refundStatus) : "N/A"}
                  </Typography>
                  {data && data.getOrderDetailsById && data.getOrderDetailsById.refundStatus === 4 ?
                    <Box>
                      <Button
                        className={golbalClasses.dashbordBtn}
                        onClick={handleRetryClick}
                      >
                        <Icon icon="pajamas:retry" style={{ color: "white", marginRight: "5px" }} /> {t('Retry')}
                      </Button>
                    </Box> : null}
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Wallet Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.walletInfo ? data.getOrderDetailsById.billDetails.walletInfo.amount != null || undefined ?data.getOrderDetailsById.billDetails.walletInfo.amount : 0 : "N/A"} ({data && data.getOrderDetailsById.billDetails.walletInfo ? data.getOrderDetailsById.billDetails.walletInfo.message !=null ?data.getOrderDetailsById.billDetails.walletInfo.message  : "N/A" : "N/A" })
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                  Nest Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.nestInfo ? data.getOrderDetailsById.billDetails.nestInfo.amount != null || undefined ? data.getOrderDetailsById.billDetails.nestInfo.amount: 0 : "N/A"}(   {data && data.getOrderDetailsById.billDetails.nestInfo ? data.getOrderDetailsById.billDetails.nestInfo.message != null ? data.getOrderDetailsById.billDetails.nestInfo.message:"N/A" : "N/A"}
                    )
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                  Coupon Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.couponInfo ? data.getOrderDetailsById.billDetails.couponInfo.amount != null || undefined ?data.getOrderDetailsById.billDetails.couponInfo.amount : 0 : "N/A"}(   {data && data.getOrderDetailsById.billDetails.couponInfo ? data.getOrderDetailsById.billDetails.couponInfo.message!= null ?  data.getOrderDetailsById.billDetails.couponInfo.message :"N/A" : "N/A"})
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                  Cancelled Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo ? data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo.amount : "N/A"} (                    {data && data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo ? data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo.message  != null ?  data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo.message : "N/A"  : "N/A"})
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4,
                  }} />
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Order Status :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById ? getOrderStatusWord(data.getOrderDetailsById.orderState.status) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Worker Status :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById ? getWorkerStatus(data.getOrderDetailsById.orderState.workerStatus) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Worker Email :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById ? getOrderWorkerEmail(data.getOrderDetailsById.orderState) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4,
                  }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className={classes.typographyOrder} style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Icon icon="mdi:address-marker-outline" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} />
                  Delivery Address
                </Typography>
                <Typography className={classes.typographyOrder}>
                  {data && data.getOrderDetailsById && data.getOrderDetailsById.deliveryAddress
                    ? `${data.getOrderDetailsById.deliveryAddress.deliveryAddress}, ${data.getOrderDetailsById.deliveryAddress.details}`
                    : "N/A"
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>

        </Box>
        <DataTable
          subHeader={true}
          title={<TableHeader title={t('Orders')} />}
          columns={columns}
          data={filtered}
          pagination
          progressPending={loadingQuery}
          progressComponent={<CustomLoader />}
          customStyles={customStyles}
        // selectableRows
        />
        <Grid container >
          <Grid item xs={6} md={6}>
          </Grid>
          <Grid item container spacing={1} xs={12} md={6} style={
            {
              background: "white", marginTop: "10px", borderRadius: 20, padding: "5px"
            }
          }>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "15px" }}>
                <Icon icon="marketeq:price-tag" width="24" height="24" style={{ marginRight: '8px' }} /> MRP
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.itemsMrp : 0}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="teenyicons:discount-outline" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Discount
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                - <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black" }} />
                {billDetails ? billDetails.totalSaving : 0}
              </Typography>
            </Grid>
            { billDetails && billDetails.couponInfo && billDetails.couponInfo.applied ?  
        
        <>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="streamline:discount-percent-coupon" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Coupon 
              </Typography>
            </Grid>
            
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.couponInfo?billDetails.couponInfo.amount : 0 : 0}
              </Typography>
            </Grid>
            </>: null}
            { billDetails && billDetails.walletInfo && billDetails.walletInfo.applied ?  
        
        <>
        <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="streamline:discount-percent-coupon" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Wallet 
              </Typography>
            </Grid>
            
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.walletInfo?billDetails.walletInfo.amount : 0 : 0}
              </Typography>
            </Grid>
            </>: null}
            { billDetails && billDetails.nestInfo && billDetails.nestInfo.applied ?  
        
        <>
        <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="streamline:discount-percent-coupon" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Neft 
              </Typography>
            </Grid>
            
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.walletInfo?billDetails.nestInfo.amount : 0 : 0}
              </Typography>
            </Grid>
            </>: null}
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="ri:e-bike-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Delivery Fee
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.deliveryCharge : 0}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="tdesign:undertake-transaction" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Handling Fee
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.handlingCharge : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
              <Divider variant="middle"
                sx={{
                  borderBottomWidth: 4,
                }} />
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <Icon icon="fluent:money-hand-16-regular" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Grand Total
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ fontWeight: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon icon="mdi:rupee" width="20" height="20" style={{ color: "black" }} />
                {billDetails ? billDetails.grandTotal : 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          {(data && data.getOrderDetailsById.orderState.workerStatus === 1 && workerType === 0) || (data && data.getOrderDetailsById.orderState.workerStatus === 4 && workerType === 1) ? (
            <Button onClick={handleReadyButtonClickAccept} className={globalClasses.dashbordBtn}>
              Accept Order
            </Button>
          ) : (data && data.getOrderDetailsById.orderState.workerStatus === 2 && workerType === 0) ? (
            <Button onClick={handleReadyButtonClick} className={globalClasses.dashbordBtn}>
              Packing Completed
            </Button>
          ) : (data && data.getOrderDetailsById.orderState.workerStatus === 5 && workerType === 1) ?
            <Button onClick={handleReadyButtonClick} className={globalClasses.dashbordBtn}>
              Billing Completed
            </Button> : (data && data.getOrderDetailsById.invoiceId && workerType === 1) ?
              <>
                <Button onClick={() => handleViewDetails()} className={globalClasses.dashbordBtn}>
                  View Invoice
                </Button>
                <Button disabled onClick={handleReadyButtonClick} className={globalClasses.dashbordBtnDisabled}>
                  Order Completed
                </Button>
              </> :
              (
                <Button disabled onClick={handleReadyButtonClick} className={globalClasses.dashbordBtnDisabled}>
                  Order Completed
                </Button>
              )}
        </Box>
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <EditOrderByWorker vendor={vendors} editModal={editModal} onClose={closeEditModal} refetch={refetch} payMethod={payMethodVar} sellingPrice={sellingPrice} />
        </Modal>
        <Box mt={2}>
          {success && (
            <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                {success}
              </Alert>
            </Snackbar>
          )}
          {error && (
            <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                {error}
              </Alert>
            </Snackbar>
          )}
        </Box>
      </Container>

    </>
  )
}

export default withTranslation()(PackageOrderDetails)



