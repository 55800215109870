import React, { useState } from 'react'
import Header from '../components/Headers/Header';
import useGlobalStyles from '../utils/globalStyles';
import { Box, Typography, Container, Grid, Modal, } from '@mui/material';
import { GetSuperAdminDashBoard } from '../apollo';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { Icon } from '@iconify/react';
import shopOutline from '@iconify-icons/teenyicons/shop-outline';
import { withTranslation } from 'react-i18next';
import VendorComponent from "../components/Vendor/Vendor"
import { useHistory } from 'react-router-dom'
import CustomLoader from '../components/Loader/CustomLoader';

const GET_SUPER_ADMIN_DASHBOARD = gql`${GetSuperAdminDashBoard}`;

const SuperAdminDashboard = (props) => {
  const history = useHistory();
  const client = useApolloClient();
  const globalClasses = useGlobalStyles();
  const { data } = useQuery(GET_SUPER_ADMIN_DASHBOARD, {
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },

  })

  const [addVendorModal, setAddVendorModal] = useState(false)

  const sty = {
    fontSize: 35,
    fontWeight: 'bold',
    color: "#0b3978",
    textAlign: 'center'
  };

  // Check if data and getSuperAdminDashboard are defined
  if (!data || !data.getSuperAdminDashboard) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CustomLoader />
      </div>
    );
  }
  // Destructure the data object to access the nested properties
  const {
    getSuperAdminDashboard: {
      centralStoreCount,
      totalOrders,
      // pendingOrders,
      // completedOrders,
      categoryCount,
      productCount
    }
  } = data;
  localStorage.setItem("totalProductsCount", productCount)
  localStorage.setItem("totalOrdersCount", totalOrders)

  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };

  const handleClick = () => {
    history.push({
      pathname: '/super_admin/managestore',
      state: { data: '' },
    });
  };

  const handleClickProducts = () => {
    history.push({
      pathname: '/super_admin/products',
      state: { data: '' },
    });
  };
  const handleClickCategory = () => {
    history.push({
      pathname: '/super_admin/mangecategory',
      state: { data: '' },
    });
  };
  return (
    <>
      <Header />
      <Container sx={{ ml: 5 }} fluid className={globalClasses.flex_admin_dashboard}>
        <Grid container mt={2} spacing={2}>

          <Grid container mt={2} spacing={2}>
            <Grid item xs={12} md={6} onClick={handleClick}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 2,
                  borderRadius: 5,
                  bgcolor: '#CADFFF',
                  width: '80%',
                  mb: 3,
                  cursor: 'pointer', // Optional: Add cursor pointer for better UX
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                  <Typography sx={headSty} >
                    Central Stores
                  </Typography>
                  <Typography sx={sty}>
                    {centralStoreCount} Store
                  </Typography>
                </div>
                <Icon icon={shopOutline} width={40} height={40} color="#0b3978" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} onClick={handleClickProducts}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 2,
                  borderRadius: 5,
                  bgcolor: '#CADFFF',
                  width: '80%',
                  mb: 3,
                  cursor: 'pointer',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                  <Typography sx={headSty} >
                    Products
                  </Typography>
                  <Typography sx={sty}>
                    {productCount}
                  </Typography>
                </div>
                {/* <Icon icon="bi:recycle" width={40} height={40} color="#0b3978" /> */}
                <Icon icon="fluent-emoji-high-contrast:shopping-bags" width={40} height={40} style={{ color: "#0b3978" }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} onClick={handleClickCategory}>
                  <Box
                    sx={{
                      display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 2,
                  borderRadius: 5,
                  bgcolor: '#CADFFF',
                  width: '80%',
                  mb: 3,
                  cursor: 'pointer',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                      <Typography sx={headSty} >
                        Categories
                      </Typography>
                      <Typography sx={sty}>
                        {categoryCount} {/* Replace this with actual data */}
                      </Typography>
                    </div>
                    {/* <Icon icon="bi:recycle" width={40} height={40} color="#0b3978" /> */}
                    <Icon icon="iconamoon:category" width={40} height={40} style={{ color: "#0b3978" }} />
                  </Box>
                </Grid>
            <Grid item xs={12} >
              <Grid container spacing={2}>
               

              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>

              </Grid>
            </Grid>

          </Grid>
        </Grid>
        {addVendorModal ? <Grid container>
          {/* <Grid item xs={12} order={{ xs: 2, lg: 1 }}> */}
          <Grid
            sx={{ display: { xs: 'none', lg: 'block' } }}
            item
            mt={5}
            ml={-2}
            order={{ xs: 1, lg: 2 }}
          >
            <Modal
              open={addVendorModal}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClose={closeAddVendorModal}
            >
              <VendorComponent onClose={closeAddVendorModal} />
            </Modal>
          </Grid>
          {/* </Grid> */}
        </Grid> : null
        }
      </Container>
    </>
  )
}

const headSty = { fontSize: 15, fontWeight: 'bold' }

export default withTranslation()(SuperAdminDashboard);
